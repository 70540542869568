import { useEffect, useRef } from 'react';
import './Services.css';
import Image1 from './Images/Image1.png';
import Social from './Images/Image2.png'
import film from './Images/film.png'
import visual1 from './Images/Visual.png'
import Advertising from './Images/Advertising.png';
import Dev from './Images/Dev.png';
import { Navbar } from '../Navbar/Navbar';
import { Footer } from '../Footer/Footer';
import { Dropdown } from '../Dropdown component/Dropdown';
import { Dropdown2 } from '../Dropdown component/Dropdown2';

function Services1() {
    const ad = useRef(null);
    const dev = useRef(null);
    const visual = useRef(null);
    const video = useRef(null);
    const social = useRef(null);
    useEffect(() => {
        const hash = window.location.hash.substring(1);

        if (hash === 'ad' && ad.current) {
            ad.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === 'social' && social.current) {
            social.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === 'visual' && visual.current) {
            visual.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === 'dev' && dev.current) {
            dev.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === 'video' && video.current) {
            video.current.scrollIntoView({ behavior: 'smooth' });
        }

    }, []);

    return (
        <div className='col'>
            <Navbar />
            {/* Below the Navbar */}
            <div className="row d-none d-lg-flex" style={{ height: '400px', justifyContent: 'center' }}>

                <div className="col-4 align-self-center services" style={{ backgroundImage: `url(${Image1})`, color: 'White' }}>
                    الخدمات
                </div>

            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ height: '400px', justifyContent: 'center' }}>

                <div className="col-4 align-self-center services-md" style={{ backgroundImage: `url(${Image1})`, color: 'White' }}>
                    الخدمات
                </div>

            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ height: '400px', justifyContent: 'center' }}>

                <div className="col-4 align-self-center services-sm" style={{ backgroundImage: `url(${Image1})`, color: 'White' }}>
                    الخدمات
                </div>

            </div>
            <div className="row d-flex d-sm-none" style={{ height: '400px', justifyContent: 'center' }}>

                <div className="col-4 align-self-center services-xs" style={{ backgroundImage: `url(${Image1})`, color: 'White' }}>
                    الخدمات
                </div>

            </div>

            {/* Stars */}
            <div className="row d-none d-lg-flex" style={{ marginTop: '100px', marginBottom: '100px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="385.206" height="82.407" viewBox="0 0 385.206 82.407">

                    <g id="Group_417" data-name="Group 417" transform="translate(-733.896 -1118)">
                        <g id="_5" data-name="5" transform="translate(733.896 1118)">
                            <g id="row1">
                                <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(886.396 1118)">
                            <g id="row1-2" data-name="row1">
                                <path id="icon:5-2" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1038.896 1118)">
                            <g id="row1-3" data-name="row1">
                                <path id="icon:5-3" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                    </g>


                </svg>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', marginBottom: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <svg style={{ width: '300px' }} xmlns="http://www.w3.org/2000/svg" width="385.206" height="82.407" viewBox="0 0 385.206 82.407">

                    <g id="Group_417" data-name="Group 417" transform="translate(-733.896 -1118)">
                        <g id="_5" data-name="5" transform="translate(733.896 1118)">
                            <g id="row1">
                                <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(886.396 1118)">
                            <g id="row1-2" data-name="row1">
                                <path id="icon:5-2" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1038.896 1118)">
                            <g id="row1-3" data-name="row1">
                                <path id="icon:5-3" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                    </g>


                </svg>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ marginTop: '100px', marginBottom: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <svg style={{ width: '200px' }} xmlns="http://www.w3.org/2000/svg" width="385.206" height="82.407" viewBox="0 0 385.206 82.407">

                    <g id="Group_417" data-name="Group 417" transform="translate(-733.896 -1118)">
                        <g id="_5" data-name="5" transform="translate(733.896 1118)">
                            <g id="row1">
                                <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(886.396 1118)">
                            <g id="row1-2" data-name="row1">
                                <path id="icon:5-2" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1038.896 1118)">
                            <g id="row1-3" data-name="row1">
                                <path id="icon:5-3" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                    </g>


                </svg>
            </div>
            <div className="row d-flex d-sm-none" style={{ marginTop: '0px', marginBottom: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <svg style={{ width: '120px' }} xmlns="http://www.w3.org/2000/svg" width="385.206" height="82.407" viewBox="0 0 385.206 82.407">

                    <g id="Group_417" data-name="Group 417" transform="translate(-733.896 -1118)">
                        <g id="_5" data-name="5" transform="translate(733.896 1118)">
                            <g id="row1">
                                <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(886.396 1118)">
                            <g id="row1-2" data-name="row1">
                                <path id="icon:5-2" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1038.896 1118)">
                            <g id="row1-3" data-name="row1">
                                <path id="icon:5-3" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                    </g>


                </svg>
            </div>

            {/* Services */}
            {/* Advertising */}
            <div ref={ad} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-end' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="المحتوى الإبداعي"
                            description="نحن كفريق الإبداع نذهب الى أبعد مكان لنجاح عملك.
نهتم في ظهور علامتك التجارية والطريقة التي قد تتواصل بها مع الجمهور وما يتردد في أذهان جمهورك.
نجعل خبرتنا في مختلف الصناعات تحول علامتك التجارية إلى علامة رائدة في السوق ومعززة للحلول التي تقدمها في إطار اتجاهات الإبداع الأحدث مع رؤية المملكة 2030
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="إستراتيجية التواصل"
                            description="نحن فريق قائم على البيانات.
نحقق نتائج إيجابية من خلال تركيز جهودنا حول الأساليب المتقدمة للاستهداف وإعادة الاستهداف، وجمع الاتجاهات الرقمية وبيانات المستهلك.
بعد فهم صناعتك واحتياجات المستهلك بنجاح، نقوم بتبسيط الرسائل المعقدة في إطار عمل تكتيكي طويل المدى سيتم مشاركته باستخدام التقنيات الرقمية بهدف تحويل الجماهير المستهدفة إلى عملاء محتملين والاحتفاظ بهم.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${Advertising})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الإعلان<br />
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px' }}>
                                تدفعنا الحقبة الرقمية التي نعيشها إلى التقدم والتميز بالقوة والإبداع.
                                وهنا تأتي خدماتنا الإعلانية لدعم مشاريعك وتحويلها إلى مشاريع مؤثرة لا ينساها المستهلكون بفضل دقتها وأصالتها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="أبحاث التسويق"
                            description="قبل المضي قدمًا في مشاريعنا، تعد الأبحاث التسويقية المرحلة الأساسية.
جمع المعلومات الهامة حول صناعة المشروع وجمهور الهدف والمنافسين وأنشطة المعايير القياسية والأداء يتيح لنا المضي قدمًا بشكل استراتيجي.
من خلال فهم شخصيات المستخدمين ورسم مساراتهم، نعيد تشكيل بياناتنا المجمعة بطريقة منطقية لجذب العملاء المحتملين بشكل طبيعي
 "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة حملات SEO & SEM"
                            description="تولي الأهمية المستحقة، فبهدف زيادة حركة البحث وإتمام المزيد من الصفقات، فإن تحسين محركات البحث وتسويق محركات البحث يولد نتائج أفضل.
يتولى فريقنا إعدادك الفني مع الكلمات الرئيسية المخصصة وتحسين الظهور في صفحات الويب
"
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="المحتوى الإبداعي"
                            description="نحن كفريق الإبداع نذهب الى أبعد مكان لنجاح عملك.
نهتم في ظهور علامتك التجارية والطريقة التي قد تتواصل بها مع الجمهور وما يتردد في أذهان جمهورك.
نجعل خبرتنا في مختلف الصناعات تحول علامتك التجارية إلى علامة رائدة في السوق ومعززة للحلول التي تقدمها في إطار اتجاهات الإبداع الأحدث مع رؤية المملكة 2030
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="إستراتيجية التواصل"
                            description="نحن فريق قائم على البيانات.
نحقق نتائج إيجابية من خلال تركيز جهودنا حول الأساليب المتقدمة للاستهداف وإعادة الاستهداف، وجمع الاتجاهات الرقمية وبيانات المستهلك.
بعد فهم صناعتك واحتياجات المستهلك بنجاح، نقوم بتبسيط الرسائل المعقدة في إطار عمل تكتيكي طويل المدى سيتم مشاركته باستخدام التقنيات الرقمية بهدف تحويل الجماهير المستهدفة إلى عملاء محتملين والاحتفاظ بهم.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${Advertising})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الإعلان<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                تدفعنا الحقبة الرقمية التي نعيشها إلى التقدم والتميز بالقوة والإبداع.
                                وهنا تأتي خدماتنا الإعلانية لدعم مشاريعك وتحويلها إلى مشاريع مؤثرة لا ينساها المستهلكون بفضل دقتها وأصالتها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="أبحاث التسويق"
                            description="قبل المضي قدمًا في مشاريعنا، تعد الأبحاث التسويقية المرحلة الأساسية.
جمع المعلومات الهامة حول صناعة المشروع وجمهور الهدف والمنافسين وأنشطة المعايير القياسية والأداء يتيح لنا المضي قدمًا بشكل استراتيجي.
من خلال فهم شخصيات المستخدمين ورسم مساراتهم، نعيد تشكيل بياناتنا المجمعة بطريقة منطقية لجذب العملاء المحتملين بشكل طبيعي
 "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة حملات SEO & SEM"
                            description="تولي الأهمية المستحقة، فبهدف زيادة حركة البحث وإتمام المزيد من الصفقات، فإن تحسين محركات البحث وتسويق محركات البحث يولد نتائج أفضل.
يتولى فريقنا إعدادك الفني مع الكلمات الرئيسية المخصصة وتحسين الظهور في صفحات الويب
"
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="المحتوى الإبداعي"
                            description="نحن كفريق الإبداع نذهب الى أبعد مكان لنجاح عملك.
نهتم في ظهور علامتك التجارية والطريقة التي قد تتواصل بها مع الجمهور وما يتردد في أذهان جمهورك.
نجعل خبرتنا في مختلف الصناعات تحول علامتك التجارية إلى علامة رائدة في السوق ومعززة للحلول التي تقدمها في إطار اتجاهات الإبداع الأحدث مع رؤية المملكة 2030
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="إستراتيجية التواصل"
                            description="نحن فريق قائم على البيانات.
نحقق نتائج إيجابية من خلال تركيز جهودنا حول الأساليب المتقدمة للاستهداف وإعادة الاستهداف، وجمع الاتجاهات الرقمية وبيانات المستهلك.
بعد فهم صناعتك واحتياجات المستهلك بنجاح، نقوم بتبسيط الرسائل المعقدة في إطار عمل تكتيكي طويل المدى سيتم مشاركته باستخدام التقنيات الرقمية بهدف تحويل الجماهير المستهدفة إلى عملاء محتملين والاحتفاظ بهم.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Advertising})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الإعلان<br />
                        <span style={{ fontSize: '9px' }}>
                            <p style={{ marginTop: '50px' }}>
                                تدفعنا الحقبة الرقمية التي نعيشها إلى التقدم والتميز بالقوة والإبداع.
                                وهنا تأتي خدماتنا الإعلانية لدعم مشاريعك وتحويلها إلى مشاريع مؤثرة لا ينساها المستهلكون بفضل دقتها وأصالتها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="أبحاث التسويق"
                            description="قبل المضي قدمًا في مشاريعنا، تعد الأبحاث التسويقية المرحلة الأساسية.
جمع المعلومات الهامة حول صناعة المشروع وجمهور الهدف والمنافسين وأنشطة المعايير القياسية والأداء يتيح لنا المضي قدمًا بشكل استراتيجي.
من خلال فهم شخصيات المستخدمين ورسم مساراتهم، نعيد تشكيل بياناتنا المجمعة بطريقة منطقية لجذب العملاء المحتملين بشكل طبيعي
 "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة حملات SEO & SEM"
                            description="تولي الأهمية المستحقة، فبهدف زيادة حركة البحث وإتمام المزيد من الصفقات، فإن تحسين محركات البحث وتسويق محركات البحث يولد نتائج أفضل.
يتولى فريقنا إعدادك الفني مع الكلمات الرئيسية المخصصة وتحسين الظهور في صفحات الويب
"
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div  className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="المحتوى الإبداعي"
                            description="نحن كفريق الإبداع نذهب الى أبعد مكان لنجاح عملك.
نهتم في ظهور علامتك التجارية والطريقة التي قد تتواصل بها مع الجمهور وما يتردد في أذهان جمهورك.
نجعل خبرتنا في مختلف الصناعات تحول علامتك التجارية إلى علامة رائدة في السوق ومعززة للحلول التي تقدمها في إطار اتجاهات الإبداع الأحدث مع رؤية المملكة 2030
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="إستراتيجية التواصل"
                            description="نحن فريق قائم على البيانات.
نحقق نتائج إيجابية من خلال تركيز جهودنا حول الأساليب المتقدمة للاستهداف وإعادة الاستهداف، وجمع الاتجاهات الرقمية وبيانات المستهلك.
بعد فهم صناعتك واحتياجات المستهلك بنجاح، نقوم بتبسيط الرسائل المعقدة في إطار عمل تكتيكي طويل المدى سيتم مشاركته باستخدام التقنيات الرقمية بهدف تحويل الجماهير المستهدفة إلى عملاء محتملين والاحتفاظ بهم.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Advertising})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الإعلان<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                تدفعنا الحقبة الرقمية التي نعيشها إلى التقدم والتميز بالقوة والإبداع.
                                وهنا تأتي خدماتنا الإعلانية لدعم مشاريعك وتحويلها إلى مشاريع مؤثرة لا ينساها المستهلكون بفضل دقتها وأصالتها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="أبحاث التسويق"
                            description="قبل المضي قدمًا في مشاريعنا، تعد الأبحاث التسويقية المرحلة الأساسية.
جمع المعلومات الهامة حول صناعة المشروع وجمهور الهدف والمنافسين وأنشطة المعايير القياسية والأداء يتيح لنا المضي قدمًا بشكل استراتيجي.
من خلال فهم شخصيات المستخدمين ورسم مساراتهم، نعيد تشكيل بياناتنا المجمعة بطريقة منطقية لجذب العملاء المحتملين بشكل طبيعي
 "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة حملات SEO & SEM"
                            description="تولي الأهمية المستحقة، فبهدف زيادة حركة البحث وإتمام المزيد من الصفقات، فإن تحسين محركات البحث وتسويق محركات البحث يولد نتائج أفضل.
يتولى فريقنا إعدادك الفني مع الكلمات الرئيسية المخصصة وتحسين الظهور في صفحات الويب
"
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div  className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="المحتوى الإبداعي"
                            description="نحن كفريق الإبداع نذهب الى أبعد مكان لنجاح عملك.
نهتم في ظهور علامتك التجارية والطريقة التي قد تتواصل بها مع الجمهور وما يتردد في أذهان جمهورك.
نجعل خبرتنا في مختلف الصناعات تحول علامتك التجارية إلى علامة رائدة في السوق ومعززة للحلول التي تقدمها في إطار اتجاهات الإبداع الأحدث مع رؤية المملكة 2030
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="إستراتيجية التواصل"
                            description="نحن فريق قائم على البيانات.
نحقق نتائج إيجابية من خلال تركيز جهودنا حول الأساليب المتقدمة للاستهداف وإعادة الاستهداف، وجمع الاتجاهات الرقمية وبيانات المستهلك.
بعد فهم صناعتك واحتياجات المستهلك بنجاح، نقوم بتبسيط الرسائل المعقدة في إطار عمل تكتيكي طويل المدى سيتم مشاركته باستخدام التقنيات الرقمية بهدف تحويل الجماهير المستهدفة إلى عملاء محتملين والاحتفاظ بهم.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Advertising})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الإعلان<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                تدفعنا الحقبة الرقمية التي نعيشها إلى التقدم والتميز بالقوة والإبداع.
                                وهنا تأتي خدماتنا الإعلانية لدعم مشاريعك وتحويلها إلى مشاريع مؤثرة لا ينساها المستهلكون بفضل دقتها وأصالتها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="أبحاث التسويق"
                            description="قبل المضي قدمًا في مشاريعنا، تعد الأبحاث التسويقية المرحلة الأساسية.
جمع المعلومات الهامة حول صناعة المشروع وجمهور الهدف والمنافسين وأنشطة المعايير القياسية والأداء يتيح لنا المضي قدمًا بشكل استراتيجي.
من خلال فهم شخصيات المستخدمين ورسم مساراتهم، نعيد تشكيل بياناتنا المجمعة بطريقة منطقية لجذب العملاء المحتملين بشكل طبيعي
 "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة حملات SEO & SEM"
                            description="تولي الأهمية المستحقة، فبهدف زيادة حركة البحث وإتمام المزيد من الصفقات، فإن تحسين محركات البحث وتسويق محركات البحث يولد نتائج أفضل.
يتولى فريقنا إعدادك الفني مع الكلمات الرئيسية المخصصة وتحسين الظهور في صفحات الويب
"
                            toggle={false}

                        />
                    </div>
                </div>
            </div>


            {/* Social Media */}
            <div ref={social} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-start' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" استراتيجيات وفعاليات وسائل التواصل الاجتماعي"
                            description="يجب دراسة وتفعيل وجودك عبر الإنترنت بشكل استراتيجي. يعمل خبراؤنا في وسائل التواصل الاجتماعي على ترويج علامتك التجارية وبيع منتجاتك أو خدماتك على المنصات الأكثر ملاءمة لجمهورك الخاص. سنعرض قيمك وقصتك من خلال شراء الوسائط وإنشاء محتوى مشوق يلبي أهداف التسويق الخاصة بك من خلال زيادة الوعي بعلامتك التجارية ونمو جمهورك، وزيادة مشاركة مجتمعك، وزيادة حركة المرور على موقعك، وتوليد العملاء المحتملين والمبيعات.

سيتم تصميم الفعاليات على شكل قصص وصور ثابتة ومقاطع فيديو قصيرة وما إلى ذلك، والتي تعكس الجانب الإنساني لعلامتك التجارية ويتم نشرها في أوقات الأداء الأفضل. يراقب فريقنا عن كثب معايير الأداء ومؤشرات الأداء الرئيسية وراء كل حركة ويطبق تعديلات صغيرة لتحسين رسائلك بناءً على عملية المحاولة والخطأ التي تعلمنا منها ما هو الأداء الجيد.
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="التسويق المؤثر"
                            description="يعتمد فريقنا على تسويق المؤثر الذي ثبتت فعاليته. نتعامل مع أبرز المؤثرين في صناعتك الذين يمكنهم تلبية توقعات جمهورك بعد صياغة خطة بعناية لهم للتأييد والترويج ووضع منتجاتك. إنها خيار فعال من حيث التكلفة للشركات، حيث يتمتع المؤثرون بعلاقة قوية مع متابعيهم، مما يضمن استقبال الرسالة بشكل جيد.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${Social})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        وسائل التواصل الاجتماعي<br />
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px' }}>
                                في ظل وجود الجميع عبر الإنترنت ، هناك حاجة لجذبهم باستمرار بنظرة جديدة يتم إيصالها عبر خلاصات الأنظمة الأساسية.
                                تكمن قوتنا في دفع العملاء ، وزيادة جمهورك ، وتوسيع نطاق وصولك من خلال إقناع عملائك ، والحفاظ على علاقة طويلة الأمد معهم ، وتعزيز الخدمات والمنتجات من خلال تفكيرنا المتوافق مع الأفكار حول المنصات التي يقضي جمهورنا معظم وقتهم عليها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="إنشاء الصفحات"
                            description="يحتاج عملك إلى أن يكون متاحًا ومفتوحًا لجميع الجماهير، وهذا هو المكان الذي ندخل فيه. سيكون جزءًا حيويًا من نجاحك في أيدينا، لا يتمثل فقط في إدارة وجودك على الإنترنت بل في إنشائه من الصفر من خلال التركيز على الفرص والاستراتيجيات المرنة التي ستدعم وتنمي علامتك التجارية.

سيقوم فريقنا في وسائل التواصل الاجتماعي بإنشاء ملف تعريفك على منصات مناسبة باستخدام أفضل الممارسات والتكتيكات الحديثة لزيادة عدد متابعيك عن طريق تعزيز المشاركة والاهتمام بما تقدمه

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="إنشاء المحتوى"
                            description="تتميز وكالتنا بجرعة فاخرة من الإبداع عند تطوير مختلف أنواع المحتوى الذي يتناول أهداف عملك واحتياجات جمهورك. بعد التعرف بعناية على علامتك التجارية، نصمم استراتيجية محتوى ذات جودة عالية وملائمة للغاية بصوت علامة تجارية ستترك انطباعًا على عقول متابعيك. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة الحسابات"
                            description="لا يجب أبدًا التقليل من أهمية الشعور بالمجتمع. إن تلبية طلبات معجبيك أمر حيوي لتبرز وتثابر في العصر الرقمي التنافسي الذي نعيش فيه. فريقنا لن يمثل علامتك التجارية فقط، بل سيستمع ويجمع بنشاط الملاحظات والشكاوى والتوقعات. سيكون لجمهورك مكانًا للاتصال والمشاركة والنمو مما سيزيد من تفاعلاتهم وتحويلاتهم لاحقًا نظرًا للثقة والثقة الزاهية التي توحي بها علامتك التجارية."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" استراتيجيات وفعاليات وسائل التواصل الاجتماعي"
                            description="يجب دراسة وتفعيل وجودك عبر الإنترنت بشكل استراتيجي. يعمل خبراؤنا في وسائل التواصل الاجتماعي على ترويج علامتك التجارية وبيع منتجاتك أو خدماتك على المنصات الأكثر ملاءمة لجمهورك الخاص. سنعرض قيمك وقصتك من خلال شراء الوسائط وإنشاء محتوى مشوق يلبي أهداف التسويق الخاصة بك من خلال زيادة الوعي بعلامتك التجارية ونمو جمهورك، وزيادة مشاركة مجتمعك، وزيادة حركة المرور على موقعك، وتوليد العملاء المحتملين والمبيعات.

سيتم تصميم الفعاليات على شكل قصص وصور ثابتة ومقاطع فيديو قصيرة وما إلى ذلك، والتي تعكس الجانب الإنساني لعلامتك التجارية ويتم نشرها في أوقات الأداء الأفضل. يراقب فريقنا عن كثب معايير الأداء ومؤشرات الأداء الرئيسية وراء كل حركة ويطبق تعديلات صغيرة لتحسين رسائلك بناءً على عملية المحاولة والخطأ التي تعلمنا منها ما هو الأداء الجيد.
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="التسويق المؤثر"
                            description="يعتمد فريقنا على تسويق المؤثر الذي ثبتت فعاليته. نتعامل مع أبرز المؤثرين في صناعتك الذين يمكنهم تلبية توقعات جمهورك بعد صياغة خطة بعناية لهم للتأييد والترويج ووضع منتجاتك. إنها خيار فعال من حيث التكلفة للشركات، حيث يتمتع المؤثرون بعلاقة قوية مع متابعيهم، مما يضمن استقبال الرسالة بشكل جيد.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        وسائل التواصل الاجتماعي<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                في ظل وجود الجميع عبر الإنترنت ، هناك حاجة لجذبهم باستمرار بنظرة جديدة يتم إيصالها عبر خلاصات الأنظمة الأساسية.
                                تكمن قوتنا في دفع العملاء ، وزيادة جمهورك ، وتوسيع نطاق وصولك من خلال إقناع عملائك ، والحفاظ على علاقة طويلة الأمد معهم ، وتعزيز الخدمات والمنتجات من خلال تفكيرنا المتوافق مع الأفكار حول المنصات التي يقضي جمهورنا معظم وقتهم عليها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="إنشاء الصفحات"
                            description="يحتاج عملك إلى أن يكون متاحًا ومفتوحًا لجميع الجماهير، وهذا هو المكان الذي ندخل فيه. سيكون جزءًا حيويًا من نجاحك في أيدينا، لا يتمثل فقط في إدارة وجودك على الإنترنت بل في إنشائه من الصفر من خلال التركيز على الفرص والاستراتيجيات المرنة التي ستدعم وتنمي علامتك التجارية.

سيقوم فريقنا في وسائل التواصل الاجتماعي بإنشاء ملف تعريفك على منصات مناسبة باستخدام أفضل الممارسات والتكتيكات الحديثة لزيادة عدد متابعيك عن طريق تعزيز المشاركة والاهتمام بما تقدمه

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="إنشاء المحتوى"
                            description="تتميز وكالتنا بجرعة فاخرة من الإبداع عند تطوير مختلف أنواع المحتوى الذي يتناول أهداف عملك واحتياجات جمهورك. بعد التعرف بعناية على علامتك التجارية، نصمم استراتيجية محتوى ذات جودة عالية وملائمة للغاية بصوت علامة تجارية ستترك انطباعًا على عقول متابعيك. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة الحسابات"
                            description="لا يجب أبدًا التقليل من أهمية الشعور بالمجتمع. إن تلبية طلبات معجبيك أمر حيوي لتبرز وتثابر في العصر الرقمي التنافسي الذي نعيش فيه. فريقنا لن يمثل علامتك التجارية فقط، بل سيستمع ويجمع بنشاط الملاحظات والشكاوى والتوقعات. سيكون لجمهورك مكانًا للاتصال والمشاركة والنمو مما سيزيد من تفاعلاتهم وتحويلاتهم لاحقًا نظرًا للثقة والثقة الزاهية التي توحي بها علامتك التجارية."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" استراتيجيات وفعاليات وسائل التواصل الاجتماعي"
                            description="يجب دراسة وتفعيل وجودك عبر الإنترنت بشكل استراتيجي. يعمل خبراؤنا في وسائل التواصل الاجتماعي على ترويج علامتك التجارية وبيع منتجاتك أو خدماتك على المنصات الأكثر ملاءمة لجمهورك الخاص. سنعرض قيمك وقصتك من خلال شراء الوسائط وإنشاء محتوى مشوق يلبي أهداف التسويق الخاصة بك من خلال زيادة الوعي بعلامتك التجارية ونمو جمهورك، وزيادة مشاركة مجتمعك، وزيادة حركة المرور على موقعك، وتوليد العملاء المحتملين والمبيعات.

سيتم تصميم الفعاليات على شكل قصص وصور ثابتة ومقاطع فيديو قصيرة وما إلى ذلك، والتي تعكس الجانب الإنساني لعلامتك التجارية ويتم نشرها في أوقات الأداء الأفضل. يراقب فريقنا عن كثب معايير الأداء ومؤشرات الأداء الرئيسية وراء كل حركة ويطبق تعديلات صغيرة لتحسين رسائلك بناءً على عملية المحاولة والخطأ التي تعلمنا منها ما هو الأداء الجيد.
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="التسويق المؤثر"
                            description="يعتمد فريقنا على تسويق المؤثر الذي ثبتت فعاليته. نتعامل مع أبرز المؤثرين في صناعتك الذين يمكنهم تلبية توقعات جمهورك بعد صياغة خطة بعناية لهم للتأييد والترويج ووضع منتجاتك. إنها خيار فعال من حيث التكلفة للشركات، حيث يتمتع المؤثرون بعلاقة قوية مع متابعيهم، مما يضمن استقبال الرسالة بشكل جيد.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        وسائل التواصل الاجتماعي<br />
                        <span style={{ fontSize: '9px' }}>
                            <p style={{ marginTop: '50px' }}>
                                في ظل وجود الجميع عبر الإنترنت ، هناك حاجة لجذبهم باستمرار بنظرة جديدة يتم إيصالها عبر خلاصات الأنظمة الأساسية.
                                تكمن قوتنا في دفع العملاء ، وزيادة جمهورك ، وتوسيع نطاق وصولك من خلال إقناع عملائك ، والحفاظ على علاقة طويلة الأمد معهم ، وتعزيز الخدمات والمنتجات من خلال تفكيرنا المتوافق مع الأفكار حول المنصات التي يقضي جمهورنا معظم وقتهم عليها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="إنشاء الصفحات"
                            description="يحتاج عملك إلى أن يكون متاحًا ومفتوحًا لجميع الجماهير، وهذا هو المكان الذي ندخل فيه. سيكون جزءًا حيويًا من نجاحك في أيدينا، لا يتمثل فقط في إدارة وجودك على الإنترنت بل في إنشائه من الصفر من خلال التركيز على الفرص والاستراتيجيات المرنة التي ستدعم وتنمي علامتك التجارية.

سيقوم فريقنا في وسائل التواصل الاجتماعي بإنشاء ملف تعريفك على منصات مناسبة باستخدام أفضل الممارسات والتكتيكات الحديثة لزيادة عدد متابعيك عن طريق تعزيز المشاركة والاهتمام بما تقدمه

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="إنشاء المحتوى"
                            description="تتميز وكالتنا بجرعة فاخرة من الإبداع عند تطوير مختلف أنواع المحتوى الذي يتناول أهداف عملك واحتياجات جمهورك. بعد التعرف بعناية على علامتك التجارية، نصمم استراتيجية محتوى ذات جودة عالية وملائمة للغاية بصوت علامة تجارية ستترك انطباعًا على عقول متابعيك. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة الحسابات"
                            description="لا يجب أبدًا التقليل من أهمية الشعور بالمجتمع. إن تلبية طلبات معجبيك أمر حيوي لتبرز وتثابر في العصر الرقمي التنافسي الذي نعيش فيه. فريقنا لن يمثل علامتك التجارية فقط، بل سيستمع ويجمع بنشاط الملاحظات والشكاوى والتوقعات. سيكون لجمهورك مكانًا للاتصال والمشاركة والنمو مما سيزيد من تفاعلاتهم وتحويلاتهم لاحقًا نظرًا للثقة والثقة الزاهية التي توحي بها علامتك التجارية."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" استراتيجيات وفعاليات وسائل التواصل الاجتماعي"
                            description="يجب دراسة وتفعيل وجودك عبر الإنترنت بشكل استراتيجي. يعمل خبراؤنا في وسائل التواصل الاجتماعي على ترويج علامتك التجارية وبيع منتجاتك أو خدماتك على المنصات الأكثر ملاءمة لجمهورك الخاص. سنعرض قيمك وقصتك من خلال شراء الوسائط وإنشاء محتوى مشوق يلبي أهداف التسويق الخاصة بك من خلال زيادة الوعي بعلامتك التجارية ونمو جمهورك، وزيادة مشاركة مجتمعك، وزيادة حركة المرور على موقعك، وتوليد العملاء المحتملين والمبيعات.

سيتم تصميم الفعاليات على شكل قصص وصور ثابتة ومقاطع فيديو قصيرة وما إلى ذلك، والتي تعكس الجانب الإنساني لعلامتك التجارية ويتم نشرها في أوقات الأداء الأفضل. يراقب فريقنا عن كثب معايير الأداء ومؤشرات الأداء الرئيسية وراء كل حركة ويطبق تعديلات صغيرة لتحسين رسائلك بناءً على عملية المحاولة والخطأ التي تعلمنا منها ما هو الأداء الجيد.
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px' }}>
                        <Dropdown
                            title="التسويق المؤثر"
                            description="يعتمد فريقنا على تسويق المؤثر الذي ثبتت فعاليته. نتعامل مع أبرز المؤثرين في صناعتك الذين يمكنهم تلبية توقعات جمهورك بعد صياغة خطة بعناية لهم للتأييد والترويج ووضع منتجاتك. إنها خيار فعال من حيث التكلفة للشركات، حيث يتمتع المؤثرون بعلاقة قوية مع متابعيهم، مما يضمن استقبال الرسالة بشكل جيد.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        وسائل التواصل الاجتماعي<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                في ظل وجود الجميع عبر الإنترنت ، هناك حاجة لجذبهم باستمرار بنظرة جديدة يتم إيصالها عبر خلاصات الأنظمة الأساسية.
                                تكمن قوتنا في دفع العملاء ، وزيادة جمهورك ، وتوسيع نطاق وصولك من خلال إقناع عملائك ، والحفاظ على علاقة طويلة الأمد معهم ، وتعزيز الخدمات والمنتجات من خلال تفكيرنا المتوافق مع الأفكار حول المنصات التي يقضي جمهورنا معظم وقتهم عليها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="إنشاء الصفحات"
                            description="يحتاج عملك إلى أن يكون متاحًا ومفتوحًا لجميع الجماهير، وهذا هو المكان الذي ندخل فيه. سيكون جزءًا حيويًا من نجاحك في أيدينا، لا يتمثل فقط في إدارة وجودك على الإنترنت بل في إنشائه من الصفر من خلال التركيز على الفرص والاستراتيجيات المرنة التي ستدعم وتنمي علامتك التجارية.

سيقوم فريقنا في وسائل التواصل الاجتماعي بإنشاء ملف تعريفك على منصات مناسبة باستخدام أفضل الممارسات والتكتيكات الحديثة لزيادة عدد متابعيك عن طريق تعزيز المشاركة والاهتمام بما تقدمه

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="إنشاء المحتوى"
                            description="تتميز وكالتنا بجرعة فاخرة من الإبداع عند تطوير مختلف أنواع المحتوى الذي يتناول أهداف عملك واحتياجات جمهورك. بعد التعرف بعناية على علامتك التجارية، نصمم استراتيجية محتوى ذات جودة عالية وملائمة للغاية بصوت علامة تجارية ستترك انطباعًا على عقول متابعيك. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة الحسابات"
                            description="لا يجب أبدًا التقليل من أهمية الشعور بالمجتمع. إن تلبية طلبات معجبيك أمر حيوي لتبرز وتثابر في العصر الرقمي التنافسي الذي نعيش فيه. فريقنا لن يمثل علامتك التجارية فقط، بل سيستمع ويجمع بنشاط الملاحظات والشكاوى والتوقعات. سيكون لجمهورك مكانًا للاتصال والمشاركة والنمو مما سيزيد من تفاعلاتهم وتحويلاتهم لاحقًا نظرًا للثقة والثقة الزاهية التي توحي بها علامتك التجارية."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" استراتيجيات وفعاليات وسائل التواصل الاجتماعي"
                            description="يجب دراسة وتفعيل وجودك عبر الإنترنت بشكل استراتيجي. يعمل خبراؤنا في وسائل التواصل الاجتماعي على ترويج علامتك التجارية وبيع منتجاتك أو خدماتك على المنصات الأكثر ملاءمة لجمهورك الخاص. سنعرض قيمك وقصتك من خلال شراء الوسائط وإنشاء محتوى مشوق يلبي أهداف التسويق الخاصة بك من خلال زيادة الوعي بعلامتك التجارية ونمو جمهورك، وزيادة مشاركة مجتمعك، وزيادة حركة المرور على موقعك، وتوليد العملاء المحتملين والمبيعات.

سيتم تصميم الفعاليات على شكل قصص وصور ثابتة ومقاطع فيديو قصيرة وما إلى ذلك، والتي تعكس الجانب الإنساني لعلامتك التجارية ويتم نشرها في أوقات الأداء الأفضل. يراقب فريقنا عن كثب معايير الأداء ومؤشرات الأداء الرئيسية وراء كل حركة ويطبق تعديلات صغيرة لتحسين رسائلك بناءً على عملية المحاولة والخطأ التي تعلمنا منها ما هو الأداء الجيد.
"
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="التسويق المؤثر"
                            description="يعتمد فريقنا على تسويق المؤثر الذي ثبتت فعاليته. نتعامل مع أبرز المؤثرين في صناعتك الذين يمكنهم تلبية توقعات جمهورك بعد صياغة خطة بعناية لهم للتأييد والترويج ووضع منتجاتك. إنها خيار فعال من حيث التكلفة للشركات، حيث يتمتع المؤثرون بعلاقة قوية مع متابعيهم، مما يضمن استقبال الرسالة بشكل جيد.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        وسائل التواصل الاجتماعي<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                في ظل وجود الجميع عبر الإنترنت ، هناك حاجة لجذبهم باستمرار بنظرة جديدة يتم إيصالها عبر خلاصات الأنظمة الأساسية.
                                تكمن قوتنا في دفع العملاء ، وزيادة جمهورك ، وتوسيع نطاق وصولك من خلال إقناع عملائك ، والحفاظ على علاقة طويلة الأمد معهم ، وتعزيز الخدمات والمنتجات من خلال تفكيرنا المتوافق مع الأفكار حول المنصات التي يقضي جمهورنا معظم وقتهم عليها.

                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="إنشاء الصفحات"
                            description="يحتاج عملك إلى أن يكون متاحًا ومفتوحًا لجميع الجماهير، وهذا هو المكان الذي ندخل فيه. سيكون جزءًا حيويًا من نجاحك في أيدينا، لا يتمثل فقط في إدارة وجودك على الإنترنت بل في إنشائه من الصفر من خلال التركيز على الفرص والاستراتيجيات المرنة التي ستدعم وتنمي علامتك التجارية.

سيقوم فريقنا في وسائل التواصل الاجتماعي بإنشاء ملف تعريفك على منصات مناسبة باستخدام أفضل الممارسات والتكتيكات الحديثة لزيادة عدد متابعيك عن طريق تعزيز المشاركة والاهتمام بما تقدمه

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="إنشاء المحتوى"
                            description="تتميز وكالتنا بجرعة فاخرة من الإبداع عند تطوير مختلف أنواع المحتوى الذي يتناول أهداف عملك واحتياجات جمهورك. بعد التعرف بعناية على علامتك التجارية، نصمم استراتيجية محتوى ذات جودة عالية وملائمة للغاية بصوت علامة تجارية ستترك انطباعًا على عقول متابعيك. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="إدارة الحسابات"
                            description="لا يجب أبدًا التقليل من أهمية الشعور بالمجتمع. إن تلبية طلبات معجبيك أمر حيوي لتبرز وتثابر في العصر الرقمي التنافسي الذي نعيش فيه. فريقنا لن يمثل علامتك التجارية فقط، بل سيستمع ويجمع بنشاط الملاحظات والشكاوى والتوقعات. سيكون لجمهورك مكانًا للاتصال والمشاركة والنمو مما سيزيد من تفاعلاتهم وتحويلاتهم لاحقًا نظرًا للثقة والثقة الزاهية التي توحي بها علامتك التجارية."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            {/* Video and film */}

            <div ref={video} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-end' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="التأثيرات الخاصة والتأثيرات المرئية الخاصة"
                            description="يتمحور أولوية فريق الإنتاج الخاص بنا حول تعزيز سرد قصتك من خلال اللجوء إلى التأثيرات الخاصة العملية أثناء تصوير المشاهد.
فريقنا دائمًا مستعد لإنشاء شيء غير موجود حاليًا مما يضع إبداعنا في العمل.
نجمع التأثيرات المرئية المتعلقة بحركة الكاميرا والإضاءة مع التأثيرات الخاصة التي لا تحتاج إلى تكنولوجيا ولكنها مطلوبة أثناء التصوير لخلق عدد غير محدود من حركات المشهد المختلفة.
الرسومات المدمجة بشكل مناسب هي ما يجعل المشاهد تبدو حقيقية وإعادة إنشاء لحظات غير واقعية على الشاشة هو ما يجعلها أجمل للمشاهدة!

                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="استوديو الشاشة الخضراء والفريق التمثيلي"
                            description="سواء أردت تصوير فيديوهات التعلُّم الإلكترونيَّة، أو المقابلات، أو أي مشهد آخر، فإن استوديو كروما كي هو في تصرُّفك!
يحمل فريقنا الإبداعي شغفًا بالأفلام ويتمتع بمنشآت إنتاجية أخرى لترجمة رسالتك بإطار لا يُنسى، ما يجعل جمهورك متشوِّقًا ومثيرًا لخطط مستقبلك.
والعمل مع ألفا كرييتيف يعني أن إيجاد الطاقم المناسب والفريق التمثيلي هو مسؤوليتنا الوحيدة لتحقيق لحظة افتراضية عالية الجودة وذات تأثير رائع يُظهر الألوان والملمس المختلفة بشكل احترافي.

                            "
                            toggle={false}
                        />

                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${film})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        إنتاج الفيديو / الأفلام

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="ابتكار الفكرة وكتابة السيناريو والقصة"
                            description="يتعامل فريق الإنتاج الإبداعي لدينا مع إبتكار الأفكار وكتابة السيناريو للفيديو من الصفر، حيث يتم التغطية لجميع المراحل بدءًا من كتابة المسودة الأولى وحتى مرحلة المراجعة والتسليم النهائي. تتميز عملية الإنتاج الإبداعية لدينا بالاهتمام الشديد بالتفاصيل والذي يساعد في تيسير عملية الإنشاء بدءًا من الجانب البصري وصولاً إلى الحوار والوتيرة، حيث يتم التأكد من وجود كل تفصيل في مشهده المناسب واحترام ما تبحث عنه علامتك التجارية. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '-50px' }}>
                        <Dropdown2
                            title="التصوير الفوتوغرافي"
                            description="نقدم التصوير الفوتوغرافي عالي الجودة في عصرنا الرقمي من خلال إدارة التصوير ومعالجة وتحرير الصور. تركيزنا على المسرحية والإضاءة يلتقط التفاصيل اللافتة للنظر والتي يجب استخدامها في حملات الإعلان والمواقع الإلكترونية لتحقيق نتائج أفضل."
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '300px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="الإعداد الأولي والإنتاج الثانوي"
                            description="يوفر فريقنا حلولًا سينمائية شاملة لكل مشروع يأتينا بحيث يغطي عناء الإعداد الأولي والإنتاج والإنتاج الثانوي.
نبدأ بترتيب خطة تنفيذية بناءً على الميزانية المخصصة والمدى المطلوب مع احترام المبادئ العالمية. ثم نقوم بتصوير المشاهد مع الممثلين والأدوات المختارة وننهيها في مرحلة الإنتاج الثانوي حيث نتأكد من تمثيل الإبداع والجودة لخبرتنا وخبرتك

                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="التأثيرات الخاصة والتأثيرات المرئية الخاصة"
                            description="يتمحور أولوية فريق الإنتاج الخاص بنا حول تعزيز سرد قصتك من خلال اللجوء إلى التأثيرات الخاصة العملية أثناء تصوير المشاهد.
فريقنا دائمًا مستعد لإنشاء شيء غير موجود حاليًا مما يضع إبداعنا في العمل.
نجمع التأثيرات المرئية المتعلقة بحركة الكاميرا والإضاءة مع التأثيرات الخاصة التي لا تحتاج إلى تكنولوجيا ولكنها مطلوبة أثناء التصوير لخلق عدد غير محدود من حركات المشهد المختلفة.
الرسومات المدمجة بشكل مناسب هي ما يجعل المشاهد تبدو حقيقية وإعادة إنشاء لحظات غير واقعية على الشاشة هو ما يجعلها أجمل للمشاهدة!

                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="استوديو الشاشة الخضراء والفريق التمثيلي"
                            description="سواء أردت تصوير فيديوهات التعلُّم الإلكترونيَّة، أو المقابلات، أو أي مشهد آخر، فإن استوديو كروما كي هو في تصرُّفك!
يحمل فريقنا الإبداعي شغفًا بالأفلام ويتمتع بمنشآت إنتاجية أخرى لترجمة رسالتك بإطار لا يُنسى، ما يجعل جمهورك متشوِّقًا ومثيرًا لخطط مستقبلك.
والعمل مع ألفا كرييتيف يعني أن إيجاد الطاقم المناسب والفريق التمثيلي هو مسؤوليتنا الوحيدة لتحقيق لحظة افتراضية عالية الجودة وذات تأثير رائع يُظهر الألوان والملمس المختلفة بشكل احترافي.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${film})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        إنتاج الفيديو / الأفلام

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="ابتكار الفكرة وكتابة السيناريو والقصة"
                            description="يتعامل فريق الإنتاج الإبداعي لدينا مع إبتكار الأفكار وكتابة السيناريو للفيديو من الصفر، حيث يتم التغطية لجميع المراحل بدءًا من كتابة المسودة الأولى وحتى مرحلة المراجعة والتسليم النهائي. تتميز عملية الإنتاج الإبداعية لدينا بالاهتمام الشديد بالتفاصيل والذي يساعد في تيسير عملية الإنشاء بدءًا من الجانب البصري وصولاً إلى الحوار والوتيرة، حيث يتم التأكد من وجود كل تفصيل في مشهده المناسب واحترام ما تبحث عنه علامتك التجارية. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="التصوير الفوتوغرافي"
                            description="نقدم التصوير الفوتوغرافي عالي الجودة في عصرنا الرقمي من خلال إدارة التصوير ومعالجة وتحرير الصور. تركيزنا على المسرحية والإضاءة يلتقط التفاصيل اللافتة للنظر والتي يجب استخدامها في حملات الإعلان والمواقع الإلكترونية لتحقيق نتائج أفضل."
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '300px', marginLeft: '0px' }}>
                        <Dropdown2
                            title="الإعداد الأولي والإنتاج الثانوي"
                            description="يوفر فريقنا حلولًا سينمائية شاملة لكل مشروع يأتينا بحيث يغطي عناء الإعداد الأولي والإنتاج والإنتاج الثانوي.
نبدأ بترتيب خطة تنفيذية بناءً على الميزانية المخصصة والمدى المطلوب مع احترام المبادئ العالمية. ثم نقوم بتصوير المشاهد مع الممثلين والأدوات المختارة وننهيها في مرحلة الإنتاج الثانوي حيث نتأكد من تمثيل الإبداع والجودة لخبرتنا وخبرتك

                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="التأثيرات الخاصة والتأثيرات المرئية الخاصة"
                            description="يتمحور أولوية فريق الإنتاج الخاص بنا حول تعزيز سرد قصتك من خلال اللجوء إلى التأثيرات الخاصة العملية أثناء تصوير المشاهد.
فريقنا دائمًا مستعد لإنشاء شيء غير موجود حاليًا مما يضع إبداعنا في العمل.
نجمع التأثيرات المرئية المتعلقة بحركة الكاميرا والإضاءة مع التأثيرات الخاصة التي لا تحتاج إلى تكنولوجيا ولكنها مطلوبة أثناء التصوير لخلق عدد غير محدود من حركات المشهد المختلفة.
الرسومات المدمجة بشكل مناسب هي ما يجعل المشاهد تبدو حقيقية وإعادة إنشاء لحظات غير واقعية على الشاشة هو ما يجعلها أجمل للمشاهدة!

                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="استوديو الشاشة الخضراء والفريق التمثيلي"
                            description="سواء أردت تصوير فيديوهات التعلُّم الإلكترونيَّة، أو المقابلات، أو أي مشهد آخر، فإن استوديو كروما كي هو في تصرُّفك!
يحمل فريقنا الإبداعي شغفًا بالأفلام ويتمتع بمنشآت إنتاجية أخرى لترجمة رسالتك بإطار لا يُنسى، ما يجعل جمهورك متشوِّقًا ومثيرًا لخطط مستقبلك.
والعمل مع ألفا كرييتيف يعني أن إيجاد الطاقم المناسب والفريق التمثيلي هو مسؤوليتنا الوحيدة لتحقيق لحظة افتراضية عالية الجودة وذات تأثير رائع يُظهر الألوان والملمس المختلفة بشكل احترافي.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${film})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        إنتاج الفيديو / الأفلام

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="ابتكار الفكرة وكتابة السيناريو والقصة"
                            description="يتعامل فريق الإنتاج الإبداعي لدينا مع إبتكار الأفكار وكتابة السيناريو للفيديو من الصفر، حيث يتم التغطية لجميع المراحل بدءًا من كتابة المسودة الأولى وحتى مرحلة المراجعة والتسليم النهائي. تتميز عملية الإنتاج الإبداعية لدينا بالاهتمام الشديد بالتفاصيل والذي يساعد في تيسير عملية الإنشاء بدءًا من الجانب البصري وصولاً إلى الحوار والوتيرة، حيث يتم التأكد من وجود كل تفصيل في مشهده المناسب واحترام ما تبحث عنه علامتك التجارية. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="التصوير الفوتوغرافي"
                            description="نقدم التصوير الفوتوغرافي عالي الجودة في عصرنا الرقمي من خلال إدارة التصوير ومعالجة وتحرير الصور. تركيزنا على المسرحية والإضاءة يلتقط التفاصيل اللافتة للنظر والتي يجب استخدامها في حملات الإعلان والمواقع الإلكترونية لتحقيق نتائج أفضل."
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '150px', marginLeft: '0px' }}>
                        <Dropdown2
                            title="الإعداد الأولي والإنتاج الثانوي"
                            description="يوفر فريقنا حلولًا سينمائية شاملة لكل مشروع يأتينا بحيث يغطي عناء الإعداد الأولي والإنتاج والإنتاج الثانوي.
نبدأ بترتيب خطة تنفيذية بناءً على الميزانية المخصصة والمدى المطلوب مع احترام المبادئ العالمية. ثم نقوم بتصوير المشاهد مع الممثلين والأدوات المختارة وننهيها في مرحلة الإنتاج الثانوي حيث نتأكد من تمثيل الإبداع والجودة لخبرتنا وخبرتك

                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="التأثيرات الخاصة والتأثيرات المرئية الخاصة"
                            description="يتمحور أولوية فريق الإنتاج الخاص بنا حول تعزيز سرد قصتك من خلال اللجوء إلى التأثيرات الخاصة العملية أثناء تصوير المشاهد.
فريقنا دائمًا مستعد لإنشاء شيء غير موجود حاليًا مما يضع إبداعنا في العمل.
نجمع التأثيرات المرئية المتعلقة بحركة الكاميرا والإضاءة مع التأثيرات الخاصة التي لا تحتاج إلى تكنولوجيا ولكنها مطلوبة أثناء التصوير لخلق عدد غير محدود من حركات المشهد المختلفة.
الرسومات المدمجة بشكل مناسب هي ما يجعل المشاهد تبدو حقيقية وإعادة إنشاء لحظات غير واقعية على الشاشة هو ما يجعلها أجمل للمشاهدة!

                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="استوديو الشاشة الخضراء والفريق التمثيلي"
                            description="سواء أردت تصوير فيديوهات التعلُّم الإلكترونيَّة، أو المقابلات، أو أي مشهد آخر، فإن استوديو كروما كي هو في تصرُّفك!
يحمل فريقنا الإبداعي شغفًا بالأفلام ويتمتع بمنشآت إنتاجية أخرى لترجمة رسالتك بإطار لا يُنسى، ما يجعل جمهورك متشوِّقًا ومثيرًا لخطط مستقبلك.
والعمل مع ألفا كرييتيف يعني أن إيجاد الطاقم المناسب والفريق التمثيلي هو مسؤوليتنا الوحيدة لتحقيق لحظة افتراضية عالية الجودة وذات تأثير رائع يُظهر الألوان والملمس المختلفة بشكل احترافي.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        إنتاج الفيديو / الأفلام

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="ابتكار الفكرة وكتابة السيناريو والقصة"
                            description="يتعامل فريق الإنتاج الإبداعي لدينا مع إبتكار الأفكار وكتابة السيناريو للفيديو من الصفر، حيث يتم التغطية لجميع المراحل بدءًا من كتابة المسودة الأولى وحتى مرحلة المراجعة والتسليم النهائي. تتميز عملية الإنتاج الإبداعية لدينا بالاهتمام الشديد بالتفاصيل والذي يساعد في تيسير عملية الإنشاء بدءًا من الجانب البصري وصولاً إلى الحوار والوتيرة، حيث يتم التأكد من وجود كل تفصيل في مشهده المناسب واحترام ما تبحث عنه علامتك التجارية. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="التصوير الفوتوغرافي"
                            description="نقدم التصوير الفوتوغرافي عالي الجودة في عصرنا الرقمي من خلال إدارة التصوير ومعالجة وتحرير الصور. تركيزنا على المسرحية والإضاءة يلتقط التفاصيل اللافتة للنظر والتي يجب استخدامها في حملات الإعلان والمواقع الإلكترونية لتحقيق نتائج أفضل."
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '150px', marginLeft: '0px' }}>
                        <Dropdown2
                            title="الإعداد الأولي والإنتاج الثانوي"
                            description="يوفر فريقنا حلولًا سينمائية شاملة لكل مشروع يأتينا بحيث يغطي عناء الإعداد الأولي والإنتاج والإنتاج الثانوي.
نبدأ بترتيب خطة تنفيذية بناءً على الميزانية المخصصة والمدى المطلوب مع احترام المبادئ العالمية. ثم نقوم بتصوير المشاهد مع الممثلين والأدوات المختارة وننهيها في مرحلة الإنتاج الثانوي حيث نتأكد من تمثيل الإبداع والجودة لخبرتنا وخبرتك

                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="التأثيرات الخاصة والتأثيرات المرئية الخاصة"
                            description="يتمحور أولوية فريق الإنتاج الخاص بنا حول تعزيز سرد قصتك من خلال اللجوء إلى التأثيرات الخاصة العملية أثناء تصوير المشاهد.
فريقنا دائمًا مستعد لإنشاء شيء غير موجود حاليًا مما يضع إبداعنا في العمل.
نجمع التأثيرات المرئية المتعلقة بحركة الكاميرا والإضاءة مع التأثيرات الخاصة التي لا تحتاج إلى تكنولوجيا ولكنها مطلوبة أثناء التصوير لخلق عدد غير محدود من حركات المشهد المختلفة.
الرسومات المدمجة بشكل مناسب هي ما يجعل المشاهد تبدو حقيقية وإعادة إنشاء لحظات غير واقعية على الشاشة هو ما يجعلها أجمل للمشاهدة!

                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="استوديو الشاشة الخضراء والفريق التمثيلي"
                            description="سواء أردت تصوير فيديوهات التعلُّم الإلكترونيَّة، أو المقابلات، أو أي مشهد آخر، فإن استوديو كروما كي هو في تصرُّفك!
يحمل فريقنا الإبداعي شغفًا بالأفلام ويتمتع بمنشآت إنتاجية أخرى لترجمة رسالتك بإطار لا يُنسى، ما يجعل جمهورك متشوِّقًا ومثيرًا لخطط مستقبلك.
والعمل مع ألفا كرييتيف يعني أن إيجاد الطاقم المناسب والفريق التمثيلي هو مسؤوليتنا الوحيدة لتحقيق لحظة افتراضية عالية الجودة وذات تأثير رائع يُظهر الألوان والملمس المختلفة بشكل احترافي.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        إنتاج الفيديو / الأفلام

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="ابتكار الفكرة وكتابة السيناريو والقصة"
                            description="يتعامل فريق الإنتاج الإبداعي لدينا مع إبتكار الأفكار وكتابة السيناريو للفيديو من الصفر، حيث يتم التغطية لجميع المراحل بدءًا من كتابة المسودة الأولى وحتى مرحلة المراجعة والتسليم النهائي. تتميز عملية الإنتاج الإبداعية لدينا بالاهتمام الشديد بالتفاصيل والذي يساعد في تيسير عملية الإنشاء بدءًا من الجانب البصري وصولاً إلى الحوار والوتيرة، حيث يتم التأكد من وجود كل تفصيل في مشهده المناسب واحترام ما تبحث عنه علامتك التجارية. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="التصوير الفوتوغرافي"
                            description="نقدم التصوير الفوتوغرافي عالي الجودة في عصرنا الرقمي من خلال إدارة التصوير ومعالجة وتحرير الصور. تركيزنا على المسرحية والإضاءة يلتقط التفاصيل اللافتة للنظر والتي يجب استخدامها في حملات الإعلان والمواقع الإلكترونية لتحقيق نتائج أفضل."
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '150px', marginLeft: '0px' }}>
                        <Dropdown2
                            title="الإعداد الأولي والإنتاج الثانوي"
                            description="يوفر فريقنا حلولًا سينمائية شاملة لكل مشروع يأتينا بحيث يغطي عناء الإعداد الأولي والإنتاج والإنتاج الثانوي.
نبدأ بترتيب خطة تنفيذية بناءً على الميزانية المخصصة والمدى المطلوب مع احترام المبادئ العالمية. ثم نقوم بتصوير المشاهد مع الممثلين والأدوات المختارة وننهيها في مرحلة الإنتاج الثانوي حيث نتأكد من تمثيل الإبداع والجودة لخبرتنا وخبرتك

                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            {/* الهوية البصرية */}

            <div ref={visual} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-start' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="الرسوم البيانية التجارية وBTL"
                            description="إن جلب عملائك للاهتمام بمنتجاتك وخدماتك يتطلب توفير مساحة حماسية وملونة لعملائك للحصول على تجربة عالية اللمسة مع عملك.
يطبق مصممونا مع المسوقين لدينا مختلف التقنيات الرسومية لعرض معنى علامتك التجارية.
تكمن قوتنا في BTL والرسوم البيانية التجارية في جمع خصائص جمهورك المستهدف والتركيز على جذب انتباههم لكسب التحويلات عبر الإنترنت وخارجها.

                            "
                            toggle={false}
                        />

                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${visual1})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الهوية البصرية

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="هوية ودليل العلامة التجارية"
                            description="مثل أي كيان، تحتاج الهوية إلى أن تُعطى لعلامتك التجارية.
يقوم فريقنا بالتنقيب العميق في إبداعهم لكي تبرز مع هوية موحدة.
بعد الجلوس معك وفهم عملك بشكل أفضل، نقوم بتعريف وتحسين الإرشادات البصرية لتصبح موحدة عبر جميع وسائل التسويق والإعلام لإظهار تماسك واتساق علامتك التجارية.
تُجيب الإرشادات البصرية على أولويتنا في تحويل عملك إلى واحد مميز وعالي الجودة.

                            
                        "
                            toggle={false}
                        />
                    </div>


                </div>
            </div>
            <div className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="الرسوم البيانية التجارية وBTL"
                            description="إن جلب عملائك للاهتمام بمنتجاتك وخدماتك يتطلب توفير مساحة حماسية وملونة لعملائك للحصول على تجربة عالية اللمسة مع عملك.
يطبق مصممونا مع المسوقين لدينا مختلف التقنيات الرسومية لعرض معنى علامتك التجارية.
تكمن قوتنا في BTL والرسوم البيانية التجارية في جمع خصائص جمهورك المستهدف والتركيز على جذب انتباههم لكسب التحويلات عبر الإنترنت وخارجها.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${visual1})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الهوية البصرية

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="هوية ودليل العلامة التجارية"
                            description="مثل أي كيان، تحتاج الهوية إلى أن تُعطى لعلامتك التجارية.
يقوم فريقنا بالتنقيب العميق في إبداعهم لكي تبرز مع هوية موحدة.
بعد الجلوس معك وفهم عملك بشكل أفضل، نقوم بتعريف وتحسين الإرشادات البصرية لتصبح موحدة عبر جميع وسائل التسويق والإعلام لإظهار تماسك واتساق علامتك التجارية.
تُجيب الإرشادات البصرية على أولويتنا في تحويل عملك إلى واحد مميز وعالي الجودة.

                            
                        "
                            toggle={false}
                        />
                    </div>

                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="الرسوم البيانية التجارية وBTL"
                            description="إن جلب عملائك للاهتمام بمنتجاتك وخدماتك يتطلب توفير مساحة حماسية وملونة لعملائك للحصول على تجربة عالية اللمسة مع عملك.
يطبق مصممونا مع المسوقين لدينا مختلف التقنيات الرسومية لعرض معنى علامتك التجارية.
تكمن قوتنا في BTL والرسوم البيانية التجارية في جمع خصائص جمهورك المستهدف والتركيز على جذب انتباههم لكسب التحويلات عبر الإنترنت وخارجها.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${visual1})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الهوية البصرية

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>


                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="هوية ودليل العلامة التجارية"
                            description="مثل أي كيان، تحتاج الهوية إلى أن تُعطى لعلامتك التجارية.
يقوم فريقنا بالتنقيب العميق في إبداعهم لكي تبرز مع هوية موحدة.
بعد الجلوس معك وفهم عملك بشكل أفضل، نقوم بتعريف وتحسين الإرشادات البصرية لتصبح موحدة عبر جميع وسائل التسويق والإعلام لإظهار تماسك واتساق علامتك التجارية.
تُجيب الإرشادات البصرية على أولويتنا في تحويل عملك إلى واحد مميز وعالي الجودة.

                            
                        "
                            toggle={false}
                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="الرسوم البيانية التجارية وBTL"
                            description="إن جلب عملائك للاهتمام بمنتجاتك وخدماتك يتطلب توفير مساحة حماسية وملونة لعملائك للحصول على تجربة عالية اللمسة مع عملك.
يطبق مصممونا مع المسوقين لدينا مختلف التقنيات الرسومية لعرض معنى علامتك التجارية.
تكمن قوتنا في BTL والرسوم البيانية التجارية في جمع خصائص جمهورك المستهدف والتركيز على جذب انتباههم لكسب التحويلات عبر الإنترنت وخارجها.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${visual1})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الهوية البصرية

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="هوية ودليل العلامة التجارية"
                            description="مثل أي كيان، تحتاج الهوية إلى أن تُعطى لعلامتك التجارية.
يقوم فريقنا بالتنقيب العميق في إبداعهم لكي تبرز مع هوية موحدة.
بعد الجلوس معك وفهم عملك بشكل أفضل، نقوم بتعريف وتحسين الإرشادات البصرية لتصبح موحدة عبر جميع وسائل التسويق والإعلام لإظهار تماسك واتساق علامتك التجارية.
تُجيب الإرشادات البصرية على أولويتنا في تحويل عملك إلى واحد مميز وعالي الجودة.

                            
                        "
                            toggle={false}
                        />
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="الرسوم البيانية التجارية وBTL"
                            description="إن جلب عملائك للاهتمام بمنتجاتك وخدماتك يتطلب توفير مساحة حماسية وملونة لعملائك للحصول على تجربة عالية اللمسة مع عملك.
يطبق مصممونا مع المسوقين لدينا مختلف التقنيات الرسومية لعرض معنى علامتك التجارية.
تكمن قوتنا في BTL والرسوم البيانية التجارية في جمع خصائص جمهورك المستهدف والتركيز على جذب انتباههم لكسب التحويلات عبر الإنترنت وخارجها.

                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${visual1})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        الهوية البصرية

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="هوية ودليل العلامة التجارية"
                            description="مثل أي كيان، تحتاج الهوية إلى أن تُعطى لعلامتك التجارية.
يقوم فريقنا بالتنقيب العميق في إبداعهم لكي تبرز مع هوية موحدة.
بعد الجلوس معك وفهم عملك بشكل أفضل، نقوم بتعريف وتحسين الإرشادات البصرية لتصبح موحدة عبر جميع وسائل التسويق والإعلام لإظهار تماسك واتساق علامتك التجارية.
تُجيب الإرشادات البصرية على أولويتنا في تحويل عملك إلى واحد مميز وعالي الجودة.

                            
                        "
                            toggle={false}
                        />
                    </div>
                </div>
            </div>
            {/* Web dev */}

            <div ref={dev} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-end' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="تحسين صفحة الموقع على الموقع"
                            description="الامتلاك موقع ويب لا يكفي للتميز في محركات البحث.
يقوم مطورونا بتحسين موقع الويب الخاص بك لزيادة تصنيفه و رؤية المحتوى ومعدلات التحويل والاحتفاظ بالعملاء.
يتركز تحسين محرك البحث في الموقع الذي يتولاه خبراؤنا على:
سرعة الصفحة وكثافة الكلمات الرئيسية وعنوان URL وعلامات العنوان والوصف الفوقي وعلامات العنوان والارتباط الداخلي والصديقية للمحمول.
مع فريقنا إلى جانبك، ستتمكن من تشغيل عملك وموقع الويب وتطبيق الجوال والمنصات الرقمية بدون عيوب.
 
                            "
                            toggle={false}
                        />

                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${Dev})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        تصميم وتطوير مواقع الويب / التطبيقات
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                تتطلب كثرة الأعمال في الوقت الحالي وجود حلول متنوعة ومعقدة.
                                مهمتنا هي بناء هوية كاملة لك على الإنترنت وخارجها، والحصول على أفضل الحلول المبتكرة والمعقدة.
                                تجمع خبرتنا مع التقنيات لزيادة ثروة عملك.

                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="تصميم وتجربة المستخدم (UI / UX)"
                            description="توجهنا الفضولي الكبير في العالم الرقمي يوجهنا نحو أحدث الاتجاهات المستخدمة في عملية إنشاء مواقع الويب وتطبيقات الجوال. فهو يدفعنا للذهاب بعيدًا عن مجرد دمج حلول البرامج.
نقوم بتصميم موقعك الإلكتروني أو تطبيق الجوال لتوفير أفضل تجربة مستخدم مركزة على المستخدم. توجيه الأدوات والتقنيات المناسبة مع خبرتنا يضمن لك عائدًا استثماريًا أعلى وعلاقة أقوى بين العميل والشركة.
سواء كنا نصمم حلول UI / UX للويب أو iOS أو Android، فنحن نعالج مشاكل عملك ونحسنها بطرق متوافقة مع الإنسان.

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="تطوير تطبيقات الجوال"
                            description="لطالما تمت توصية بدعم موقع الويب الخاص بك بتطبيق جوال يحتوي على واجهة برمجة تطبيقات جيدة التنظيم ليتمكن مستخدموك من الوصول بسهولة والحصول على رحلة ممتعة.
العناية بواجهة المستخدم، ورمز التطبيق الآمن، والخوادم المتينة، وتقديم التطبيق في متجر التطبيقات (iOS) ومتجر (Play (Android

                            "
                            toggle={false}

                        />
                    </div>


                </div>
            </div>
            <div className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="تحسين صفحة الموقع على الموقع"
                            description="الامتلاك موقع ويب لا يكفي للتميز في محركات البحث.
يقوم مطورونا بتحسين موقع الويب الخاص بك لزيادة تصنيفه و رؤية المحتوى ومعدلات التحويل والاحتفاظ بالعملاء.
يتركز تحسين محرك البحث في الموقع الذي يتولاه خبراؤنا على:
سرعة الصفحة وكثافة الكلمات الرئيسية وعنوان URL وعلامات العنوان والوصف الفوقي وعلامات العنوان والارتباط الداخلي والصديقية للمحمول.
مع فريقنا إلى جانبك، ستتمكن من تشغيل عملك وموقع الويب وتطبيق الجوال والمنصات الرقمية بدون عيوب.
 
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${Dev})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        تصميم وتطوير مواقع الويب / التطبيقات
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                تتطلب كثرة الأعمال في الوقت الحالي وجود حلول متنوعة ومعقدة.
                                مهمتنا هي بناء هوية كاملة لك على الإنترنت وخارجها، والحصول على أفضل الحلول المبتكرة والمعقدة.
                                تجمع خبرتنا مع التقنيات لزيادة ثروة عملك.

                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="تصميم وتجربة المستخدم (UI / UX)"
                            description="توجهنا الفضولي الكبير في العالم الرقمي يوجهنا نحو أحدث الاتجاهات المستخدمة في عملية إنشاء مواقع الويب وتطبيقات الجوال. فهو يدفعنا للذهاب بعيدًا عن مجرد دمج حلول البرامج.
نقوم بتصميم موقعك الإلكتروني أو تطبيق الجوال لتوفير أفضل تجربة مستخدم مركزة على المستخدم. توجيه الأدوات والتقنيات المناسبة مع خبرتنا يضمن لك عائدًا استثماريًا أعلى وعلاقة أقوى بين العميل والشركة.
سواء كنا نصمم حلول UI / UX للويب أو iOS أو Android، فنحن نعالج مشاكل عملك ونحسنها بطرق متوافقة مع الإنسان.

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="تطوير تطبيقات الجوال"
                            description="لطالما تمت توصية بدعم موقع الويب الخاص بك بتطبيق جوال يحتوي على واجهة برمجة تطبيقات جيدة التنظيم ليتمكن مستخدموك من الوصول بسهولة والحصول على رحلة ممتعة.
العناية بواجهة المستخدم، ورمز التطبيق الآمن، والخوادم المتينة، وتقديم التطبيق في متجر التطبيقات (iOS) ومتجر (Play (Android

                            "
                            toggle={false}

                        />

                    </div>

                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="تحسين صفحة الموقع على الموقع"
                            description="الامتلاك موقع ويب لا يكفي للتميز في محركات البحث.
يقوم مطورونا بتحسين موقع الويب الخاص بك لزيادة تصنيفه و رؤية المحتوى ومعدلات التحويل والاحتفاظ بالعملاء.
يتركز تحسين محرك البحث في الموقع الذي يتولاه خبراؤنا على:
سرعة الصفحة وكثافة الكلمات الرئيسية وعنوان URL وعلامات العنوان والوصف الفوقي وعلامات العنوان والارتباط الداخلي والصديقية للمحمول.
مع فريقنا إلى جانبك، ستتمكن من تشغيل عملك وموقع الويب وتطبيق الجوال والمنصات الرقمية بدون عيوب.
 
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Dev})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        تصميم وتطوير مواقع الويب / التطبيقات
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                تتطلب كثرة الأعمال في الوقت الحالي وجود حلول متنوعة ومعقدة.
                                مهمتنا هي بناء هوية كاملة لك على الإنترنت وخارجها، والحصول على أفضل الحلول المبتكرة والمعقدة.
                                تجمع خبرتنا مع التقنيات لزيادة ثروة عملك.

                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>


                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="تصميم وتجربة المستخدم (UI / UX)"
                            description="توجهنا الفضولي الكبير في العالم الرقمي يوجهنا نحو أحدث الاتجاهات المستخدمة في عملية إنشاء مواقع الويب وتطبيقات الجوال. فهو يدفعنا للذهاب بعيدًا عن مجرد دمج حلول البرامج.
نقوم بتصميم موقعك الإلكتروني أو تطبيق الجوال لتوفير أفضل تجربة مستخدم مركزة على المستخدم. توجيه الأدوات والتقنيات المناسبة مع خبرتنا يضمن لك عائدًا استثماريًا أعلى وعلاقة أقوى بين العميل والشركة.
سواء كنا نصمم حلول UI / UX للويب أو iOS أو Android، فنحن نعالج مشاكل عملك ونحسنها بطرق متوافقة مع الإنسان.

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="تطوير تطبيقات الجوال"
                            description="لطالما تمت توصية بدعم موقع الويب الخاص بك بتطبيق جوال يحتوي على واجهة برمجة تطبيقات جيدة التنظيم ليتمكن مستخدموك من الوصول بسهولة والحصول على رحلة ممتعة.
العناية بواجهة المستخدم، ورمز التطبيق الآمن، والخوادم المتينة، وتقديم التطبيق في متجر التطبيقات (iOS) ومتجر (Play (Android

                            "
                            toggle={false}

                        />

                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="تحسين صفحة الموقع على الموقع"
                            description="الامتلاك موقع ويب لا يكفي للتميز في محركات البحث.
يقوم مطورونا بتحسين موقع الويب الخاص بك لزيادة تصنيفه و رؤية المحتوى ومعدلات التحويل والاحتفاظ بالعملاء.
يتركز تحسين محرك البحث في الموقع الذي يتولاه خبراؤنا على:
سرعة الصفحة وكثافة الكلمات الرئيسية وعنوان URL وعلامات العنوان والوصف الفوقي وعلامات العنوان والارتباط الداخلي والصديقية للمحمول.
مع فريقنا إلى جانبك، ستتمكن من تشغيل عملك وموقع الويب وتطبيق الجوال والمنصات الرقمية بدون عيوب.
 
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Dev})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        تصميم وتطوير مواقع الويب / التطبيقات
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                تتطلب كثرة الأعمال في الوقت الحالي وجود حلول متنوعة ومعقدة.
                                مهمتنا هي بناء هوية كاملة لك على الإنترنت وخارجها، والحصول على أفضل الحلول المبتكرة والمعقدة.
                                تجمع خبرتنا مع التقنيات لزيادة ثروة عملك.

                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="تصميم وتجربة المستخدم (UI / UX)"
                            description="توجهنا الفضولي الكبير في العالم الرقمي يوجهنا نحو أحدث الاتجاهات المستخدمة في عملية إنشاء مواقع الويب وتطبيقات الجوال. فهو يدفعنا للذهاب بعيدًا عن مجرد دمج حلول البرامج.
نقوم بتصميم موقعك الإلكتروني أو تطبيق الجوال لتوفير أفضل تجربة مستخدم مركزة على المستخدم. توجيه الأدوات والتقنيات المناسبة مع خبرتنا يضمن لك عائدًا استثماريًا أعلى وعلاقة أقوى بين العميل والشركة.
سواء كنا نصمم حلول UI / UX للويب أو iOS أو Android، فنحن نعالج مشاكل عملك ونحسنها بطرق متوافقة مع الإنسان.

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="تطوير تطبيقات الجوال"
                            description="لطالما تمت توصية بدعم موقع الويب الخاص بك بتطبيق جوال يحتوي على واجهة برمجة تطبيقات جيدة التنظيم ليتمكن مستخدموك من الوصول بسهولة والحصول على رحلة ممتعة.
العناية بواجهة المستخدم، ورمز التطبيق الآمن، والخوادم المتينة، وتقديم التطبيق في متجر التطبيقات (iOS) ومتجر (Play (Android

                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="تحسين صفحة الموقع على الموقع"
                            description="الامتلاك موقع ويب لا يكفي للتميز في محركات البحث.
يقوم مطورونا بتحسين موقع الويب الخاص بك لزيادة تصنيفه و رؤية المحتوى ومعدلات التحويل والاحتفاظ بالعملاء.
يتركز تحسين محرك البحث في الموقع الذي يتولاه خبراؤنا على:
سرعة الصفحة وكثافة الكلمات الرئيسية وعنوان URL وعلامات العنوان والوصف الفوقي وعلامات العنوان والارتباط الداخلي والصديقية للمحمول.
مع فريقنا إلى جانبك، ستتمكن من تشغيل عملك وموقع الويب وتطبيق الجوال والمنصات الرقمية بدون عيوب.
 
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Dev})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        تصميم وتطوير مواقع الويب / التطبيقات
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                تتطلب كثرة الأعمال في الوقت الحالي وجود حلول متنوعة ومعقدة.
                                مهمتنا هي بناء هوية كاملة لك على الإنترنت وخارجها، والحصول على أفضل الحلول المبتكرة والمعقدة.
                                تجمع خبرتنا مع التقنيات لزيادة ثروة عملك.

                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="تصميم وتجربة المستخدم (UI / UX)"
                            description="توجهنا الفضولي الكبير في العالم الرقمي يوجهنا نحو أحدث الاتجاهات المستخدمة في عملية إنشاء مواقع الويب وتطبيقات الجوال. فهو يدفعنا للذهاب بعيدًا عن مجرد دمج حلول البرامج.
نقوم بتصميم موقعك الإلكتروني أو تطبيق الجوال لتوفير أفضل تجربة مستخدم مركزة على المستخدم. توجيه الأدوات والتقنيات المناسبة مع خبرتنا يضمن لك عائدًا استثماريًا أعلى وعلاقة أقوى بين العميل والشركة.
سواء كنا نصمم حلول UI / UX للويب أو iOS أو Android، فنحن نعالج مشاكل عملك ونحسنها بطرق متوافقة مع الإنسان.

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="تطوير تطبيقات الجوال"
                            description="لطالما تمت توصية بدعم موقع الويب الخاص بك بتطبيق جوال يحتوي على واجهة برمجة تطبيقات جيدة التنظيم ليتمكن مستخدموك من الوصول بسهولة والحصول على رحلة ممتعة.
العناية بواجهة المستخدم، ورمز التطبيق الآمن، والخوادم المتينة، وتقديم التطبيق في متجر التطبيقات (iOS) ومتجر (Play (Android

                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Services1;