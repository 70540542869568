import React from 'react'
import './Navbar.css'
import { useNavigate } from 'react-router-dom'

export function Navbar() {
    const navigate = useNavigate();
    const HomeClick = () => {
        navigate("/");
    }

    const AboutClick = () => {
        navigate('/about');
    }

    const ContactClick = () => {
        navigate('/contact');
    }
    const ServicesClick = () => {
        navigate('/services');
    }
    



    return (
        <div className="row">
            <div className="row d-xs d-lg-none mx-0 px-0">
                <svg onClick={() => HomeClick()} width="357.999" height="208.753" viewBox="0 0 357.999 208.753">
                    <defs>
                        <clipPath id="clip-path">
                            <rect id="Rectangle_201" data-name="Rectangle 201" width="357.999" height="208.753" fill="none" />
                        </clipPath>
                    </defs>
                    <g id="Group_386" data-name="Group 386" clip-path="url(#clip-path)">
                        <text id="ALPHA" transform="translate(0.317 196.572)" fill="#fff" font-size="25" font-family="Cleanvertising-Black, Cleanvertising" font-weight="800"><tspan x="0" y="0" letter-spacing="0.349em">A</tspan><tspan y="0" letter-spacing="0.35em">LPH</tspan><tspan y="0">A</tspan></text>
                        <text id="CREATIVE" transform="translate(157.013 196.572)" fill="#fff" font-size="25" font-family="Cleanvertising-Light, Cleanvertising " font-weight="300"><tspan x="0" y="0" letter-spacing="0.33em">CRE</tspan><tspan y="0" letter-spacing="0.256em">A</tspan><tspan y="0" letter-spacing="0.33em">T</tspan><tspan y="0" letter-spacing="0.329em">I</tspan><tspan y="0" letter-spacing="0.33em">V</tspan><tspan y="0">E</tspan></text>
                        <path id="Path_342" data-name="Path 342" d="M152.183,68.569c-6.768-3.889-13.49-7.857-20.047-12.091-8.844-5.712-9.534-4.47-5.8-10.713Q137.289,27.42,148.207,9.047c.5-.836.966-1.686,1.463-2.522,1.245-2.1,1.477-4.217-.659-5.767-2.217-1.605-3.991-.438-5.552,1.4-10.747,12.669-21.565,25.28-32.245,38-1.582,1.884-2.717,2.026-4.774.755C94.321,33.43,82.114,26.084,69.944,18.678a24.334,24.334,0,0,0-7.3-3.41c-10.193-2.252-19.179,5.172-19.434,16.157q-.749,32.369-1.4,64.737c-.244,11.583-.621,23.167-.676,34.75a15.859,15.859,0,0,0,2.023,7.828l0-.006,0,.006a15.084,15.084,0,0,0,13.775,7.854c6.345-.261,11.226-2.873,14.451-8.324l34.532-58.48L116.96,59.822,109.977,80.96c8.53,4.954,21.341,12.117,27.2,15.632a13.4,13.4,0,0,0,11.813,1.489c6.707-2.394,10.721-7.18,11.729-14.178,1.013-7.03-2.543-11.9-8.53-15.333M65.84,99.433l8.466-25.622.046.1c-.052-2.853-.07-5.036-.009-5.6.543-5.07,3.5-6.713,8.135-4.661,6.586,2.917.9,8.536-5.935,16.416Z" transform="translate(78.265 0)" fill="#fff" />
                        <path id="Path_343" data-name="Path 343" d="M63.461,40.58a1.039,1.039,0,0,1,1.425-.369c.813.479,1.7,1,2.63,1.538L74.5,20.612Z" transform="translate(120.726 39.213)" fill="#dbdbdb" />
                        <path id="Path_344" data-name="Path 344" d="M58.286,35.5c-.046-2.032-.09-4.026-.122-5.77l.122-6.51L49.651,55.252l10.7-19.365c-.081.093-.16.183-.238.276a1.044,1.044,0,0,1-1.831-.659" transform="translate(94.455 44.182)" fill="#dbdbdb" />
                    </g>
                </svg>
            </div>
            <div className="col-2 d-none d-lg-block" >
                <svg width="357.999" height="208.753" viewBox="0 0 357.999 208.753">
                    <defs>
                        <clipPath id="clip-path">
                            <rect id="Rectangle_201" data-name="Rectangle 201" width="357.999" height="208.753" fill="none" />
                        </clipPath>
                    </defs>
                    <g id="Group_386" data-name="Group 386" clip-path="url(#clip-path)">
                        <text id="ALPHA" transform="translate(0.317 196.572)" fill="#fff" font-size="25" font-family="Cleanvertising-Black, Cleanvertising" font-weight="800"><tspan x="0" y="0" letter-spacing="0.349em">A</tspan><tspan y="0" letter-spacing="0.35em">LPH</tspan><tspan y="0">A</tspan></text>
                        <text id="CREATIVE" transform="translate(157.013 196.572)" fill="#fff" font-size="25" font-family="Cleanvertising-Light, Cleanvertising " font-weight="300"><tspan x="0" y="0" letter-spacing="0.33em">CRE</tspan><tspan y="0" letter-spacing="0.256em">A</tspan><tspan y="0" letter-spacing="0.33em">T</tspan><tspan y="0" letter-spacing="0.329em">I</tspan><tspan y="0" letter-spacing="0.33em">V</tspan><tspan y="0">E</tspan></text>
                        <path id="Path_342" data-name="Path 342" d="M152.183,68.569c-6.768-3.889-13.49-7.857-20.047-12.091-8.844-5.712-9.534-4.47-5.8-10.713Q137.289,27.42,148.207,9.047c.5-.836.966-1.686,1.463-2.522,1.245-2.1,1.477-4.217-.659-5.767-2.217-1.605-3.991-.438-5.552,1.4-10.747,12.669-21.565,25.28-32.245,38-1.582,1.884-2.717,2.026-4.774.755C94.321,33.43,82.114,26.084,69.944,18.678a24.334,24.334,0,0,0-7.3-3.41c-10.193-2.252-19.179,5.172-19.434,16.157q-.749,32.369-1.4,64.737c-.244,11.583-.621,23.167-.676,34.75a15.859,15.859,0,0,0,2.023,7.828l0-.006,0,.006a15.084,15.084,0,0,0,13.775,7.854c6.345-.261,11.226-2.873,14.451-8.324l34.532-58.48L116.96,59.822,109.977,80.96c8.53,4.954,21.341,12.117,27.2,15.632a13.4,13.4,0,0,0,11.813,1.489c6.707-2.394,10.721-7.18,11.729-14.178,1.013-7.03-2.543-11.9-8.53-15.333M65.84,99.433l8.466-25.622.046.1c-.052-2.853-.07-5.036-.009-5.6.543-5.07,3.5-6.713,8.135-4.661,6.586,2.917.9,8.536-5.935,16.416Z" transform="translate(78.265 0)" fill="#fff" />
                        <path id="Path_343" data-name="Path 343" d="M63.461,40.58a1.039,1.039,0,0,1,1.425-.369c.813.479,1.7,1,2.63,1.538L74.5,20.612Z" transform="translate(120.726 39.213)" fill="#dbdbdb" />
                        <path id="Path_344" data-name="Path 344" d="M58.286,35.5c-.046-2.032-.09-4.026-.122-5.77l.122-6.51L49.651,55.252l10.7-19.365c-.081.093-.16.183-.238.276a1.044,1.044,0,0,1-1.831-.659" transform="translate(94.455 44.182)" fill="#dbdbdb" />
                    </g>
                </svg>
            </div>

            <div
                className="col-10"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className="Nav row d-none d-lg-flex" style={{ justifyContent: 'center' }}>

                    <div className="col-2 Navbar" onClick={() => HomeClick()}>Home</div>
                    <div className="col-2 Navbar" onClick={() => AboutClick()}>About us</div>
                    <div className="col-2 Navbar" onClick={() => ServicesClick()}>Services</div>
                    <div className="col-2 Navbar">Portfolio</div>
                    <div className="col-2 Navbar">Our clients</div>
                    <div className="col-2 Navbar" onClick={() => ContactClick()}>Contact us</div>
                </div>

            </div>
        </div>
    )
}
