import { useEffect, useRef } from 'react';
import './Services.css';
import Image1 from './Images/Image1.png';
import Social from './Images/Image2.png'
import film from './Images/film.png'
import visual1 from './Images/Visual.png'
import Advertising from './Images/Advertising.png';
import Dev from './Images/Dev.png';
import { Navbar } from '../Navbar/Navbar';
import { Footer } from '../Footer/Footer';
import { Dropdown } from '../Dropdown component/Dropdown';
import { Dropdown2 } from '../Dropdown component/Dropdown2';

export function Services() {
    const ad = useRef(null);
    const dev = useRef(null);
    const visual = useRef(null);
    const video = useRef(null);
    const social = useRef(null);
    useEffect(() => {
        const hash = window.location.hash.substring(1);

        if (hash === 'ad' && ad.current) {
            ad.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === 'social' && social.current) {
            social.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === 'visual' && visual.current) {
            visual.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === 'dev' && dev.current) {
            dev.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === 'video' && video.current) {
            video.current.scrollIntoView({ behavior: 'smooth' });
        }

    }, []);

    return (
        <div className='col'>
            <Navbar />
            {/* Below the Navbar */}
            <div className="row d-none d-lg-flex" style={{ height: '400px', justifyContent: 'center' }}>

                <div className="col-4 align-self-center services" style={{ backgroundImage: `url(${Image1})`, color: 'White' }}>
                    SERVICES
                </div>

            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ height: '400px', justifyContent: 'center' }}>

                <div className="col-4 align-self-center services-md" style={{ backgroundImage: `url(${Image1})`, color: 'White' }}>
                    SERVICES
                </div>

            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ height: '400px', justifyContent: 'center' }}>

                <div className="col-4 align-self-center services-sm" style={{ backgroundImage: `url(${Image1})`, color: 'White' }}>
                    SERVICES
                </div>

            </div>
            <div className="row d-flex d-sm-none" style={{ height: '400px', justifyContent: 'center' }}>

                <div className="col-4 align-self-center services-xs" style={{ backgroundImage: `url(${Image1})`, color: 'White' }}>
                    SERVICES
                </div>

            </div>

            {/* Stars */}
            <div className="row d-none d-lg-flex" style={{ marginTop: '100px', marginBottom: '100px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="385.206" height="82.407" viewBox="0 0 385.206 82.407">

                    <g id="Group_417" data-name="Group 417" transform="translate(-733.896 -1118)">
                        <g id="_5" data-name="5" transform="translate(733.896 1118)">
                            <g id="row1">
                                <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(886.396 1118)">
                            <g id="row1-2" data-name="row1">
                                <path id="icon:5-2" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1038.896 1118)">
                            <g id="row1-3" data-name="row1">
                                <path id="icon:5-3" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                    </g>


                </svg>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', marginBottom: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <svg style={{ width: '300px' }} xmlns="http://www.w3.org/2000/svg" width="385.206" height="82.407" viewBox="0 0 385.206 82.407">

                    <g id="Group_417" data-name="Group 417" transform="translate(-733.896 -1118)">
                        <g id="_5" data-name="5" transform="translate(733.896 1118)">
                            <g id="row1">
                                <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(886.396 1118)">
                            <g id="row1-2" data-name="row1">
                                <path id="icon:5-2" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1038.896 1118)">
                            <g id="row1-3" data-name="row1">
                                <path id="icon:5-3" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                    </g>


                </svg>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ marginTop: '100px', marginBottom: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <svg style={{ width: '200px' }} xmlns="http://www.w3.org/2000/svg" width="385.206" height="82.407" viewBox="0 0 385.206 82.407">

                    <g id="Group_417" data-name="Group 417" transform="translate(-733.896 -1118)">
                        <g id="_5" data-name="5" transform="translate(733.896 1118)">
                            <g id="row1">
                                <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(886.396 1118)">
                            <g id="row1-2" data-name="row1">
                                <path id="icon:5-2" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1038.896 1118)">
                            <g id="row1-3" data-name="row1">
                                <path id="icon:5-3" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                    </g>


                </svg>
            </div>
            <div className="row d-flex d-sm-none" style={{ marginTop: '0px', marginBottom: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <svg style={{ width: '120px' }} xmlns="http://www.w3.org/2000/svg" width="385.206" height="82.407" viewBox="0 0 385.206 82.407">

                    <g id="Group_417" data-name="Group 417" transform="translate(-733.896 -1118)">
                        <g id="_5" data-name="5" transform="translate(733.896 1118)">
                            <g id="row1">
                                <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(886.396 1118)">
                            <g id="row1-2" data-name="row1">
                                <path id="icon:5-2" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1038.896 1118)">
                            <g id="row1-3" data-name="row1">
                                <path id="icon:5-3" data-name="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                            </g>
                        </g>
                    </g>


                </svg>
            </div>

            {/* Services */}
            {/* Advertising */}
            <div ref={ad} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-end' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="Creative content"
                            description="Before proceeding with our projects, marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performance allows us to move forward strategically. By understanding user personas and mapping their journey, we coherently rework our collected data to naturally appeal and attract potential leads."
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="Communication Strategy"
                            description="We are a data-driven team. 
                            We achieve positive results by revolving
                            our efforts around advanced methods of
                            targeting and retargeting, collecting 
                            digital trends and consumer data.
                            After successfully understanding your
                             industry and consumer’s needs, we 
                            simplify complex messages in a long
                            -term tactical framework that will be
                             shared using digital technologies 
                            with the aim of converting targeted 
                            audiences to potential customers and 
                            retaining them.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${Advertising})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Advertising<br />
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px' }}>
                                The digital ad age we live in pushes us to move<br />
                                forward and stand out with power and creativity.<br />
                                This is where our advertising services come in to support<br />
                                your projects and turn them into impactful promoted ones consumers<br />
                                won’t simply forget given their accuracy and originality.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Marketing Research"
                            description="Before proceeding with our projects,
                        marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performanceallows us to move forward strategically.  By understanding user personas and mapping their journey, we coherentlyrework our collected data to naturally appeal and attract potential leads.
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="SEO & SEM 
                        Campaigns Management"
                            description="Search Engine Optimization and Search
                        Engine Marketing generate more traffic 
                        and close more deals. Our skilled team 
                        handles your technical set-up with 
                        tailored keywords, on-page optimization,
                        and off-site SEO signals to organically 
                        maintain a high rank and increase 
                        awareness. Digging deep into how 
                        people tend to search for your brand 
                        and the services you provide will 
                        increase your website’s visibility, 
                        especially when paid promotions are 
                        applied after we create relevant ads 
                        on different online channels to beat the 
                        competition by guaranteeing more 
                        potential deals."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div  className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="Creative content"
                            description="Before proceeding with our projects, marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performance allows us to move forward strategically. By understanding user personas and mapping their journey, we coherently rework our collected data to naturally appeal and attract potential leads."
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="Communication Strategy"
                            description="We are a data-driven team. 
                            We achieve positive results by revolving
                            our efforts around advanced methods of
                            targeting and retargeting, collecting 
                            digital trends and consumer data.
                            After successfully understanding your
                             industry and consumer’s needs, we 
                            simplify complex messages in a long
                            -term tactical framework that will be
                             shared using digital technologies 
                            with the aim of converting targeted 
                            audiences to potential customers and 
                            retaining them.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${Advertising})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Advertising<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                The digital ad age we live in pushes us to move<br />
                                forward and stand out with power and creativity.<br />
                                This is where our advertising services come in to support<br />
                                your projects and turn them into impactful promoted ones consumers<br />
                                won’t simply forget given their accuracy and originality.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Marketing Research"
                            description="Before proceeding with our projects,
                        marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performanceallows us to move forward strategically.  By understanding user personas and mapping their journey, we coherentlyrework our collected data to naturally appeal and attract potential leads.
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="SEO & SEM 
                        Campaigns Management"
                            description="Search Engine Optimization and Search
                        Engine Marketing generate more traffic 
                        and close more deals. Our skilled team 
                        handles your technical set-up with 
                        tailored keywords, on-page optimization,
                        and off-site SEO signals to organically 
                        maintain a high rank and increase 
                        awareness. Digging deep into how 
                        people tend to search for your brand 
                        and the services you provide will 
                        increase your website’s visibility, 
                        especially when paid promotions are 
                        applied after we create relevant ads 
                        on different online channels to beat the 
                        competition by guaranteeing more 
                        potential deals."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div  className="row d-none d-md-flex d-lg-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="Creative content"
                            description="Before proceeding with our projects, marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performance allows us to move forward strategically. By understanding user personas and mapping their journey, we coherently rework our collected data to naturally appeal and attract potential leads."
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="Communication Strategy"
                            description="We are a data-driven team. 
                            We achieve positive results by revolving
                            our efforts around advanced methods of
                            targeting and retargeting, collecting 
                            digital trends and consumer data.
                            After successfully understanding your
                             industry and consumer’s needs, we 
                            simplify complex messages in a long
                            -term tactical framework that will be
                             shared using digital technologies 
                            with the aim of converting targeted 
                            audiences to potential customers and 
                            retaining them.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Advertising})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Advertising<br />
                        <span style={{ fontSize: '9px' }}>
                            <p style={{ marginTop: '50px' }}>
                                The digital ad age we live in pushes us to move<br />
                                forward and stand out with power and creativity.<br />
                                This is where our advertising services come in to support<br />
                                your projects and turn them into impactful promoted ones consumers<br />
                                won’t simply forget given their accuracy and originality.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Marketing Research"
                            description="Before proceeding with our projects,
                        marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performanceallows us to move forward strategically.  By understanding user personas and mapping their journey, we coherentlyrework our collected data to naturally appeal and attract potential leads.
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="SEO & SEM 
                        Campaigns Management"
                            description="Search Engine Optimization and Search
                        Engine Marketing generate more traffic 
                        and close more deals. Our skilled team 
                        handles your technical set-up with 
                        tailored keywords, on-page optimization,
                        and off-site SEO signals to organically 
                        maintain a high rank and increase 
                        awareness. Digging deep into how 
                        people tend to search for your brand 
                        and the services you provide will 
                        increase your website’s visibility, 
                        especially when paid promotions are 
                        applied after we create relevant ads 
                        on different online channels to beat the 
                        competition by guaranteeing more 
                        potential deals."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="Creative content"
                            description="Before proceeding with our projects, marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performance allows us to move forward strategically. By understanding user personas and mapping their journey, we coherently rework our collected data to naturally appeal and attract potential leads."
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="Communication Strategy"
                            description="We are a data-driven team. 
                            We achieve positive results by revolving
                            our efforts around advanced methods of
                            targeting and retargeting, collecting 
                            digital trends and consumer data.
                            After successfully understanding your
                             industry and consumer’s needs, we 
                            simplify complex messages in a long
                            -term tactical framework that will be
                             shared using digital technologies 
                            with the aim of converting targeted 
                            audiences to potential customers and 
                            retaining them.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Advertising})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Advertising<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                The digital ad age we live in pushes us to move<br />
                                forward and stand out with power and creativity.<br />
                                This is where our advertising services come in to support<br />
                                your projects and turn them into impactful promoted ones consumers<br />
                                won’t simply forget given their accuracy and originality.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Marketing Research"
                            description="Before proceeding with our projects,
                        marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performanceallows us to move forward strategically.  By understanding user personas and mapping their journey, we coherentlyrework our collected data to naturally appeal and attract potential leads.
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="SEO & SEM 
                        Campaigns Management"
                            description="Search Engine Optimization and Search
                        Engine Marketing generate more traffic 
                        and close more deals. Our skilled team 
                        handles your technical set-up with 
                        tailored keywords, on-page optimization,
                        and off-site SEO signals to organically 
                        maintain a high rank and increase 
                        awareness. Digging deep into how 
                        people tend to search for your brand 
                        and the services you provide will 
                        increase your website’s visibility, 
                        especially when paid promotions are 
                        applied after we create relevant ads 
                        on different online channels to beat the 
                        competition by guaranteeing more 
                        potential deals."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div  className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="Creative content"
                            description="Before proceeding with our projects, marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performance allows us to move forward strategically. By understanding user personas and mapping their journey, we coherently rework our collected data to naturally appeal and attract potential leads."
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="Communication Strategy"
                            description="We are a data-driven team. 
                            We achieve positive results by revolving
                            our efforts around advanced methods of
                            targeting and retargeting, collecting 
                            digital trends and consumer data.
                            After successfully understanding your
                             industry and consumer’s needs, we 
                            simplify complex messages in a long
                            -term tactical framework that will be
                             shared using digital technologies 
                            with the aim of converting targeted 
                            audiences to potential customers and 
                            retaining them.
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Advertising})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Advertising<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                The digital ad age we live in pushes us to move<br />
                                forward and stand out with power and creativity.<br />
                                This is where our advertising services come in to support<br />
                                your projects and turn them into impactful promoted ones consumers<br />
                                won’t simply forget given their accuracy and originality.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Marketing Research"
                            description="Before proceeding with our projects,
                        marketing research is the ultimate pass. Gathering useful findings regarding the project’s industry, target audience, competitors, and benchmarks’ activities and performanceallows us to move forward strategically.  By understanding user personas and mapping their journey, we coherentlyrework our collected data to naturally appeal and attract potential leads.
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '400px', marginLeft: '' }}>
                        <Dropdown2
                            title="SEO & SEM 
                        Campaigns Management"
                            description="Search Engine Optimization and Search
                        Engine Marketing generate more traffic 
                        and close more deals. Our skilled team 
                        handles your technical set-up with 
                        tailored keywords, on-page optimization,
                        and off-site SEO signals to organically 
                        maintain a high rank and increase 
                        awareness. Digging deep into how 
                        people tend to search for your brand 
                        and the services you provide will 
                        increase your website’s visibility, 
                        especially when paid promotions are 
                        applied after we create relevant ads 
                        on different online channels to beat the 
                        competition by guaranteeing more 
                        potential deals."
                            toggle={false}

                        />
                    </div>
                </div>
            </div>


            {/* Social Media */}
            <div ref={social} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-start' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" Social Media
                            Strategies and Activations"
                            description="Your online presence needs to be strategically studied and activated. 
                            Our social media experts promote your brand and sell your product or service on the most suitable platforms for your specific type of audience. 
                            We will showcase your values and story through media buying and engaging content fulfilling your marketing goals by increasing your brand awareness, growing your audience, boosting your community’s engagement, driving traffic to your site, and generating leads and sales. 
                            
                            The activations will be designed as stories, static images, short-form videos, etc. reflecting your human side and shared at the best performing time. 
                            Our team closely monitors the metrics and KPIs behind every move and applies small tweaks to improve your messages based on a trial-and-error process informing us of what is performing well.
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="Influencer
                            Marketing"
                            description="Our team relies on influencer marketing which has proven to be efficient. 
                            We proceed with your industry’s top influencers that can meet your audience’s expectations after carefully crafting a plan for them to endorse, promote, and place your products. 
                            This is an attractive cost-effective option for businesses since the influencers already have a well-established relationship with their followers guaranteeing that the message will be well received.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${Social})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Social Media<br />
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px' }}>
                                With everybody online, there’s a need to constantly<br />
                                attract them with a fresh outlook communicated<br />
                                on the platforms’ feeds. Our power resides in driving<br />
                                customers, growing your audience, and expanding<br />
                                your reach by impressing your clients,<br />
                                maintaining a long-lasting relationship<br />
                                with them, and promoting services and products<br />
                                with our out-of-the-box thinking<br />
                                aligned with collected insights on the platforms our<br />
                                audience spends most of their time on.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Pages creation"
                            description="Your business needs to be accessible and open to all audiences, and that’s where we step in. A vital part of your success will be in our hands, not just managing your online presence but creating it from scratch by focusing on flexible opportunities and strategies that will support and grow your brand. Our social media team will create your online profile on suitable platforms with the best practices and modern tactics to increase your number of followers by 
                            driving engagement and interest in what you have to offer. 
                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Content&nbsp;Creation"
                            description="Our agency stands out with its extravagant dose of creativity put to use when developing different types of content addressing your business objectives and audience’s needs. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="Community 
                            Management"
                            description="The sense of community is never to be taken lightly. Catering to your fans’ requests is vital for you to stand out and persevere in the competitive digital age we live in.  
                            Our team will not just represent your brand, it will also be actively listening and gathering feedback, complaints, and expectations. Your audience will have a place to connect, share and grow which will increase their interactions and conversions later on given the radiant trust and confidence your brand suggests. 
                             "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div  className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" Social Media
                            Strategies and Activations"
                            description="Your online presence needs to be strategically studied and activated. 
                            Our social media experts promote your brand and sell your product or service on the most suitable platforms for your specific type of audience. 
                            We will showcase your values and story through media buying and engaging content fulfilling your marketing goals by increasing your brand awareness, growing your audience, boosting your community’s engagement, driving traffic to your site, and generating leads and sales. 
                            
                            The activations will be designed as stories, static images, short-form videos, etc. reflecting your human side and shared at the best performing time. 
                            Our team closely monitors the metrics and KPIs behind every move and applies small tweaks to improve your messages based on a trial-and-error process informing us of what is performing well.
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="Influencer
                            Marketing"
                            description="Our team relies on influencer marketing which has proven to be efficient. 
                            We proceed with your industry’s top influencers that can meet your audience’s expectations after carefully crafting a plan for them to endorse, promote, and place your products. 
                            This is an attractive cost-effective option for businesses since the influencers already have a well-established relationship with their followers guaranteeing that the message will be well received.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Social Media<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                With everybody online, there’s a need to constantly<br />
                                attract them with a fresh outlook communicated<br />
                                on the platforms’ feeds. Our power resides in driving<br />
                                customers, growing your audience, and expanding<br />
                                your reach by impressing your clients,<br />
                                maintaining a long-lasting relationship<br />
                                with them, and promoting services and products<br />
                                with our out-of-the-box thinking<br />
                                aligned with collected insights on the platforms our<br />
                                audience spends most of their time on.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Pages creation"
                            description="Your business needs to be accessible and open to all audiences, and that’s where we step in. A vital part of your success will be in our hands, not just managing your online presence but creating it from scratch by focusing on flexible opportunities and strategies that will support and grow your brand. Our social media team will create your online profile on suitable platforms with the best practices and modern tactics to increase your number of followers by 
                            driving engagement and interest in what you have to offer. 
                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Content&nbsp;Creation"
                            description="Our agency stands out with its extravagant dose of creativity put to use when developing different types of content addressing your business objectives and audience’s needs. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="Community 
                            Management"
                            description="The sense of community is never to be taken lightly. Catering to your fans’ requests is vital for you to stand out and persevere in the competitive digital age we live in.  
                            Our team will not just represent your brand, it will also be actively listening and gathering feedback, complaints, and expectations. Your audience will have a place to connect, share and grow which will increase their interactions and conversions later on given the radiant trust and confidence your brand suggests. 
                             "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div  className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" Social Media
                            Strategies and Activations"
                            description="Your online presence needs to be strategically studied and activated. 
                            Our social media experts promote your brand and sell your product or service on the most suitable platforms for your specific type of audience. 
                            We will showcase your values and story through media buying and engaging content fulfilling your marketing goals by increasing your brand awareness, growing your audience, boosting your community’s engagement, driving traffic to your site, and generating leads and sales. 
                            
                            The activations will be designed as stories, static images, short-form videos, etc. reflecting your human side and shared at the best performing time. 
                            Our team closely monitors the metrics and KPIs behind every move and applies small tweaks to improve your messages based on a trial-and-error process informing us of what is performing well.
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="Influencer
                            Marketing"
                            description="Our team relies on influencer marketing which has proven to be efficient. 
                            We proceed with your industry’s top influencers that can meet your audience’s expectations after carefully crafting a plan for them to endorse, promote, and place your products. 
                            This is an attractive cost-effective option for businesses since the influencers already have a well-established relationship with their followers guaranteeing that the message will be well received.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Social Media<br />
                        <span style={{ fontSize: '9px' }}>
                            <p style={{ marginTop: '50px' }}>
                                With everybody online, there’s a need to constantly<br />
                                attract them with a fresh outlook communicated<br />
                                on the platforms’ feeds. Our power resides in driving<br />
                                customers, growing your audience, and expanding<br />
                                your reach by impressing your clients,<br />
                                maintaining a long-lasting relationship<br />
                                with them, and promoting services and products<br />
                                with our out-of-the-box thinking<br />
                                aligned with collected insights on the platforms our<br />
                                audience spends most of their time on.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Pages creation"
                            description="Your business needs to be accessible and open to all audiences, and that’s where we step in. A vital part of your success will be in our hands, not just managing your online presence but creating it from scratch by focusing on flexible opportunities and strategies that will support and grow your brand. Our social media team will create your online profile on suitable platforms with the best practices and modern tactics to increase your number of followers by 
                            driving engagement and interest in what you have to offer. 
                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Content&nbsp;Creation"
                            description="Our agency stands out with its extravagant dose of creativity put to use when developing different types of content addressing your business objectives and audience’s needs. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="Community 
                            Management"
                            description="The sense of community is never to be taken lightly. Catering to your fans’ requests is vital for you to stand out and persevere in the competitive digital age we live in.  
                            Our team will not just represent your brand, it will also be actively listening and gathering feedback, complaints, and expectations. Your audience will have a place to connect, share and grow which will increase their interactions and conversions later on given the radiant trust and confidence your brand suggests. 
                             "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" Social Media
                            Strategies and Activations"
                            description="Your online presence needs to be strategically studied and activated. 
                            Our social media experts promote your brand and sell your product or service on the most suitable platforms for your specific type of audience. 
                            We will showcase your values and story through media buying and engaging content fulfilling your marketing goals by increasing your brand awareness, growing your audience, boosting your community’s engagement, driving traffic to your site, and generating leads and sales. 
                            
                            The activations will be designed as stories, static images, short-form videos, etc. reflecting your human side and shared at the best performing time. 
                            Our team closely monitors the metrics and KPIs behind every move and applies small tweaks to improve your messages based on a trial-and-error process informing us of what is performing well.
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px' }}>
                        <Dropdown
                            title="Influencer
                            Marketing"
                            description="Our team relies on influencer marketing which has proven to be efficient. 
                            We proceed with your industry’s top influencers that can meet your audience’s expectations after carefully crafting a plan for them to endorse, promote, and place your products. 
                            This is an attractive cost-effective option for businesses since the influencers already have a well-established relationship with their followers guaranteeing that the message will be well received.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Social Media<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                With everybody online, there’s a need to constantly<br />
                                attract them with a fresh outlook communicated<br />
                                on the platforms’ feeds. Our power resides in driving<br />
                                customers, growing your audience, and expanding<br />
                                your reach by impressing your clients,<br />
                                maintaining a long-lasting relationship<br />
                                with them, and promoting services and products<br />
                                with our out-of-the-box thinking<br />
                                aligned with collected insights on the platforms our<br />
                                audience spends most of their time on.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Pages creation"
                            description="Your business needs to be accessible and open to all audiences, and that’s where we step in. A vital part of your success will be in our hands, not just managing your online presence but creating it from scratch by focusing on flexible opportunities and strategies that will support and grow your brand. Our social media team will create your online profile on suitable platforms with the best practices and modern tactics to increase your number of followers by 
                            driving engagement and interest in what you have to offer. 
                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Content&nbsp;Creation"
                            description="Our agency stands out with its extravagant dose of creativity put to use when developing different types of content addressing your business objectives and audience’s needs. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="Community 
                            Management"
                            description="The sense of community is never to be taken lightly. Catering to your fans’ requests is vital for you to stand out and persevere in the competitive digital age we live in.  
                            Our team will not just represent your brand, it will also be actively listening and gathering feedback, complaints, and expectations. Your audience will have a place to connect, share and grow which will increase their interactions and conversions later on given the radiant trust and confidence your brand suggests. 
                             "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title=" Social Media
                            Strategies and Activations"
                            description="Your online presence needs to be strategically studied and activated. 
                            Our social media experts promote your brand and sell your product or service on the most suitable platforms for your specific type of audience. 
                            We will showcase your values and story through media buying and engaging content fulfilling your marketing goals by increasing your brand awareness, growing your audience, boosting your community’s engagement, driving traffic to your site, and generating leads and sales. 
                            
                            The activations will be designed as stories, static images, short-form videos, etc. reflecting your human side and shared at the best performing time. 
                            Our team closely monitors the metrics and KPIs behind every move and applies small tweaks to improve your messages based on a trial-and-error process informing us of what is performing well.
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '' }}>
                        <Dropdown
                            title="Influencer
                            Marketing"
                            description="Our team relies on influencer marketing which has proven to be efficient. 
                            We proceed with your industry’s top influencers that can meet your audience’s expectations after carefully crafting a plan for them to endorse, promote, and place your products. 
                            This is an attractive cost-effective option for businesses since the influencers already have a well-established relationship with their followers guaranteeing that the message will be well received.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Social Media<br />
                        <span style={{ fontSize: '8px' }}>
                            <p style={{ marginTop: '50px' }}>
                                With everybody online, there’s a need to constantly<br />
                                attract them with a fresh outlook communicated<br />
                                on the platforms’ feeds. Our power resides in driving<br />
                                customers, growing your audience, and expanding<br />
                                your reach by impressing your clients,<br />
                                maintaining a long-lasting relationship<br />
                                with them, and promoting services and products<br />
                                with our out-of-the-box thinking<br />
                                aligned with collected insights on the platforms our<br />
                                audience spends most of their time on.<br />
                            </p>
                        </span>
                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Pages creation"
                            description="Your business needs to be accessible and open to all audiences, and that’s where we step in. A vital part of your success will be in our hands, not just managing your online presence but creating it from scratch by focusing on flexible opportunities and strategies that will support and grow your brand. Our social media team will create your online profile on suitable platforms with the best practices and modern tactics to increase your number of followers by 
                            driving engagement and interest in what you have to offer. 
                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Content&nbsp;Creation"
                            description="Our agency stands out with its extravagant dose of creativity put to use when developing different types of content addressing your business objectives and audience’s needs. "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '' }}>
                        <Dropdown2
                            title="Community 
                            Management"
                            description="The sense of community is never to be taken lightly. Catering to your fans’ requests is vital for you to stand out and persevere in the competitive digital age we live in.  
                            Our team will not just represent your brand, it will also be actively listening and gathering feedback, complaints, and expectations. Your audience will have a place to connect, share and grow which will increase their interactions and conversions later on given the radiant trust and confidence your brand suggests. 
                             "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            {/* Video and film */}

            <div ref={video} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-end' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="SFX & VFX"
                            description="Our production team’s number one priority is enhancing the narrative of your story by resorting to special practical effects when shooting scenes. Always being willing to create something that does not already exist puts our creativity into action. We combine visual effects related to the camera movement and lighting with special effects not requiring technology but are needed while shooting in order to create an unlimited amount of different scenic moves.
                            Properly integrated graphics are what makes scenes feel so real and recreating unrealistic moments on a screen is what makes it nicer to watch! 
                            
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="Green screen studio & cast"
                            description="Whether you want to shoot e-learning videos, interviews, or any sequence, our Chroma Key is at your disposal! 
                            Passionate about movies, our creatives have their hands on a green studio along with other production facilities to successfully translate your message in a memorable frame letting your audience hooked and excited about your future plans. 
                            Working with Alpha Creative means that finding the right crew and cast is our sole responsibility to realize professionally high-quality virtual moment showing different colors and textures.
                            
                            "
                            toggle={false}
                        />

                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${film})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Video/Film<br /> Production

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Concept&nbsp;creation, 
                            scriptwriting & storyboard"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '-50px' }}>
                        <Dropdown2
                            title="Photography"
                            description="We provide high-quality photography in our digital era by managing shooting, processing, and editing images. 
                            Our focus on staging and lighting captures eye-catching details that shall be used in your advertising campaigns and websites for better results. 
                             "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '300px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="Pre-production, 
                            production & post-production"
                            description="Our team caters to every project coming our way with a 360 cinematographic solution covering the hassles of pre-production, production, and post-production. We start by structuring an execution plan based on the allocated budget and requested scale respecting universal guidelines. We then film the scenes with the chosen actors and props and finally finalize it in the post-production phase where we make sure creativity and quality are properly reflecting our expertise and yours. 
 
                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="SFX & VFX"
                            description="Our production team’s number one priority is enhancing the narrative of your story by resorting to special practical effects when shooting scenes. Always being willing to create something that does not already exist puts our creativity into action. We combine visual effects related to the camera movement and lighting with special effects not requiring technology but are needed while shooting in order to create an unlimited amount of different scenic moves.
                            Properly integrated graphics are what makes scenes feel so real and recreating unrealistic moments on a screen is what makes it nicer to watch! 
                            
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="Green screen studio & cast"
                            description="Whether you want to shoot e-learning videos, interviews, or any sequence, our Chroma Key is at your disposal! 
                            Passionate about movies, our creatives have their hands on a green studio along with other production facilities to successfully translate your message in a memorable frame letting your audience hooked and excited about your future plans. 
                            Working with Alpha Creative means that finding the right crew and cast is our sole responsibility to realize professionally high-quality virtual moment showing different colors and textures.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${film})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Video/Film<br />Production

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Concept&nbsp;creation, 
                            scriptwriting & storyboard"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Photography"
                            description="We provide high-quality photography in our digital era by managing shooting, processing, and editing images. 
                            Our focus on staging and lighting captures eye-catching details that shall be used in your advertising campaigns and websites for better results. 
                             "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '300px', marginLeft: '0px' }}>
                        <Dropdown2
                            title="Pre-production, 
                            production & post-production"
                            description="Our team caters to every project coming our way with a 360 cinematographic solution covering the hassles of pre-production, production, and post-production. We start by structuring an execution plan based on the allocated budget and requested scale respecting universal guidelines. We then film the scenes with the chosen actors and props and finally finalize it in the post-production phase where we make sure creativity and quality are properly reflecting our expertise and yours. 
 
                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="SFX & VFX"
                            description="Our production team’s number one priority is enhancing the narrative of your story by resorting to special practical effects when shooting scenes. Always being willing to create something that does not already exist puts our creativity into action. We combine visual effects related to the camera movement and lighting with special effects not requiring technology but are needed while shooting in order to create an unlimited amount of different scenic moves.
                            Properly integrated graphics are what makes scenes feel so real and recreating unrealistic moments on a screen is what makes it nicer to watch! 
                            
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="Green screen studio & cast"
                            description="Whether you want to shoot e-learning videos, interviews, or any sequence, our Chroma Key is at your disposal! 
                            Passionate about movies, our creatives have their hands on a green studio along with other production facilities to successfully translate your message in a memorable frame letting your audience hooked and excited about your future plans. 
                            Working with Alpha Creative means that finding the right crew and cast is our sole responsibility to realize professionally high-quality virtual moment showing different colors and textures.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${film})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Video/Film<br />Production

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Concept&nbsp;creation, 
                            scriptwriting & storyboard"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Photography"
                            description="We provide high-quality photography in our digital era by managing shooting, processing, and editing images. 
                            Our focus on staging and lighting captures eye-catching details that shall be used in your advertising campaigns and websites for better results. 
                             "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '150px', marginLeft: '0px' }}>
                        <Dropdown2
                            title="Pre-production, 
                            production & post-production"
                            description="Our team caters to every project coming our way with a 360 cinematographic solution covering the hassles of pre-production, production, and post-production. We start by structuring an execution plan based on the allocated budget and requested scale respecting universal guidelines. We then film the scenes with the chosen actors and props and finally finalize it in the post-production phase where we make sure creativity and quality are properly reflecting our expertise and yours. 
 
                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="SFX & VFX"
                            description="Our production team’s number one priority is enhancing the narrative of your story by resorting to special practical effects when shooting scenes. Always being willing to create something that does not already exist puts our creativity into action. We combine visual effects related to the camera movement and lighting with special effects not requiring technology but are needed while shooting in order to create an unlimited amount of different scenic moves.
                            Properly integrated graphics are what makes scenes feel so real and recreating unrealistic moments on a screen is what makes it nicer to watch! 
                            
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="Green screen studio & cast"
                            description="Whether you want to shoot e-learning videos, interviews, or any sequence, our Chroma Key is at your disposal! 
                            Passionate about movies, our creatives have their hands on a green studio along with other production facilities to successfully translate your message in a memorable frame letting your audience hooked and excited about your future plans. 
                            Working with Alpha Creative means that finding the right crew and cast is our sole responsibility to realize professionally high-quality virtual moment showing different colors and textures.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Video/Film<br />Production

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Concept&nbsp;creation, 
                            scriptwriting & storyboard"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Photography"
                            description="We provide high-quality photography in our digital era by managing shooting, processing, and editing images. 
                            Our focus on staging and lighting captures eye-catching details that shall be used in your advertising campaigns and websites for better results. 
                             "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '150px', marginLeft: '0px' }}>
                        <Dropdown2
                            title="Pre-production, 
                            production & post-production"
                            description="Our team caters to every project coming our way with a 360 cinematographic solution covering the hassles of pre-production, production, and post-production. We start by structuring an execution plan based on the allocated budget and requested scale respecting universal guidelines. We then film the scenes with the chosen actors and props and finally finalize it in the post-production phase where we make sure creativity and quality are properly reflecting our expertise and yours. 
 
                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="SFX & VFX"
                            description="Our production team’s number one priority is enhancing the narrative of your story by resorting to special practical effects when shooting scenes. Always being willing to create something that does not already exist puts our creativity into action. We combine visual effects related to the camera movement and lighting with special effects not requiring technology but are needed while shooting in order to create an unlimited amount of different scenic moves.
                            Properly integrated graphics are what makes scenes feel so real and recreating unrealistic moments on a screen is what makes it nicer to watch! 
                            
                            "
                            toggle={false}
                        />
                    </div>
                    <div className="col-1" style={{ marginTop: '300px', marginLeft: '-50px' }}>
                        <Dropdown
                            title="Green screen studio & cast"
                            description="Whether you want to shoot e-learning videos, interviews, or any sequence, our Chroma Key is at your disposal! 
                            Passionate about movies, our creatives have their hands on a green studio along with other production facilities to successfully translate your message in a memorable frame letting your audience hooked and excited about your future plans. 
                            Working with Alpha Creative means that finding the right crew and cast is our sole responsibility to realize professionally high-quality virtual moment showing different colors and textures.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Social})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Video/Film<br />Production

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Concept&nbsp;creation, 
                            scriptwriting & storyboard"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Photography"
                            description="We provide high-quality photography in our digital era by managing shooting, processing, and editing images. 
                            Our focus on staging and lighting captures eye-catching details that shall be used in your advertising campaigns and websites for better results. 
                             "
                            toggle={false}

                        />
                    </div>
                    <div className="row" style={{ marginTop: '150px', marginLeft: '0px' }}>
                        <Dropdown2
                            title="Pre-production, 
                            production & post-production"
                            description="Our team caters to every project coming our way with a 360 cinematographic solution covering the hassles of pre-production, production, and post-production. We start by structuring an execution plan based on the allocated budget and requested scale respecting universal guidelines. We then film the scenes with the chosen actors and props and finally finalize it in the post-production phase where we make sure creativity and quality are properly reflecting our expertise and yours. 
 
                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            {/* Visual Identity */}

            <div ref={visual} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-start' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="Retail graphic and BTL"
                            description="Bringing your customers to take notice of your products and services requires offering an inviting vibrant space for your clients to have a high-touch experience with your business. 
                            Our designers aligned with our marketers apply various graphic techniques to display the meaning behind your brand. 
                            Our strength in Below The Line and Retail Graphics is in gathering your target audience’s characteristics and focusing on capturing their attention for you to gain offline and online conversions.
                            
                            "
                            toggle={false}
                        />

                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${visual1})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Visual Identity

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Brand identity & guidelines"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>


                </div>
            </div>
            <div className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="Retail graphic and BTL"
                            description="Bringing your customers to take notice of your products and services requires offering an inviting vibrant space for your clients to have a high-touch experience with your business. 
                            Our designers aligned with our marketers apply various graphic techniques to display the meaning behind your brand. 
                            Our strength in Below The Line and Retail Graphics is in gathering your target audience’s characteristics and focusing on capturing their attention for you to gain offline and online conversions.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${visual1})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Visual Identity

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Brand identity & guidelines"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>

                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                        <Dropdown
                            title="Retail graphic and BTL"
                            description="Bringing your customers to take notice of your products and services requires offering an inviting vibrant space for your clients to have a high-touch experience with your business. 
                            Our designers aligned with our marketers apply various graphic techniques to display the meaning behind your brand. 
                            Our strength in Below The Line and Retail Graphics is in gathering your target audience’s characteristics and focusing on capturing their attention for you to gain offline and online conversions.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${visual1})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Visual Identity

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>


                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                        <Dropdown2
                            title="Brand identity & guidelines"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                    <Dropdown
                            title="Retail graphic and BTL"
                            description="Bringing your customers to take notice of your products and services requires offering an inviting vibrant space for your clients to have a high-touch experience with your business. 
                            Our designers aligned with our marketers apply various graphic techniques to display the meaning behind your brand. 
                            Our strength in Below The Line and Retail Graphics is in gathering your target audience’s characteristics and focusing on capturing their attention for you to gain offline and online conversions.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${visual1})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        Visual Identity

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                        <Dropdown2
                            title="Brand identity & guidelines"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                    <Dropdown
                            title="Retail graphic and BTL"
                            description="Bringing your customers to take notice of your products and services requires offering an inviting vibrant space for your clients to have a high-touch experience with your business. 
                            Our designers aligned with our marketers apply various graphic techniques to display the meaning behind your brand. 
                            Our strength in Below The Line and Retail Graphics is in gathering your target audience’s characteristics and focusing on capturing their attention for you to gain offline and online conversions.
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${visual1})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                       Visual Identity

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                    <Dropdown2
                            title="Brand identity & guidelines"
                            description="Our creative production team handles concepts and video script creation from scratch by covering all stages from writing a first draft to the revision and final delivery stages. Our strong attention to detail is here to ease the creation process from the visual to the dialogue and pace by making sure that every detail is in its right scene respecting the mood your brand is looking for. 

                            
                        "
                            toggle={false}
                        />
                    </div>
                </div>
            </div>
            {/* Web dev */}
           
            <div ref={dev} className="row d-none d-xl-flex" style={{ justifyContent: 'flex-end' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                    <Dropdown
                            title="On-Site Page Optimization"
                            description="Owning a website is not enough to stand out on search engines. 
                            Our developers optimize your website to increase its ranking, visibility, conversion rates, and customer retention. 
                            On-site search engine optimization handled by our experts focuses on:
                            Page speed and keyword density, URL, title tags, meta description, heading tags, alt tags, internal linking, and mobile friendliness.
                            With our team by your side, you will be able to run your business, website, mobile app, and digital platforms flawlessly. 
                            
                            "
                            toggle={false}
                        />

                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '700px', width: '600px', marginTop: '50px', backgroundImage: `url(${Dev})` }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                    Web/App design<br />& development
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                The abundance of businesses nowadays
                                demands to have diverse and complex solutions.
                                Our mission is to build you a full offline and online identity making
                                the best out of diverse, innovative, and complex solutions.
                                Combining our expertise with the technicalities
                                increases your business’ wealth.
                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                    <Dropdown2
                            title="UI/UX"
                            description="Our vast curiosity in the digital world guides us towards the most modern trends applied in the website and mobile app creation process. It pushes us to go beyond simply integrating software solutions. We design your website or mobile app to provide the best user-centric experience. Aligning the right tools and techniques with our expertise guarantees you a higher return on investment and a stronger customer-company relationship. Whether we are designing UI/UX solutions for web, iOS, and Android, we tackle your business problems and improve them in human-centric ways. 

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="Mobile App Development"
                            description="It has always been recommended to support your website with a mobile application that has a well-structured API for your users to easily access and have a pleasant journey. 
                            Taking care of the user interface, secure app code, resilient back ends, and the application’s submission in the App Store (iOS) and Play Store (Android) ensures that our mobile experts are building your business a top-notch functional space
                            
                            "
                            toggle={false}

                        />
                    </div>


                </div>
            </div>
            <div className="row d-none d-lg-flex d-xl-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                    <Dropdown
                            title="On-Site Page Optimization"
                            description="Owning a website is not enough to stand out on search engines. 
                            Our developers optimize your website to increase its ranking, visibility, conversion rates, and customer retention. 
                            On-site search engine optimization handled by our experts focuses on:
                            Page speed and keyword density, URL, title tags, meta description, heading tags, alt tags, internal linking, and mobile friendliness.
                            With our team by your side, you will be able to run your business, website, mobile app, and digital platforms flawlessly. 
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '600px', width: '400px', marginTop: '50px', backgroundImage: `url(${Dev})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                    Web/App design<br />& development
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                The abundance of businesses nowadays
                                demands to have diverse and complex solutions.
                                Our mission is to build you a full offline and online identity making
                                the best out of diverse, innovative, and complex solutions.
                                Combining our expertise with the technicalities
                                increases your business’ wealth.
                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                    <Dropdown2
                            title="UI/UX"
                            description="Our vast curiosity in the digital world guides us towards the most modern trends applied in the website and mobile app creation process. It pushes us to go beyond simply integrating software solutions. We design your website or mobile app to provide the best user-centric experience. Aligning the right tools and techniques with our expertise guarantees you a higher return on investment and a stronger customer-company relationship. Whether we are designing UI/UX solutions for web, iOS, and Android, we tackle your business problems and improve them in human-centric ways. 

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="Mobile App Development"
                            description="It has always been recommended to support your website with a mobile application that has a well-structured API for your users to easily access and have a pleasant journey. 
                            Taking care of the user interface, secure app code, resilient back ends, and the application’s submission in the App Store (iOS) and Play Store (Android) ensures that our mobile experts are building your business a top-notch functional space
                            
                            "
                            toggle={false}

                        />
                    
                    </div>

                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{ marginTop: '100px', justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                    <Dropdown
                            title="On-Site Page Optimization"
                            description="Owning a website is not enough to stand out on search engines. 
                            Our developers optimize your website to increase its ranking, visibility, conversion rates, and customer retention. 
                            On-site search engine optimization handled by our experts focuses on:
                            Page speed and keyword density, URL, title tags, meta description, heading tags, alt tags, internal linking, and mobile friendliness.
                            With our team by your side, you will be able to run your business, website, mobile app, and digital platforms flawlessly. 
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Dev})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                    Web/App design<br />& development
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                The abundance of businesses nowadays
                                demands to have diverse and complex solutions.
                                Our mission is to build you a full offline and online identity making
                                the best out of diverse, innovative, and complex solutions.
                                Combining our expertise with the technicalities
                                increases your business’ wealth.
                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>


                    </div>
                    <div className="row" style={{ marginTop: '200px', marginLeft: '20px' }}>
                    <Dropdown2
                            title="UI/UX"
                            description="Our vast curiosity in the digital world guides us towards the most modern trends applied in the website and mobile app creation process. It pushes us to go beyond simply integrating software solutions. We design your website or mobile app to provide the best user-centric experience. Aligning the right tools and techniques with our expertise guarantees you a higher return on investment and a stronger customer-company relationship. Whether we are designing UI/UX solutions for web, iOS, and Android, we tackle your business problems and improve them in human-centric ways. 

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="Mobile App Development"
                            description="It has always been recommended to support your website with a mobile application that has a well-structured API for your users to easily access and have a pleasant journey. 
                            Taking care of the user interface, secure app code, resilient back ends, and the application’s submission in the App Store (iOS) and Play Store (Android) ensures that our mobile experts are building your business a top-notch functional space
                            
                            "
                            toggle={false}

                        />
                    
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                    <Dropdown
                            title="On-Site Page Optimization"
                            description="Owning a website is not enough to stand out on search engines. 
                            Our developers optimize your website to increase its ranking, visibility, conversion rates, and customer retention. 
                            On-site search engine optimization handled by our experts focuses on:
                            Page speed and keyword density, URL, title tags, meta description, heading tags, alt tags, internal linking, and mobile friendliness.
                            With our team by your side, you will be able to run your business, website, mobile app, and digital platforms flawlessly. 
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Dev})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                    Web/App design<br />& development
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                The abundance of businesses nowadays
                                demands to have diverse and complex solutions.
                                Our mission is to build you a full offline and online identity making
                                the best out of diverse, innovative, and complex solutions.
                                Combining our expertise with the technicalities
                                increases your business’ wealth.
                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                    <Dropdown2
                            title="UI/UX"
                            description="Our vast curiosity in the digital world guides us towards the most modern trends applied in the website and mobile app creation process. It pushes us to go beyond simply integrating software solutions. We design your website or mobile app to provide the best user-centric experience. Aligning the right tools and techniques with our expertise guarantees you a higher return on investment and a stronger customer-company relationship. Whether we are designing UI/UX solutions for web, iOS, and Android, we tackle your business problems and improve them in human-centric ways. 

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="Mobile App Development"
                            description="It has always been recommended to support your website with a mobile application that has a well-structured API for your users to easily access and have a pleasant journey. 
                            Taking care of the user interface, secure app code, resilient back ends, and the application’s submission in the App Store (iOS) and Play Store (Android) ensures that our mobile experts are building your business a top-notch functional space
                            
                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none" style={{ justifyContent: 'center' }}>
                <div className="col-2" style={{ marginTop: '100px', zIndex: '1' }}>
                    <div className="col">
                    <Dropdown
                            title="On-Site Page Optimization"
                            description="Owning a website is not enough to stand out on search engines. 
                            Our developers optimize your website to increase its ranking, visibility, conversion rates, and customer retention. 
                            On-site search engine optimization handled by our experts focuses on:
                            Page speed and keyword density, URL, title tags, meta description, heading tags, alt tags, internal linking, and mobile friendliness.
                            With our team by your side, you will be able to run your business, website, mobile app, and digital platforms flawlessly. 
                            
                            "
                            toggle={false}
                        />
                    </div>
                </div>
                <div className="col-3 align-self-end cover-img" style={{ height: '500px', width: '300px', marginTop: '50px', backgroundImage: `url(${Dev})`, borderRadius: '100px' }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                    Web/App design<br />& development
                        <span style={{ fontSize: '16px' }}>
                            <p style={{ marginTop: '50px', padding: '20px' }}>
                                The abundance of businesses nowadays
                                demands to have diverse and complex solutions.
                                Our mission is to build you a full offline and online identity making
                                the best out of diverse, innovative, and complex solutions.
                                Combining our expertise with the technicalities
                                increases your business’ wealth.
                            </p>
                        </span>

                    </div>
                </div>
                <div className="col-2" style={{ marginRight: '60px', marginLeft: '-70px' }}>
                    <div className="row" style={{ marginTop: '100px', marginLeft: '' }}>

                    <Dropdown2
                            title="UI/UX"
                            description="Our vast curiosity in the digital world guides us towards the most modern trends applied in the website and mobile app creation process. It pushes us to go beyond simply integrating software solutions. We design your website or mobile app to provide the best user-centric experience. Aligning the right tools and techniques with our expertise guarantees you a higher return on investment and a stronger customer-company relationship. Whether we are designing UI/UX solutions for web, iOS, and Android, we tackle your business problems and improve them in human-centric ways. 

                            
                        "
                            toggle={false}
                        />
                    </div>

                    <div className="row" style={{ marginTop: '500px', marginLeft: '-70px' }}>
                        <Dropdown2
                            title="Mobile App Development"
                            description="It has always been recommended to support your website with a mobile application that has a well-structured API for your users to easily access and have a pleasant journey. 
                            Taking care of the user interface, secure app code, resilient back ends, and the application’s submission in the App Store (iOS) and Play Store (Android) ensures that our mobile experts are building your business a top-notch functional space
                            
                            "
                            toggle={false}

                        />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}
