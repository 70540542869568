import React from 'react'
import { Navbar } from '../Navbar/Navbar'
import { Footer } from '../Footer/Footer'

import Facebook from './Images/Facebook.png'
import Youtube from './Images/Youtube.png'
import Instagram from './Images/Instagram.png'
import Twitter from './Images/Twitter.png'
import './Contact.css'
import { useNavigate } from 'react-router-dom'

function Contact1() {
    const navigate = useNavigate();
    const RequestClick = () => {
        navigate("/request/ar")
    }
    return (
        <div className='col container'>
            <Navbar />
            <div className="row">
                <div className="col" style={{ textAlign: 'center' }}>

                    <svg style={{
                        width: '50%'
                    }} xmlns="http://www.w3.org/2000/svg" width="1070" height="353" viewBox="0 0 1070 353">
                        <path id="Rectangle_269" data-name="Rectangle 269" d="M176.5,0h0A176.5,176.5,0,0,1,353,176.5V353a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V176.5A176.5,176.5,0,0,1,176.5,0Z" transform="translate(371)" fill="#8655a5" />
                        <text id="Contact_us" data-name="Contact us" transform="translate(0 251)" fill="#fff" font-size="200" font-family="LexendDeca-SemiBold, Lexend Deca" font-weight="600"><tspan x="0" y="0">تواصل معنا</tspan></text>
                    </svg>
                </div>

            </div>
            <div className="row justify-content-center">
                <div className="col" onClick={() => RequestClick()} style={{ textAlign: 'center' }}>
                    <svg className='request' onClick={() => RequestClick()} style={{
                        width: '15%',
                        marginLeft: '30px'
                    }} xmlns="http://www.w3.org/2000/svg" width="353" height="144" viewBox="0 0 353 144">
                        <g id="Group_415" data-name="Group 415" transform="translate(-813 -944)">
                            <g id="Rectangle_268" data-name="Rectangle 268" transform="translate(813 987)" fill="#fff" stroke="#707070" stroke-width="1">
                                <rect width="314" height="101" rx="50.5" stroke="none" />
                                <rect x="0.5" y="0.5" width="313" height="100" rx="50" fill="none" />
                            </g>
                            <text id="Request_a_quote" data-name="Request a quote" transform="translate(852 1049)" fill="#602f7f" font-size="29" font-family="LexendDeca-SemiBold, Lexend Deca" font-weight="600"><tspan x="0" y="0">Request a quote</tspan></text>
                            <ellipse id="Ellipse_113" data-name="Ellipse 113" cx="38.5" cy="38" rx="38.5" ry="38" transform="translate(1089 944)" />
                            <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M8.586,8.858H25.151L5.293,28.716l2.728,2.728L27.878,11.586V28.151h3.858V5H8.586Z" transform="translate(1108.872 963.391)" fill="#fff" />
                        </g>
                    </svg>
                </div>
            </div>
            <div className="row" style={{
                marginTop: '200px'
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="170.167" height="26.985" viewBox="0 0 170.167 26.985">
                    <g id="Group_416" data-name="Group 416" transform="translate(-884.459 -1351)">
                        <g id="_5" data-name="5" transform="translate(884.46 1351)">
                            <g id="row1" transform="translate(0)">
                                <path id="icon:5" d="M26.264,13.2l-7.859,1.391a4.775,4.775,0,0,0-3.878,4.132l-1.108,8.264L12.065,18.91a4.762,4.762,0,0,0-4.022-3.984L0,13.787,7.859,12.4a4.775,4.775,0,0,0,3.878-4.132L12.845,0,14.2,8.074a4.762,4.762,0,0,0,4.022,3.984Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(956.411 1351)">
                            <g id="row1-2" data-name="row1" transform="translate(0)">
                                <path id="icon:5-2" data-name="icon:5" d="M26.264,13.2l-7.859,1.391a4.775,4.775,0,0,0-3.878,4.132l-1.108,8.264L12.065,18.91a4.762,4.762,0,0,0-4.022-3.984L0,13.787,7.859,12.4a4.775,4.775,0,0,0,3.878-4.132L12.845,0,14.2,8.074a4.762,4.762,0,0,0,4.022,3.984Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1028.362 1351)">
                            <g id="row1-3" data-name="row1" transform="translate(0)">
                                <path id="icon:5-3" data-name="icon:5" d="M26.264,13.2l-7.859,1.391a4.775,4.775,0,0,0-3.878,4.132l-1.108,8.264L12.065,18.91a4.762,4.762,0,0,0-4.022-3.984L0,13.787,7.859,12.4a4.775,4.775,0,0,0,3.878-4.132L12.845,0,14.2,8.074a4.762,4.762,0,0,0,4.022,3.984Z" fill="#fff" />
                            </g>
                        </g>
                    </g>
                </svg>

            </div>
            <form type="POST">

                <div className="row justify-content-center mt-5">
                    <div className="col"></div>
                    <div className="col">

                        <div className="row details" style={{ direction: 'rtl' }}>رقم هاتف شركتك:</div>
                        <div className="row">
                            <input className='input' placeholder='Type here...' type='text'></input>
                        </div>
                        <div className="row details" style={{ direction: 'rtl' }}>الاسم الذي يمكن التواصل به:</div>
                        <div className="row">
                            <input className='input' placeholder='Type here...' type='text'></input>
                        </div>
                        <div className="row details" style={{ direction: 'rtl' }}>عنوان البريد الإلكتروني:</div>
                        <div className="row">
                            <input className='input' placeholder='Type here...' type='text'></input>
                        </div>
                        <div className="row details" style={{ direction: 'rtl' }}>البلد:</div>
                        <div className="row ">
                            <input className='input' placeholder='Type here...' type='text'></input>
                        </div>
                        <div className="row">
                            <div className="d-flex justify-content-end pt-4" style={{
                                paddingRight: '0px !important'
                            }}>

                                <button className='button'>Submit</button>

                            </div>
                        </div>
                    </div>
                    <div className="col"></div>
                </div>
            </form>
            <div className="row" style={{
                color: 'white',
                fontSize: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: '600',
                marginTop: '300px'
            }}>Stay Connected</div>
            <div className="row " style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '100px'
            }}>
                <div className="col-2">
                    <a href='https://www.facebook.com/profile.php?id=100090702415106'>
                        <img src={Facebook} style={{ width: '62%' }} alt='logo' />
                    </a>
                </div>
                <div className="col-2">
                    <a href='https://www.linkedin.com/company/alpha-creative-marketing-company/'>
                        <img src={Youtube} style={{ width: '80%' }} alt='logo' />
                    </a>
                </div>
                <div className="col-2">
                    <a href="https://instagram.com/alpha.creative.co?igshid=ZWQyN2ExYTkwZQ==">
                        <img src={Instagram} style={{ width: '62%' }} alt='logo' />

                    </a>
                </div>
                <div className="col-2">
                    <a href='https://twitter.com/alphacreativeco?s=11&t=63mfqNhNENb5OcSnqZvUKQ'>
                        <img src={Twitter} style={{ width: '62%' }} alt='logo' />

                    </a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact1;
