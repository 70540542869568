import React, { useState, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { Footer } from '../Footer/Footer';
import '../Navbar/Navbar.css'
import { Link, useNavigate } from 'react-router-dom'
import 'swiper/css';
import 'swiper/css/pagination';
import './Home.css'
import videoImage from './Images/videoImage.png'
import Image1 from './Images/Image1.png'
import PortfolioImage1 from './Images/PortfolioImage1.png'
import PortfolioImage2 from './Images/PortfolioImage2.png'
import PortfolioImage3 from './Images/PortfolioImage3.png'
import PortfolioImage4 from './Images/PortfolioImage4.png'
import PortfolioImage5 from './Images/PortfolioImage5.png'
import mindLogo from './Images/MindCare Logo.png'
import yaqeenLogo from './Images/YAQEEN OUTLINES.png';
import vastaLogo from './Images/vasta.png';
import itisLogo from './Images/ITIS-LOGO.png'
import msaderLogo from './Images/logo mubs.png'
import atharLogo from './Images/logo athar.png'
import deliverLogo from './Images/deliverit final logo .png'
import itracksLogo from './Images/logo Tracks.png'
import goodLogo from './Images/logo good food.png'


SwiperCore.use([Pagination]);
export function Home() {
    const navigate = useNavigate();

    const HomeClick = () => {
        navigate("/");
    }

    const AboutClick = () => {
        navigate('/about');
    }

    const ContactClick = () => {
        navigate('/contact');
    }
    const ServicesClick = () => {
        navigate('/services');
    }

    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const PortfolioClick = () => {
        const portfolio = ref1.current;
        if (portfolio) {
            portfolio.scrollIntoView();
        }
    }

    const ClientClick = () => {
        const client = ref2.current;
        if (client) {
            client.scrollIntoView();
        }
    }
    const burgerClick = () => {
        setHamburger(!hamburger);
    }

    const [hamburger, setHamburger] = useState(false);
    const [yaqeenToggle, setYaqeenToggle] = useState(false);
    const [mindToggle, setMindToggle] = useState(false);
    const [vastaToggle, setVastaToggle] = useState(false);
    const [itisToggle, setItisToggle] = useState(false);
    const [msaderToggle, setMsaderToggle] = useState(false);
    const [atharToggle, setAtharToggle] = useState(false);
    const [deliverToggle, setDeliverToggle] = useState(false);
    const [itracksToggle, setItracksToggle] = useState(false);
    const [goodToggle, setGoodToggle] = useState(false);

    const MindClick = () => {
        setMindToggle(!mindToggle);
    }

    const YaqeenClick = () => {
        setYaqeenToggle(!yaqeenToggle);
    }

    const VastaClick = () => {
        setVastaToggle(!vastaToggle);
    }

    const ItisClick = () => {
        setItisToggle(!itisToggle);
    }

    const MsaderClick = () => {
        setMsaderToggle(!msaderToggle);
    }

    const AtharClick = () => {
        setAtharToggle(!atharToggle);
    }

    const DeliverClick = () => {
        setDeliverToggle(!deliverToggle);
    }

    const ItracksClick = () => {
        setItracksToggle(!itracksToggle);
    }

    const GoodClick = () => {
        setGoodToggle(!goodToggle);
    }


    return (
        <div className='Header container-fluid row' style={{

        }}>
            <div id="mySidenav" class="sidenav">
                <a href="/ar" id="about">Arabic</a>
            </div>
            <div className="row">

                {/* Navbar */}
                <div className="row">
                    <div className="col-9 d-xs d-lg-none mx-0 px-0" style={{ alignItems: 'center' }}>



                        <svg style={{ width: '100%', marginLeft: '50px' }} width="357.999" height="208.753" viewBox="0 0 357.999 208.753">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_201" data-name="Rectangle 201" width="357.999" height="208.753" fill="none" />
                                </clipPath>
                            </defs>
                            <g id="Group_386" data-name="Group 386" clip-path="url(#clip-path)">
                                <text id="ALPHA" transform="translate(0.317 196.572)" fill="#fff" font-size="25" font-family="Cleanvertising-Black, Cleanvertising" font-weight="800"><tspan x="0" y="0" letter-spacing="0.349em">A</tspan><tspan y="0" letter-spacing="0.35em">LPH</tspan><tspan y="0">A</tspan></text>
                                <text id="CREATIVE" transform="translate(157.013 196.572)" fill="#fff" font-size="25" font-family="Cleanvertising-Light, Cleanvertising " font-weight="300"><tspan x="0" y="0" letter-spacing="0.33em">CRE</tspan><tspan y="0" letter-spacing="0.256em">A</tspan><tspan y="0" letter-spacing="0.33em">T</tspan><tspan y="0" letter-spacing="0.329em">I</tspan><tspan y="0" letter-spacing="0.33em">V</tspan><tspan y="0">E</tspan></text>
                                <path id="Path_342" data-name="Path 342" d="M152.183,68.569c-6.768-3.889-13.49-7.857-20.047-12.091-8.844-5.712-9.534-4.47-5.8-10.713Q137.289,27.42,148.207,9.047c.5-.836.966-1.686,1.463-2.522,1.245-2.1,1.477-4.217-.659-5.767-2.217-1.605-3.991-.438-5.552,1.4-10.747,12.669-21.565,25.28-32.245,38-1.582,1.884-2.717,2.026-4.774.755C94.321,33.43,82.114,26.084,69.944,18.678a24.334,24.334,0,0,0-7.3-3.41c-10.193-2.252-19.179,5.172-19.434,16.157q-.749,32.369-1.4,64.737c-.244,11.583-.621,23.167-.676,34.75a15.859,15.859,0,0,0,2.023,7.828l0-.006,0,.006a15.084,15.084,0,0,0,13.775,7.854c6.345-.261,11.226-2.873,14.451-8.324l34.532-58.48L116.96,59.822,109.977,80.96c8.53,4.954,21.341,12.117,27.2,15.632a13.4,13.4,0,0,0,11.813,1.489c6.707-2.394,10.721-7.18,11.729-14.178,1.013-7.03-2.543-11.9-8.53-15.333M65.84,99.433l8.466-25.622.046.1c-.052-2.853-.07-5.036-.009-5.6.543-5.07,3.5-6.713,8.135-4.661,6.586,2.917.9,8.536-5.935,16.416Z" transform="translate(78.265 0)" fill="#fff" />
                                <path id="Path_343" data-name="Path 343" d="M63.461,40.58a1.039,1.039,0,0,1,1.425-.369c.813.479,1.7,1,2.63,1.538L74.5,20.612Z" transform="translate(120.726 39.213)" fill="#dbdbdb" />
                                <path id="Path_344" data-name="Path 344" d="M58.286,35.5c-.046-2.032-.09-4.026-.122-5.77l.122-6.51L49.651,55.252l10.7-19.365c-.081.093-.16.183-.238.276a1.044,1.044,0,0,1-1.831-.659" transform="translate(94.455 44.182)" fill="#dbdbdb" />
                            </g>
                        </svg>


                    </div>
                    <div className="col-3 d-flex d-lg-none" onClick={() => burgerClick()} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <svg style={{ cursor: 'pointer' }} width="40" zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="40" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="id1"><path d="M 3.386719 7.164062 L 26.613281 7.164062 L 26.613281 22.40625 L 3.386719 22.40625 Z M 3.386719 7.164062 " clip-rule="nonzero" fill="white"></path></clipPath></defs><g clip-path="url(#id1)"><path fill="white" d="M 3.398438 22.40625 L 26.601562 22.40625 L 26.601562 19.867188 L 3.398438 19.867188 Z M 3.398438 16.054688 L 26.601562 16.054688 L 26.601562 13.515625 L 3.398438 13.515625 Z M 3.398438 7.164062 L 3.398438 9.703125 L 26.601562 9.703125 L 26.601562 7.164062 Z M 3.398438 7.164062 " fill-opacity="1" fill-rule="nonzero"></path></g></svg>
                    </div>
                    <div className="col-2 d-none d-lg-block" >
                        <svg width="357.999" height="208.753" viewBox="0 0 357.999 208.753">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect id="Rectangle_201" data-name="Rectangle 201" width="357.999" height="208.753" fill="none" />
                                </clipPath>
                            </defs>
                            <g id="Group_386" data-name="Group 386" clip-path="url(#clip-path)">
                                <text id="ALPHA" transform="translate(0.317 196.572)" fill="#fff" font-size="25" font-family="Cleanvertising-Black, Cleanvertising" font-weight="800"><tspan x="0" y="0" letter-spacing="0.349em">A</tspan><tspan y="0" letter-spacing="0.35em">LPH</tspan><tspan y="0">A</tspan></text>
                                <text id="CREATIVE" transform="translate(157.013 196.572)" fill="#fff" font-size="25" font-family="Cleanvertising-Light, Cleanvertising " font-weight="300"><tspan x="0" y="0" letter-spacing="0.33em">CRE</tspan><tspan y="0" letter-spacing="0.256em">A</tspan><tspan y="0" letter-spacing="0.33em">T</tspan><tspan y="0" letter-spacing="0.329em">I</tspan><tspan y="0" letter-spacing="0.33em">V</tspan><tspan y="0">E</tspan></text>
                                <path id="Path_342" data-name="Path 342" d="M152.183,68.569c-6.768-3.889-13.49-7.857-20.047-12.091-8.844-5.712-9.534-4.47-5.8-10.713Q137.289,27.42,148.207,9.047c.5-.836.966-1.686,1.463-2.522,1.245-2.1,1.477-4.217-.659-5.767-2.217-1.605-3.991-.438-5.552,1.4-10.747,12.669-21.565,25.28-32.245,38-1.582,1.884-2.717,2.026-4.774.755C94.321,33.43,82.114,26.084,69.944,18.678a24.334,24.334,0,0,0-7.3-3.41c-10.193-2.252-19.179,5.172-19.434,16.157q-.749,32.369-1.4,64.737c-.244,11.583-.621,23.167-.676,34.75a15.859,15.859,0,0,0,2.023,7.828l0-.006,0,.006a15.084,15.084,0,0,0,13.775,7.854c6.345-.261,11.226-2.873,14.451-8.324l34.532-58.48L116.96,59.822,109.977,80.96c8.53,4.954,21.341,12.117,27.2,15.632a13.4,13.4,0,0,0,11.813,1.489c6.707-2.394,10.721-7.18,11.729-14.178,1.013-7.03-2.543-11.9-8.53-15.333M65.84,99.433l8.466-25.622.046.1c-.052-2.853-.07-5.036-.009-5.6.543-5.07,3.5-6.713,8.135-4.661,6.586,2.917.9,8.536-5.935,16.416Z" transform="translate(78.265 0)" fill="#fff" />
                                <path id="Path_343" data-name="Path 343" d="M63.461,40.58a1.039,1.039,0,0,1,1.425-.369c.813.479,1.7,1,2.63,1.538L74.5,20.612Z" transform="translate(120.726 39.213)" fill="#dbdbdb" />
                                <path id="Path_344" data-name="Path 344" d="M58.286,35.5c-.046-2.032-.09-4.026-.122-5.77l.122-6.51L49.651,55.252l10.7-19.365c-.081.093-.16.183-.238.276a1.044,1.044,0,0,1-1.831-.659" transform="translate(94.455 44.182)" fill="#dbdbdb" />
                            </g>
                        </svg>
                    </div>
                    <div
                        className="col-10"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div className="Nav row d-none d-lg-flex" style={{ justifyContent: 'center' }}>

                            <div className="col-2 Navbar" onClick={() => HomeClick()}>Home</div>
                            <div className="col-2 Navbar" onClick={() => AboutClick()}>About us</div>
                            <div className="col-2 Navbar" onClick={() => ServicesClick()}>Services</div>
                            <div className="col-2 Navbar" onClick={() => PortfolioClick()}>Portfolio</div>
                            <div className="col-2 Navbar" onClick={() => ClientClick()}>Our clients</div>
                            <div className="col-2 Navbar" onClick={() => ContactClick()}>Contact us</div>
                        </div>

                    </div>
                </div>
                {/* Navbar end */}
                {/* Hamburger menu for mobile */}
                {hamburger &&
                    <div className="col hamburger">

                        <div className="row-12 Navbar1" onClick={() => HomeClick()}>Home</div>
                        <div className="row-12 Navbar1" onClick={() => AboutClick()}>About us</div>
                        <div className="row-12 Navbar1" onClick={() => ServicesClick()}>Services</div>
                        <div className="row-12 Navbar1" onClick={() => PortfolioClick()}>Portfolio</div>
                        <div className="row-12 Navbar1" onClick={() => ClientClick()}>Our clients</div>
                        <div className="row-12 Navbar1" onClick={() => ContactClick()}>Contact us</div>

                    </div>
                }
                {/* Hamburger end */}
                <div className='row' style={{ width: '100%' }}>
                    <div className="col-8" style={{
                        color: 'white',
                    }}>
                        <div className="col d-none d-xl-block pt-3 mt-2" style={{
                            fontWeight: '100',
                            fontSize: '70px',
                            lineHeight: '100px',
                        }}>

                            WE GO <span className='col-6' style={{ fontWeight: "900" }}>FURTHER,</span>
                            <br />
                            THAN THE CROWD

                            <div className="col" style={{
                                marginTop: '30px',
                                fontSize: '20px',
                                lineHeight: '25px',
                                fontWeight: '500'
                            }}>
                                Your Alpha agency for your business and creative solutions.<br />
                                Upscale your business the Saudi Market with effective digital services.
                            </div>
                        </div>
                        <div className="col d-none d-lg-block d-xl-none pt-3 mt-2" style={{
                            fontWeight: '100',
                            fontSize: '40px',
                            lineHeight: '70px',
                        }}>

                            WE GO <span className='col-6' style={{ fontWeight: "900" }}>FURTHER,</span>
                            <br />
                            THAN THE CROWD

                            <div className="col" style={{
                                marginTop: '30px',
                                fontSize: '15px',
                                lineHeight: '25px',
                                fontWeight: '500'
                            }}>
                                Your Alpha agency for your business and creative solutions.<br />
                                Upscale your business the Saudi Market with effective digital services.
                            </div>
                        </div>
                        <div className="col d-none d-md-block d-lg-none pt-3 mt-2" style={{
                            fontWeight: '100',
                            fontSize: '28px',
                            lineHeight: '70px',
                        }}>

                            WE GO <span className='col-6' style={{ fontWeight: "900" }}>FURTHER,</span>
                            <br />
                            THAN THE CROWD

                            <div className="col" style={{
                                marginTop: '30px',
                                fontSize: '12px',
                                lineHeight: '25px',
                                fontWeight: '500'
                            }}>
                                Your Alpha agency for your business and creative solutions.<br />
                                Upscale your business the Saudi Market with effective digital services.
                            </div>
                        </div>
                        <div className="col d-none d-sm-block d-md-none pt-3 mt-2" style={{
                            fontWeight: '100',
                            fontSize: '30px',
                            lineHeight: '40px',
                            width: '300px'
                        }}>

                            WE GO <span className='col-6' style={{ fontWeight: "900" }}>FURTHER,</span>
                            <br />
                            THAN THE CROWD

                            <div className="col" style={{
                                marginTop: '30px',
                                fontSize: '8px',
                                lineHeight: '25px',
                                fontWeight: '500'
                            }}>
                                Your Alpha agency for your business and creative solutions.<br />
                                Upscale your business the Saudi Market with effective digital services.
                            </div>
                        </div>
                        <div className="col d-block d-sm-none pt-3 mt-2" style={{
                            fontWeight: '100',
                            fontSize: '12px',
                            lineHeight: '20px',
                        }}>

                            WE GO <span className='col-6' style={{ fontWeight: "900" }}>FURTHER,</span>
                            <br />
                            THAN THE CROWD

                            <div className="col" style={{
                                marginTop: '0px',
                                fontSize: '3px',
                                lineHeight: '5px',
                                fontWeight: '500'
                            }}>
                                Your Alpha agency for your business and creative solutions.<br />
                                Upscale your business the Saudi Market with effective digital services.
                            </div>
                        </div>
                    </div>

                    <div className="col-4 d-none d-md-block">

                        <div className="row mt-5" style={{
                            width: 'auto',
                            backgroundColor: 'grey',
                            borderRadius: '60px 60px 60px 60px',
                            backgroundImage: `URL(${videoImage})`,
                            backgroundSize: 'cover',



                        }}
                        >
                            <svg style={{ width: '75px' }} width="64" height="64" viewBox="0 0 64 64">
                                <g id="Group_411" data-name="Group 411" transform="translate(-1294 -377)">
                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(1294 377)" fill="#fff" />
                                    <path id="Polygon_1" data-name="Polygon 1" d="M12.527,6.078a4,4,0,0,1,6.946,0L28.58,22.015A4,4,0,0,1,25.107,28H6.893A4,4,0,0,1,3.42,22.015Z" transform="translate(1344 393) rotate(90)" />
                                </g>
                            </svg>


                        </div>
                        <div className="row mt-5 pt-5" style={{

                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'nowrap'


                        }}>
                            <svg className='col-4' xmlns="http://www.w3.org/2000/svg" width="38.446" height="39.5" viewBox="0 0 38.446 39.5">
                                <path id="icon:5" d="M38.446,19.318l-11.5,2.037A6.989,6.989,0,0,0,21.265,27.4L19.643,39.5,17.661,27.681a6.971,6.971,0,0,0-5.887-5.832L0,20.182l11.5-2.037A6.989,6.989,0,0,0,17.18,12.1L18.8,0l1.982,11.819a6.971,6.971,0,0,0,5.887,5.832Z" fill="#fff" />
                            </svg>
                            <svg className='col-4' xmlns="http://www.w3.org/2000/svg" width="38.446" height="39.5" viewBox="0 0 38.446 39.5">
                                <path id="icon:5" d="M38.446,19.318l-11.5,2.037A6.989,6.989,0,0,0,21.265,27.4L19.643,39.5,17.661,27.681a6.971,6.971,0,0,0-5.887-5.832L0,20.182l11.5-2.037A6.989,6.989,0,0,0,17.18,12.1L18.8,0l1.982,11.819a6.971,6.971,0,0,0,5.887,5.832Z" fill="#fff" />
                            </svg>
                            <svg className='col-4 mt-3' xmlns="http://www.w3.org/2000/svg" width="38.446" height="39.5" viewBox="0 0 38.446 39.5">
                                <path id="icon:5" d="M38.446,19.318l-11.5,2.037A6.989,6.989,0,0,0,21.265,27.4L19.643,39.5,17.661,27.681a6.971,6.971,0,0,0-5.887-5.832L0,20.182l11.5-2.037A6.989,6.989,0,0,0,17.18,12.1L18.8,0l1.982,11.819a6.971,6.971,0,0,0,5.887,5.832Z" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                    <div className="col-4 d-block d-md-none">

                        <div className="row" style={{
                            width: '120px',
                            height: '20px',
                            backgroundColor: 'grey',
                            borderRadius: '30px 30px 30px 30px',
                            backgroundImage: `URL(${videoImage})`,
                            backgroundSize: 'cover',
                            marginTop: '2rem'



                        }}
                        >
                            <svg style={{ width: '65px', height: '20px' }} width="64" height="64" viewBox="0 0 64 64">
                                <g id="Group_411" data-name="Group 411" transform="translate(-1294 -377)">
                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(1294 377)" fill="#fff" />
                                    <path id="Polygon_1" data-name="Polygon 1" d="M12.527,6.078a4,4,0,0,1,6.946,0L28.58,22.015A4,4,0,0,1,25.107,28H6.893A4,4,0,0,1,3.42,22.015Z" transform="translate(1344 393) rotate(90)" />
                                </g>
                            </svg>


                        </div>
                        <div className="row" style={{
                            width: '120px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'nowrap'


                        }}>
                            <svg className='col-4' xmlns="http://www.w3.org/2000/svg" width="38.446" height="39.5" viewBox="0 0 38.446 39.5">
                                <path id="icon:5" d="M38.446,19.318l-11.5,2.037A6.989,6.989,0,0,0,21.265,27.4L19.643,39.5,17.661,27.681a6.971,6.971,0,0,0-5.887-5.832L0,20.182l11.5-2.037A6.989,6.989,0,0,0,17.18,12.1L18.8,0l1.982,11.819a6.971,6.971,0,0,0,5.887,5.832Z" fill="#fff" />
                            </svg>
                            <svg className='col-4' xmlns="http://www.w3.org/2000/svg" width="38.446" height="39.5" viewBox="0 0 38.446 39.5">
                                <path id="icon:5" d="M38.446,19.318l-11.5,2.037A6.989,6.989,0,0,0,21.265,27.4L19.643,39.5,17.661,27.681a6.971,6.971,0,0,0-5.887-5.832L0,20.182l11.5-2.037A6.989,6.989,0,0,0,17.18,12.1L18.8,0l1.982,11.819a6.971,6.971,0,0,0,5.887,5.832Z" fill="#fff" />
                            </svg>
                            <svg className='col-4 mt-2' xmlns="http://www.w3.org/2000/svg" width="38.446" height="39.5" viewBox="0 0 38.446 39.5">
                                <path id="icon:5" d="M38.446,19.318l-11.5,2.037A6.989,6.989,0,0,0,21.265,27.4L19.643,39.5,17.661,27.681a6.971,6.971,0,0,0-5.887-5.832L0,20.182l11.5-2.037A6.989,6.989,0,0,0,17.18,12.1L18.8,0l1.982,11.819a6.971,6.971,0,0,0,5.887,5.832Z" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                    <div className="col-4 d-none d-sm-none d-md-none">

                        <div className="row" style={{
                            width: '120px',
                            height: '20px',
                            backgroundColor: 'grey',
                            borderRadius: '30px 30px 30px 30px',
                            backgroundImage: `URL(${videoImage})`,
                            backgroundSize: 'cover',
                            marginTop: '4rem'



                        }}
                        >
                            <svg style={{ width: '65px', height: '20px' }} width="64" height="64" viewBox="0 0 64 64">
                                <g id="Group_411" data-name="Group 411" transform="translate(-1294 -377)">
                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="32" cy="32" r="32" transform="translate(1294 377)" fill="#fff" />
                                    <path id="Polygon_1" data-name="Polygon 1" d="M12.527,6.078a4,4,0,0,1,6.946,0L28.58,22.015A4,4,0,0,1,25.107,28H6.893A4,4,0,0,1,3.42,22.015Z" transform="translate(1344 393) rotate(90)" />
                                </g>
                            </svg>


                        </div>
                        <div className="row" style={{
                            width: '120px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'nowrap'


                        }}>
                            <svg className='col-4' xmlns="http://www.w3.org/2000/svg" width="38.446" height="39.5" viewBox="0 0 38.446 39.5">
                                <path id="icon:5" d="M38.446,19.318l-11.5,2.037A6.989,6.989,0,0,0,21.265,27.4L19.643,39.5,17.661,27.681a6.971,6.971,0,0,0-5.887-5.832L0,20.182l11.5-2.037A6.989,6.989,0,0,0,17.18,12.1L18.8,0l1.982,11.819a6.971,6.971,0,0,0,5.887,5.832Z" fill="#fff" />
                            </svg>
                            <svg className='col-4' xmlns="http://www.w3.org/2000/svg" width="38.446" height="39.5" viewBox="0 0 38.446 39.5">
                                <path id="icon:5" d="M38.446,19.318l-11.5,2.037A6.989,6.989,0,0,0,21.265,27.4L19.643,39.5,17.661,27.681a6.971,6.971,0,0,0-5.887-5.832L0,20.182l11.5-2.037A6.989,6.989,0,0,0,17.18,12.1L18.8,0l1.982,11.819a6.971,6.971,0,0,0,5.887,5.832Z" fill="#fff" />
                            </svg>
                            <svg className='col-4 mt-2' xmlns="http://www.w3.org/2000/svg" width="38.446" height="39.5" viewBox="0 0 38.446 39.5">
                                <path id="icon:5" d="M38.446,19.318l-11.5,2.037A6.989,6.989,0,0,0,21.265,27.4L19.643,39.5,17.661,27.681a6.971,6.971,0,0,0-5.887-5.832L0,20.182l11.5-2.037A6.989,6.989,0,0,0,17.18,12.1L18.8,0l1.982,11.819a6.971,6.971,0,0,0,5.887,5.832Z" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                </div>
                {/* Hello */}
                <div className='row d-none d-xl-flex'>

                    <div className='col-3 mt-5 pt-5' style={{
                        width: 'auto',

                    }}>
                        <svg width="227" height="48" viewBox="0 0 227 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-148 -834)">
                                <g id="Rectangle_175" data-name="Rectangle 175" transform="translate(148 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="227" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="226" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Adaptability_" data-name="Adaptability " transform="translate(171 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Adaptability</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-3 mt-5 pt-5' style={{
                        width: 'auto',

                    }}>
                        <svg width="226" height="48" viewBox="0 0 226 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-413 -834)">
                                <g id="Rectangle_176" data-name="Rectangle 176" transform="translate(413 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="226" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="225" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Leadership" transform="translate(448 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Leadership</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className="col-6 mt-5 pt-5" style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '15px'
                    }}>

                        <svg width="1066" height="6" viewBox="0 0 1066 6">
                            <g id="Group_412" data-name="Group 412" transform="translate(-746 -855)">
                                <circle id="Ellipse_7" data-name="Ellipse 7" cx="3" cy="3" r="3" transform="translate(1806 855)" fill="#fff" />
                                <circle id="Ellipse_8" data-name="Ellipse 8" cx="3" cy="3" r="3" transform="translate(746 855)" fill="#fff" />
                                <path id="Path_339" data-name="Path 339" d="M2761.14,756h1059.5" transform="translate(-2011.639 102)" fill="none" stroke="#fff" stroke-width="0.5" />
                            </g>
                        </svg>
                    </div>

                </div>
                <div className='row d-none d-lg-flex d-xl-none'>

                    <div className='col-3 mt-5 pt-5' style={{
                        width: '150px',

                    }}>
                        <svg style={{ width: '130px' }} width="227" height="48" viewBox="0 0 227 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-148 -834)">
                                <g id="Rectangle_175" data-name="Rectangle 175" transform="translate(148 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="227" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="226" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Adaptability_" data-name="Adaptability " transform="translate(171 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Adaptability</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-3 mt-5 pt-5' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '130px' }} width="226" height="48" viewBox="0 0 226 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-413 -834)">
                                <g id="Rectangle_176" data-name="Rectangle 176" transform="translate(413 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="226" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="225" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Leadership" transform="translate(448 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Leadership</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className="col-6 mt-5 pt-5" style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '0px'
                    }}>

                        <svg style={{ width: '800px' }} width="1066" height="6" viewBox="0 0 1066 6">
                            <g id="Group_412" data-name="Group 412" transform="translate(-746 -855)">
                                <circle id="Ellipse_7" data-name="Ellipse 7" cx="3" cy="3" r="3" transform="translate(1806 855)" fill="#fff" />
                                <circle id="Ellipse_8" data-name="Ellipse 8" cx="3" cy="3" r="3" transform="translate(746 855)" fill="#fff" />
                                <path id="Path_339" data-name="Path 339" d="M2761.14,756h1059.5" transform="translate(-2011.639 102)" fill="none" stroke="#fff" stroke-width="0.5" />
                            </g>
                        </svg>
                    </div>

                </div>
                <div className='row d-none d-md-flex d-lg-none'>

                    <div className='col-3 mt-5 pt-5' style={{
                        width: '130px',

                    }}>
                        <svg style={{ width: '130px' }} width="227" height="48" viewBox="0 0 227 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-148 -834)">
                                <g id="Rectangle_175" data-name="Rectangle 175" transform="translate(148 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="227" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="226" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Adaptability_" data-name="Adaptability " transform="translate(171 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Adaptability</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-3 mt-5 pt-5' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '130px' }} width="226" height="48" viewBox="0 0 226 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-413 -834)">
                                <g id="Rectangle_176" data-name="Rectangle 176" transform="translate(413 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="226" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="225" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Leadership" transform="translate(448 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Leadership</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className="col-6 mt-5 pt-5" style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '0px'
                    }}>

                        <svg style={{ width: '800px' }} width="1066" height="6" viewBox="0 0 1066 6">
                            <g id="Group_412" data-name="Group 412" transform="translate(-746 -855)">
                                <circle id="Ellipse_7" data-name="Ellipse 7" cx="3" cy="3" r="3" transform="translate(1806 855)" fill="#fff" />
                                <circle id="Ellipse_8" data-name="Ellipse 8" cx="3" cy="3" r="3" transform="translate(746 855)" fill="#fff" />
                                <path id="Path_339" data-name="Path 339" d="M2761.14,756h1059.5" transform="translate(-2011.639 102)" fill="none" stroke="#fff" stroke-width="0.5" />
                            </g>
                        </svg>
                    </div>

                </div>
                <div className='row d-none d-sm-flex d-md-none'>

                    <div className='col-1 mt-5 pt-5' style={{
                        width: '100px',

                    }}>
                        <svg style={{ width: '90px' }} width="227" height="48" viewBox="0 0 227 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-148 -834)">
                                <g id="Rectangle_175" data-name="Rectangle 175" transform="translate(148 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="227" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="226" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Adaptability_" data-name="Adaptability " transform="translate(171 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Adaptability</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-1 mt-5 pt-5' style={{
                        width: '120px',

                    }}>
                        <svg style={{ width: '90px' }} width="226" height="48" viewBox="0 0 226 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-413 -834)">
                                <g id="Rectangle_176" data-name="Rectangle 176" transform="translate(413 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="226" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="225" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Leadership" transform="translate(448 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Leadership</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className="col-10 mt-5 pt-5" style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '0px',
                        width: '200px'
                    }}>

                        <svg style={{ width: '250px' }} width="1066" height="6" viewBox="0 0 1066 6">
                            <g id="Group_412" data-name="Group 412" transform="translate(-746 -855)">
                                <circle id="Ellipse_7" data-name="Ellipse 7" cx="3" cy="3" r="3" transform="translate(1806 855)" fill="#fff" />
                                <circle id="Ellipse_8" data-name="Ellipse 8" cx="3" cy="3" r="3" transform="translate(746 855)" fill="#fff" />
                                <path id="Path_339" data-name="Path 339" d="M2761.14,756h1059.5" transform="translate(-2011.639 102)" fill="none" stroke="#fff" stroke-width="0.5" />
                            </g>
                        </svg>
                    </div>

                </div>
                <div className='row d-flex d-sm-none d-md-none'>

                    <div className='col-1' style={{
                        width: '60px',

                    }}>
                        <svg style={{ width: '50px' }} width="227" height="48" viewBox="0 0 227 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-148 -834)">
                                <g id="Rectangle_175" data-name="Rectangle 175" transform="translate(148 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="227" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="226" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Adaptability_" data-name="Adaptability " transform="translate(171 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Adaptability</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-1 ' style={{
                        width: '60px',

                    }}>
                        <svg style={{ width: '50px' }} width="226" height="48" viewBox="0 0 226 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-413 -834)">
                                <g id="Rectangle_176" data-name="Rectangle 176" transform="translate(413 834)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="226" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="225" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Leadership" transform="translate(448 869)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Leadership</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className="col-10" style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '0px',
                        width: '115px'
                    }}>

                        <svg style={{ width: '150px' }} width="1066" height="6" viewBox="0 0 1066 6">
                            <g id="Group_412" data-name="Group 412" transform="translate(-746 -855)">
                                <circle id="Ellipse_7" data-name="Ellipse 7" cx="3" cy="3" r="3" transform="translate(1806 855)" fill="#fff" />
                                <circle id="Ellipse_8" data-name="Ellipse 8" cx="3" cy="3" r="3" transform="translate(746 855)" fill="#fff" />
                                <path id="Path_339" data-name="Path 339" d="M2761.14,756h1059.5" transform="translate(-2011.639 102)" fill="none" stroke="#fff" stroke-width="0.5" />
                            </g>
                        </svg>
                    </div>

                </div>



                <div className='row d-none d-xl-flex' style={{ marginBottom: '100px' }}>

                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg width="257" height="48" viewBox="0 0 257 48">
                            <g id="Group_418" data-name="Group 418" transform="translate(-148 -908)">
                                <g id="Rectangle_202" data-name="Rectangle 202" transform="translate(148 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="257" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="256" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Persuasiveness" transform="translate(171 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Persuasiveness</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg width="229" height="48" viewBox="0 0 229 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-432 -908)">
                                <g id="Rectangle_203" data-name="Rectangle 203" transform="translate(432 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="229" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="228" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="High-Minded" transform="translate(456 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">High-Minded</tspan></text>
                            </g>
                        </svg>
                    </div>
                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg width="254" height="48" viewBox="0 0 254 48">
                            <g id="Group_419" data-name="Group 419" transform="translate(-696 -908)">
                                <g id="Rectangle_204" data-name="Rectangle 204" transform="translate(696 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="254" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="253" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Accountability" transform="translate(724 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Accountability</tspan></text>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className='row d-none d-lg-flex d-xl-none' style={{ marginBottom: '100px' }}>

                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '135px' }} width="257" height="48" viewBox="0 0 257 48">
                            <g id="Group_418" data-name="Group 418" transform="translate(-148 -908)">
                                <g id="Rectangle_202" data-name="Rectangle 202" transform="translate(148 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="257" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="256" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Persuasiveness" transform="translate(171 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Persuasiveness</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '130px' }} width="229" height="48" viewBox="0 0 229 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-432 -908)">
                                <g id="Rectangle_203" data-name="Rectangle 203" transform="translate(432 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="229" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="228" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="High-Minded" transform="translate(456 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">High-Minded</tspan></text>
                            </g>
                        </svg>
                    </div>
                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '135px' }} width="254" height="48" viewBox="0 0 254 48">
                            <g id="Group_419" data-name="Group 419" transform="translate(-696 -908)">
                                <g id="Rectangle_204" data-name="Rectangle 204" transform="translate(696 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="254" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="253" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Accountability" transform="translate(724 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Accountability</tspan></text>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className='row d-none d-md-flex d-lg-none' style={{ marginBottom: '100px' }}>

                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '155px' }} width="257" height="48" viewBox="0 0 257 48">
                            <g id="Group_418" data-name="Group 418" transform="translate(-148 -908)">
                                <g id="Rectangle_202" data-name="Rectangle 202" transform="translate(148 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="257" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="256" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Persuasiveness" transform="translate(171 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Persuasiveness</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '150px' }} width="229" height="48" viewBox="0 0 229 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-432 -908)">
                                <g id="Rectangle_203" data-name="Rectangle 203" transform="translate(432 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="229" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="228" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="High-Minded" transform="translate(456 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">High-Minded</tspan></text>
                            </g>
                        </svg>
                    </div>
                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '155px' }} width="254" height="48" viewBox="0 0 254 48">
                            <g id="Group_419" data-name="Group 419" transform="translate(-696 -908)">
                                <g id="Rectangle_204" data-name="Rectangle 204" transform="translate(696 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="254" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="253" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Accountability" transform="translate(724 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Accountability</tspan></text>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className='row d-none d-sm-flex d-md-none' style={{ marginBottom: '100px' }}>

                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '105px' }} width="257" height="48" viewBox="0 0 257 48">
                            <g id="Group_418" data-name="Group 418" transform="translate(-148 -908)">
                                <g id="Rectangle_202" data-name="Rectangle 202" transform="translate(148 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="257" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="256" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Persuasiveness" transform="translate(171 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Persuasiveness</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '100px' }} width="229" height="48" viewBox="0 0 229 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-432 -908)">
                                <g id="Rectangle_203" data-name="Rectangle 203" transform="translate(432 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="229" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="228" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="High-Minded" transform="translate(456 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">High-Minded</tspan></text>
                            </g>
                        </svg>
                    </div>
                    <div className='col-4 mt-3 pt-3' style={{
                        width: 'auto',

                    }}>
                        <svg style={{ width: '105px' }} width="254" height="48" viewBox="0 0 254 48">
                            <g id="Group_419" data-name="Group 419" transform="translate(-696 -908)">
                                <g id="Rectangle_204" data-name="Rectangle 204" transform="translate(696 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="254" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="253" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Accountability" transform="translate(724 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Accountability</tspan></text>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className='row d-flex d-sm-none' style={{ marginBottom: '100px' }}>

                    <div className='col-4' style={{
                        width: '65px',

                    }}>
                        <svg style={{ width: '55px' }} width="257" height="48" viewBox="0 0 257 48">
                            <g id="Group_418" data-name="Group 418" transform="translate(-148 -908)">
                                <g id="Rectangle_202" data-name="Rectangle 202" transform="translate(148 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="257" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="256" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Persuasiveness" transform="translate(171 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Persuasiveness</tspan></text>
                            </g>
                        </svg>

                    </div>
                    <div className='col-4' style={{
                        width: '60px',

                    }}>
                        <svg style={{ width: '50px' }} width="229" height="48" viewBox="0 0 229 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-432 -908)">
                                <g id="Rectangle_203" data-name="Rectangle 203" transform="translate(432 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="229" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="228" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="High-Minded" transform="translate(456 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">High-Minded</tspan></text>
                            </g>
                        </svg>
                    </div>
                    <div className='col-4' style={{
                        width: '60px',

                    }}>
                        <svg style={{ width: '55px' }} width="254" height="48" viewBox="0 0 254 48">
                            <g id="Group_419" data-name="Group 419" transform="translate(-696 -908)">
                                <g id="Rectangle_204" data-name="Rectangle 204" transform="translate(696 908)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="254" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="253" height="47" rx="22.5" fill="none" />
                                </g>
                                <text id="Accountability" transform="translate(724 943)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Accountability</tspan></text>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="row d-none d-lg-flex" style={{
                height: "2500px",
                backgroundImage: `url(${Image1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: "200px",



            }}>
                <div className='col' style={{

                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginTop: '-70px'


                }}>
                    <svg width="250" height="250" viewBox="0 0 364 364">
                        <g id="Group_411" data-name="Group 411" transform="translate(-1299 -956)">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="182" cy="182" r="182" transform="translate(1299 956)" />
                            <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M20.907,23.294H99.442L5.293,117.443l12.934,12.934,94.149-94.149v78.535h18.294V5H20.907Z" transform="translate(1413.019 1070.312)" fill="#fff" />
                        </g>
                    </svg>
                </div>
                <div className='Container col'>
                    <div className="Animation">
                        <div className='MovingText'>
                            CREATIVITY&nbsp;<span style={{ display: 'flex', margin: '0px 300px 0px 300px' }}><svg id="_5" data-name="5" width="80.207" height="82.407" viewBox="0 0 80.207 82.407">
                                <g id="row1">
                                    <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                                </g>
                            </svg>
                            </span>&nbsp;CHALLENGE
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <svg onClick={() => ServicesClick()} width="331" height="96" viewBox="0 0 331 96">
                        <g id="Group_412" data-name="Group 412" transform="translate(-793.889 -2418)">
                            <g id="Rectangle_179" data-name="Rectangle 179" transform="translate(793.889 2418)" fill="#fff" stroke="#fff" stroke-width="1">
                                <rect width="331" height="96" rx="48" stroke="none" />
                                <rect x="0.5" y="0.5" width="330" height="95" rx="47.5" fill="none" />
                            </g>
                            <text id="Read_more" data-name="Read more" transform="translate(837.889 2482)" font-size="45" font-family="LexendDeca-Medium, Lexend Deca" font-weight="500"><tspan x="0" y="0">Read more</tspan></text>
                        </g>
                    </svg>
                </div>


                {/* Services */}
                <div className="row" style={{

                }}>

                    <div className="row" style={{
                        color: 'white',
                        fontSize: '120px',
                        fontFamily: 'Lexend Deca',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end'
                    }}>SERVICES</div>
                    <div className="row" style={{
                        display: 'flex',
                        alignContent: 'flex-start',
                        justifyContent: 'center',
                        padding: '100px 100px 100px 100px'

                    }}>

                        <svg viewBox="0 0 1502 917">
                            <g id="Group_383" data-name="Group 383" transform="translate(-40 -1113.805)">
                                <Link to="/services#ad"><rect id="Rectangle_165" data-name="Rectangle 165" width="347" height="597" rx="45" transform="translate(40 1433.805)" fill="#fff" opacity="0.832" /></Link>
                                <rect id="Rectangle_166" data-name="Rectangle 166" width="347" height="285" rx="45" transform="translate(40 1113.805)" fill="#8553a5" opacity="0.8" />
                                <Link to="/services#visual"><rect id="Rectangle_168" data-name="Rectangle 168" width="347" height="748" rx="45" transform="translate(426 1113.805)" fill="#602f7f" opacity="0.822" /></Link>
                                <rect id="Rectangle_169" data-name="Rectangle 169" width="348" height="126" rx="45" transform="translate(423 1904.805)" fill="#fff" opacity="0.804" />
                                <Link to="/services#video"><rect id="Rectangle_170" data-name="Rectangle 170" width="731" height="282" rx="45" transform="translate(811 1748.805)" fill="#602f7f" opacity="0.861" /></Link>
                                <Link to="/services#dev"><rect id="Rectangle_171" data-name="Rectangle 171" width="347" height="436" rx="45" transform="translate(1195 1272.805)" fill="#fff" opacity="0.859" /> </Link>
                                <rect id="Rectangle_172" data-name="Rectangle 172" width="347" height="127" rx="45" transform="translate(1195 1113.805)" fill="#8553a5" opacity="0.895" />
                                <Link to="/services#social"><rect id="Rectangle_167" data-name="Rectangle 167" width="347" height="595" rx="45" transform="translate(811 1113.805)" fill="#fff" opacity="0.867" /></Link>
                                <g id="u" transform="translate(1271.801 1402.793)">
                                    <g id="Group_384" data-name="Group 384" transform="translate(2.25 3.25)">
                                        <path id="Path_320" data-name="Path 320" d="M145.134,3.25H48.264A46.055,46.055,0,0,0,2.25,49.264v77.5a46.055,46.055,0,0,0,46.014,46.014h96.871a46.055,46.055,0,0,0,46.014-46.014v-77.5A46.055,46.055,0,0,0,145.134,3.25ZM48.264,17.781h96.871A31.455,31.455,0,0,1,175.649,42H17.749A31.455,31.455,0,0,1,48.264,17.781Zm96.871,140.463H48.264A31.544,31.544,0,0,1,16.781,126.76V56.529H176.617V126.76A31.544,31.544,0,0,1,145.134,158.243Z" transform="translate(-2.25 -3.25)" fill="#231f20" />
                                        <path id="Path_321" data-name="Path 321" d="M38,13.359a7.309,7.309,0,0,0-10.268,0L8.359,32.734A7.309,7.309,0,0,0,8.359,43L27.734,62.376a7.25,7.25,0,0,0,10.268,0,7.309,7.309,0,0,0,0-10.268l-14.24-14.24L38,23.628a7.309,7.309,0,0,0,0-10.268Z" transform="translate(32.52 66.268)" fill="#231f20" />
                                        <path id="Path_322" data-name="Path 322" d="M26.628,13.359A7.261,7.261,0,0,0,16.359,23.628L30.6,37.868l-14.24,14.24a7.309,7.309,0,0,0,0,10.268,7.25,7.25,0,0,0,10.268,0L46,43a7.309,7.309,0,0,0,0-10.268L26.628,13.359Z" transform="translate(102.017 66.268)" fill="#231f20" />
                                    </g>
                                </g>
                                <g id="Megaphone" transform="translate(107.806 1612.944)">
                                    <path id="Path_314" data-name="Path 314" d="M76.2,89.572H41.786A41.786,41.786,0,1,1,41.786,6H76.2a7.374,7.374,0,0,1,7.374,7.374V82.2A7.374,7.374,0,0,1,76.2,89.572ZM41.786,20.748a27.038,27.038,0,0,0,0,54.076H68.824V20.748Z" transform="translate(0 23.495)" />
                                    <path id="Path_315" data-name="Path 315" d="M124.612,142.562a7.511,7.511,0,0,1-2.025-.28l-103.236-29.5A7.375,7.375,0,0,1,23.4,98.6l93.836,26.807V17.151L23.4,43.962A7.375,7.375,0,0,1,19.351,29.78L122.586.284a7.374,7.374,0,0,1,9.4,7.089V135.188A7.374,7.374,0,0,1,124.612,142.562Z" transform="translate(54.822 0)" />
                                    <path id="Path_316" data-name="Path 316" d="M42.374,71.908a7.374,7.374,0,0,1,0-14.748,17.206,17.206,0,1,0,0-34.412A7.374,7.374,0,0,1,42.374,8a31.954,31.954,0,1,1,0,63.908Z" transform="translate(137.059 31.327)" />
                                    <path id="Path_317" data-name="Path 317" d="M32.264,27.748H18.593a7.374,7.374,0,1,1,0-14.748H32.264a7.374,7.374,0,0,1,0,14.748Z" transform="translate(43.933 50.907)" />
                                    <path id="Path_318" data-name="Path 318" d="M14.043,27.748a7.482,7.482,0,0,1-5.26-2.163A9.971,9.971,0,0,1,7.9,24.454,7.929,7.929,0,0,1,6.767,21.8a6.966,6.966,0,0,1,0-2.851A7.929,7.929,0,0,1,7.9,16.294a9.971,9.971,0,0,1,.885-1.131,10.373,10.373,0,0,1,1.131-.934,10.561,10.561,0,0,1,1.278-.688c.442-.147.934-.295,1.376-.393a7.2,7.2,0,0,1,2.9,0c.442.1.934.246,1.376.393a10.559,10.559,0,0,1,1.278.688,10.372,10.372,0,0,1,1.131.934,9.969,9.969,0,0,1,.885,1.131,7.929,7.929,0,0,1,1.131,2.655,6.966,6.966,0,0,1,0,2.851,7.929,7.929,0,0,1-1.131,2.655,9.97,9.97,0,0,1-.885,1.131,10.373,10.373,0,0,1-1.131.934,10.559,10.559,0,0,1-1.278.688c-.442.147-.934.295-1.376.393a7.373,7.373,0,0,1-1.426.147Z" transform="translate(25.924 50.907)" />
                                    <path id="Path_319" data-name="Path 319" d="M73.342,113.274H29.1a7.374,7.374,0,0,1-7.246-6.017L7.1,28.6A7.374,7.374,0,0,1,21.6,25.888L35.219,98.526H64.346L51.363,33.606a7.374,7.374,0,0,1,14.463-2.891l14.748,73.74a7.374,7.374,0,0,1-7.231,8.819Z" transform="translate(27.435 78.448)" />
                                </g>
                                <path id="clapperboard" d="M123.511,4.011a21.685,21.685,0,0,0-6.541.836L20.92,32.48A22.13,22.13,0,0,0,5.846,59.729L9,70.74v71.285A22.1,22.1,0,0,0,31,164.032H135.03a22.1,22.1,0,0,0,22.006-22.006V74.007a6,6,0,0,0-6-6H59.689l85-24.452a6,6,0,0,0,4.111-7.416l-4.618-16.176A22.121,22.121,0,0,0,125.652,4.167C124.944,4.079,124.231,4.029,123.511,4.011Zm4.665,13.566a9.885,9.885,0,0,1,4.462,5.681s0,.005,0,.008l2.977,10.409-19.24,5.533Zm-13.973.555L100.161,43.874l-21.8,6.275L92.393,24.407ZM76.186,29.073,62.143,54.814l-21.8,6.267L54.375,35.348ZM38.16,40.013,24.117,65.747l-3.759,1.078-2.97-10.394a9.96,9.96,0,0,1,6.853-12.417ZM21,80.009H145.033v62.017a9.914,9.914,0,0,1-10,10H31a9.914,9.914,0,0,1-10-10Z" transform="translate(1076.981 1805.787)" fill="#fff" />
                                <path id="Polygon_2" data-name="Polygon 2" d="M14.514,6.2a4,4,0,0,1,6.973,0L32.647,26.039A4,4,0,0,1,29.161,32H6.839a4,4,0,0,1-3.486-5.961Z" transform="translate(1181 1904.805) rotate(90)" fill="#fff" />
                                <g id="_027-finger_scanner" data-name="027-finger scanner" transform="translate(453.922 1367.861)">
                                    <path id="Path_324" data-name="Path 324" d="M323.077,225a4.057,4.057,0,0,0-4.057,4.057V264.35a69.043,69.043,0,0,1-68.965,68.965,4.057,4.057,0,1,0,0,8.113,77.166,77.166,0,0,0,77.078-77.078V229.056A4.057,4.057,0,0,0,323.077,225Z" transform="translate(-106.978 -133.722)" fill="#fff" />
                                    <path id="Path_325" data-name="Path 325" d="M294.036,13.7a69.017,69.017,0,0,1,51.728,66.774,4.057,4.057,0,0,0,8.114,0A76.182,76.182,0,0,0,337.41,32.961,77.756,77.756,0,0,0,296.073,5.847a4.057,4.057,0,0,0-2.037,7.853Z" transform="translate(-133.721 -3.397)" fill="#fff" />
                                    <path id="Path_326" data-name="Path 326" d="M125.841,382.831a69.017,69.017,0,0,1-51.728-66.774,4.057,4.057,0,0,0-8.114,0,76.182,76.182,0,0,0,16.467,47.514A77.755,77.755,0,0,0,123.8,390.685a4.057,4.057,0,1,0,2.036-7.853Z" transform="translate(0 -185.429)" fill="#fff" />
                                    <path id="Path_327" data-name="Path 327" d="M70.057,116.429a4.057,4.057,0,0,0,4.057-4.057V77.078A69.043,69.043,0,0,1,143.078,8.114a4.057,4.057,0,0,0,0-8.114A77.165,77.165,0,0,0,66,77.078v35.294A4.057,4.057,0,0,0,70.057,116.429Z" transform="translate(0)" fill="#fff" />
                                    <path id="Path_328" data-name="Path 328" d="M169.822,45A58.89,58.89,0,0,0,111,103.822v53.55a58.823,58.823,0,0,0,117.646,0v-53.55A58.889,58.889,0,0,0,169.822,45Zm50.71,112.373a50.71,50.71,0,0,1-101.419,0v-53.55a50.71,50.71,0,1,1,101.419,0Z" transform="translate(-26.744 -26.744)" fill="#fff" />
                                    <path id="Path_329" data-name="Path 329" d="M180.355,305.786a32.658,32.658,0,0,1-16.243-28.063V251.557a4.057,4.057,0,1,0-8.114,0v26.166a40.8,40.8,0,0,0,20.269,35.072,4.057,4.057,0,0,0,4.088-7.009Z" transform="translate(-53.489 -147.095)" fill="#fff" />
                                    <path id="Path_330" data-name="Path 330" d="M282.511,184.056v53.55a32.491,32.491,0,0,1-32.454,32.454,4.057,4.057,0,1,0,0,8.114,40.72,40.72,0,0,0,40.568-40.568v-53.55a4.057,4.057,0,0,0-8.114,0Z" transform="translate(-106.978 -106.978)" fill="#fff" />
                                    <path id="Path_331" data-name="Path 331" d="M169.184,143.751a13.2,13.2,0,0,0,13.184-13.185,14.2,14.2,0,0,1,28.4,0v27.586a4.057,4.057,0,1,0,8.114,0V130.567a22.312,22.312,0,0,0-44.625,0,5.071,5.071,0,0,1-10.142,0,32.432,32.432,0,0,1,60.533-16.215,4.057,4.057,0,0,0,7.013-4.081A40.546,40.546,0,0,0,156,130.567a13.211,13.211,0,0,0,13.184,13.185Z" transform="translate(-53.489 -53.489)" fill="#fff" />
                                    <path id="Path_332" data-name="Path 332" d="M268.313,293a4.057,4.057,0,0,0-4.057,4.057v7.708a14.215,14.215,0,0,1-14.2,14.2,4.057,4.057,0,0,0,0,8.114,22.337,22.337,0,0,0,22.312-22.312v-7.708A4.057,4.057,0,0,0,268.313,293Z" transform="translate(-106.979 -174.137)" fill="#fff" />
                                    <path id="Path_333" data-name="Path 333" d="M227.369,228.511V184.055a4.057,4.057,0,1,0-8.114,0v44.456a5.071,5.071,0,0,1-10.142,0V211.439a4.057,4.057,0,1,0-8.114,0v17.072a13.184,13.184,0,0,0,26.369,0Z" transform="translate(-80.234 -106.977)" fill="#fff" />
                                </g>
                                <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M121.865,72.338V32.716A29.716,29.716,0,0,0,92.149,3L52.527,92.149v108.96H164.261a19.811,19.811,0,0,0,19.811-16.839l13.67-89.149A19.811,19.811,0,0,0,177.93,72.338ZM52.527,201.109H22.811A19.811,19.811,0,0,1,3,181.3V111.96A19.811,19.811,0,0,1,22.811,92.149H52.527" transform="translate(884.017 1294.603)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="15" />
                            </g>
                        </svg>
                    </div>

                </div>

            </div>
            <div className="row d-none d-md-flex d-lg-none" style={{
                height: "2500px",
                backgroundPosition: 'center',
                backgroundImage: `url(${Image1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: "150px",




            }}>
                <div className='col' style={{

                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginTop: '-70px'


                }}>
                    <svg style={{ width: "200px" }} width="250" height="250" viewBox="0 0 364 364">
                        <g id="Group_411" data-name="Group 411" transform="translate(-1299 -956)">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="182" cy="182" r="182" transform="translate(1299 956)" />
                            <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M20.907,23.294H99.442L5.293,117.443l12.934,12.934,94.149-94.149v78.535h18.294V5H20.907Z" transform="translate(1413.019 1070.312)" fill="#fff" />
                        </g>
                    </svg>
                </div>
                <div className='Container col'>
                    <div className="Animation">
                        <div className='MovingText'>
                            CREATIVITY&nbsp;<span style={{ display: 'flex', margin: '0px 300px 0px 300px' }}><svg id="_5" data-name="5" width="80.207" height="82.407" viewBox="0 0 80.207 82.407">
                                <g id="row1">
                                    <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                                </g>
                            </svg>
                            </span>&nbsp;CHALLENGE
                        </div>
                    </div>
                </div>
                <div className="row">
                    <svg onClick={() => ServicesClick()} width="331" height="96" viewBox="0 0 331 96">
                        <g id="Group_412" data-name="Group 412" transform="translate(-793.889 -2418)">
                            <g id="Rectangle_179" data-name="Rectangle 179" transform="translate(793.889 2418)" fill="#fff" stroke="#fff" stroke-width="1">
                                <rect width="331" height="96" rx="48" stroke="none" />
                                <rect x="0.5" y="0.5" width="330" height="95" rx="47.5" fill="none" />
                            </g>
                            <text id="Read_more" data-name="Read more" transform="translate(837.889 2482)" font-size="45" font-family="LexendDeca-Medium, Lexend Deca" font-weight="500"><tspan x="0" y="0">Read more</tspan></text>
                        </g>
                    </svg>
                </div>


                {/* Services */}
                <div className="row" style={{

                }}>

                    <div className="row" style={{
                        color: 'white',
                        fontSize: '120px',
                        fontFamily: 'Lexend Deca',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end'
                    }}>SERVICES</div>
                    <div className="row" style={{
                        display: 'flex',
                        alignContent: 'flex-start',
                        justifyContent: 'center',
                        padding: '0px'

                    }}>

                        <svg viewBox="0 0 1502 917">
                            <g id="Group_383" data-name="Group 383" transform="translate(-40 -1113.805)">
                                <Link to="/services#ad">
                                    <rect id="Rectangle_165" data-name="Rectangle 165" width="347" height="597" rx="45" transform="translate(40 1433.805)" fill="#fff" opacity="0.832" />
                                </Link>
                                <rect id="Rectangle_166" data-name="Rectangle 166" width="347" height="285" rx="45" transform="translate(40 1113.805)" fill="#8553a5" opacity="0.8" />
                                <Link to="/services#visual">
                                    <rect id="Rectangle_168" data-name="Rectangle 168" width="347" height="748" rx="45" transform="translate(426 1113.805)" fill="#602f7f" opacity="0.822" />
                                </Link>
                                <rect id="Rectangle_169" data-name="Rectangle 169" width="348" height="126" rx="45" transform="translate(423 1904.805)" fill="#fff" opacity="0.804" />
                                <Link to="/services#video"><rect id="Rectangle_170" data-name="Rectangle 170" width="731" height="282" rx="45" transform="translate(811 1748.805)" fill="#602f7f" opacity="0.861" /></Link>
                                <Link to="/services#dev">
                                    <rect id="Rectangle_171" data-name="Rectangle 171" width="347" height="436" rx="45" transform="translate(1195 1272.805)" fill="#fff" opacity="0.859" />
                                </Link>                                <rect id="Rectangle_172" data-name="Rectangle 172" width="347" height="127" rx="45" transform="translate(1195 1113.805)" fill="#8553a5" opacity="0.895" />
                                <Link to="/services#social"><rect id="Rectangle_167" data-name="Rectangle 167" width="347" height="595" rx="45" transform="translate(811 1113.805)" fill="#fff" opacity="0.867" /></Link>
                                <g id="u" transform="translate(1271.801 1402.793)">
                                    <g id="Group_384" data-name="Group 384" transform="translate(2.25 3.25)">
                                        <path id="Path_320" data-name="Path 320" d="M145.134,3.25H48.264A46.055,46.055,0,0,0,2.25,49.264v77.5a46.055,46.055,0,0,0,46.014,46.014h96.871a46.055,46.055,0,0,0,46.014-46.014v-77.5A46.055,46.055,0,0,0,145.134,3.25ZM48.264,17.781h96.871A31.455,31.455,0,0,1,175.649,42H17.749A31.455,31.455,0,0,1,48.264,17.781Zm96.871,140.463H48.264A31.544,31.544,0,0,1,16.781,126.76V56.529H176.617V126.76A31.544,31.544,0,0,1,145.134,158.243Z" transform="translate(-2.25 -3.25)" fill="#231f20" />
                                        <path id="Path_321" data-name="Path 321" d="M38,13.359a7.309,7.309,0,0,0-10.268,0L8.359,32.734A7.309,7.309,0,0,0,8.359,43L27.734,62.376a7.25,7.25,0,0,0,10.268,0,7.309,7.309,0,0,0,0-10.268l-14.24-14.24L38,23.628a7.309,7.309,0,0,0,0-10.268Z" transform="translate(32.52 66.268)" fill="#231f20" />
                                        <path id="Path_322" data-name="Path 322" d="M26.628,13.359A7.261,7.261,0,0,0,16.359,23.628L30.6,37.868l-14.24,14.24a7.309,7.309,0,0,0,0,10.268,7.25,7.25,0,0,0,10.268,0L46,43a7.309,7.309,0,0,0,0-10.268L26.628,13.359Z" transform="translate(102.017 66.268)" fill="#231f20" />
                                    </g>
                                </g>
                                <g id="Megaphone" transform="translate(107.806 1612.944)">
                                    <path id="Path_314" data-name="Path 314" d="M76.2,89.572H41.786A41.786,41.786,0,1,1,41.786,6H76.2a7.374,7.374,0,0,1,7.374,7.374V82.2A7.374,7.374,0,0,1,76.2,89.572ZM41.786,20.748a27.038,27.038,0,0,0,0,54.076H68.824V20.748Z" transform="translate(0 23.495)" />
                                    <path id="Path_315" data-name="Path 315" d="M124.612,142.562a7.511,7.511,0,0,1-2.025-.28l-103.236-29.5A7.375,7.375,0,0,1,23.4,98.6l93.836,26.807V17.151L23.4,43.962A7.375,7.375,0,0,1,19.351,29.78L122.586.284a7.374,7.374,0,0,1,9.4,7.089V135.188A7.374,7.374,0,0,1,124.612,142.562Z" transform="translate(54.822 0)" />
                                    <path id="Path_316" data-name="Path 316" d="M42.374,71.908a7.374,7.374,0,0,1,0-14.748,17.206,17.206,0,1,0,0-34.412A7.374,7.374,0,0,1,42.374,8a31.954,31.954,0,1,1,0,63.908Z" transform="translate(137.059 31.327)" />
                                    <path id="Path_317" data-name="Path 317" d="M32.264,27.748H18.593a7.374,7.374,0,1,1,0-14.748H32.264a7.374,7.374,0,0,1,0,14.748Z" transform="translate(43.933 50.907)" />
                                    <path id="Path_318" data-name="Path 318" d="M14.043,27.748a7.482,7.482,0,0,1-5.26-2.163A9.971,9.971,0,0,1,7.9,24.454,7.929,7.929,0,0,1,6.767,21.8a6.966,6.966,0,0,1,0-2.851A7.929,7.929,0,0,1,7.9,16.294a9.971,9.971,0,0,1,.885-1.131,10.373,10.373,0,0,1,1.131-.934,10.561,10.561,0,0,1,1.278-.688c.442-.147.934-.295,1.376-.393a7.2,7.2,0,0,1,2.9,0c.442.1.934.246,1.376.393a10.559,10.559,0,0,1,1.278.688,10.372,10.372,0,0,1,1.131.934,9.969,9.969,0,0,1,.885,1.131,7.929,7.929,0,0,1,1.131,2.655,6.966,6.966,0,0,1,0,2.851,7.929,7.929,0,0,1-1.131,2.655,9.97,9.97,0,0,1-.885,1.131,10.373,10.373,0,0,1-1.131.934,10.559,10.559,0,0,1-1.278.688c-.442.147-.934.295-1.376.393a7.373,7.373,0,0,1-1.426.147Z" transform="translate(25.924 50.907)" />
                                    <path id="Path_319" data-name="Path 319" d="M73.342,113.274H29.1a7.374,7.374,0,0,1-7.246-6.017L7.1,28.6A7.374,7.374,0,0,1,21.6,25.888L35.219,98.526H64.346L51.363,33.606a7.374,7.374,0,0,1,14.463-2.891l14.748,73.74a7.374,7.374,0,0,1-7.231,8.819Z" transform="translate(27.435 78.448)" />
                                </g>
                                <path id="clapperboard" d="M123.511,4.011a21.685,21.685,0,0,0-6.541.836L20.92,32.48A22.13,22.13,0,0,0,5.846,59.729L9,70.74v71.285A22.1,22.1,0,0,0,31,164.032H135.03a22.1,22.1,0,0,0,22.006-22.006V74.007a6,6,0,0,0-6-6H59.689l85-24.452a6,6,0,0,0,4.111-7.416l-4.618-16.176A22.121,22.121,0,0,0,125.652,4.167C124.944,4.079,124.231,4.029,123.511,4.011Zm4.665,13.566a9.885,9.885,0,0,1,4.462,5.681s0,.005,0,.008l2.977,10.409-19.24,5.533Zm-13.973.555L100.161,43.874l-21.8,6.275L92.393,24.407ZM76.186,29.073,62.143,54.814l-21.8,6.267L54.375,35.348ZM38.16,40.013,24.117,65.747l-3.759,1.078-2.97-10.394a9.96,9.96,0,0,1,6.853-12.417ZM21,80.009H145.033v62.017a9.914,9.914,0,0,1-10,10H31a9.914,9.914,0,0,1-10-10Z" transform="translate(1076.981 1805.787)" fill="#fff" />
                                <path id="Polygon_2" data-name="Polygon 2" d="M14.514,6.2a4,4,0,0,1,6.973,0L32.647,26.039A4,4,0,0,1,29.161,32H6.839a4,4,0,0,1-3.486-5.961Z" transform="translate(1181 1904.805) rotate(90)" fill="#fff" />
                                <g id="_027-finger_scanner" data-name="027-finger scanner" transform="translate(453.922 1367.861)">
                                    <path id="Path_324" data-name="Path 324" d="M323.077,225a4.057,4.057,0,0,0-4.057,4.057V264.35a69.043,69.043,0,0,1-68.965,68.965,4.057,4.057,0,1,0,0,8.113,77.166,77.166,0,0,0,77.078-77.078V229.056A4.057,4.057,0,0,0,323.077,225Z" transform="translate(-106.978 -133.722)" fill="#fff" />
                                    <path id="Path_325" data-name="Path 325" d="M294.036,13.7a69.017,69.017,0,0,1,51.728,66.774,4.057,4.057,0,0,0,8.114,0A76.182,76.182,0,0,0,337.41,32.961,77.756,77.756,0,0,0,296.073,5.847a4.057,4.057,0,0,0-2.037,7.853Z" transform="translate(-133.721 -3.397)" fill="#fff" />
                                    <path id="Path_326" data-name="Path 326" d="M125.841,382.831a69.017,69.017,0,0,1-51.728-66.774,4.057,4.057,0,0,0-8.114,0,76.182,76.182,0,0,0,16.467,47.514A77.755,77.755,0,0,0,123.8,390.685a4.057,4.057,0,1,0,2.036-7.853Z" transform="translate(0 -185.429)" fill="#fff" />
                                    <path id="Path_327" data-name="Path 327" d="M70.057,116.429a4.057,4.057,0,0,0,4.057-4.057V77.078A69.043,69.043,0,0,1,143.078,8.114a4.057,4.057,0,0,0,0-8.114A77.165,77.165,0,0,0,66,77.078v35.294A4.057,4.057,0,0,0,70.057,116.429Z" transform="translate(0)" fill="#fff" />
                                    <path id="Path_328" data-name="Path 328" d="M169.822,45A58.89,58.89,0,0,0,111,103.822v53.55a58.823,58.823,0,0,0,117.646,0v-53.55A58.889,58.889,0,0,0,169.822,45Zm50.71,112.373a50.71,50.71,0,0,1-101.419,0v-53.55a50.71,50.71,0,1,1,101.419,0Z" transform="translate(-26.744 -26.744)" fill="#fff" />
                                    <path id="Path_329" data-name="Path 329" d="M180.355,305.786a32.658,32.658,0,0,1-16.243-28.063V251.557a4.057,4.057,0,1,0-8.114,0v26.166a40.8,40.8,0,0,0,20.269,35.072,4.057,4.057,0,0,0,4.088-7.009Z" transform="translate(-53.489 -147.095)" fill="#fff" />
                                    <path id="Path_330" data-name="Path 330" d="M282.511,184.056v53.55a32.491,32.491,0,0,1-32.454,32.454,4.057,4.057,0,1,0,0,8.114,40.72,40.72,0,0,0,40.568-40.568v-53.55a4.057,4.057,0,0,0-8.114,0Z" transform="translate(-106.978 -106.978)" fill="#fff" />
                                    <path id="Path_331" data-name="Path 331" d="M169.184,143.751a13.2,13.2,0,0,0,13.184-13.185,14.2,14.2,0,0,1,28.4,0v27.586a4.057,4.057,0,1,0,8.114,0V130.567a22.312,22.312,0,0,0-44.625,0,5.071,5.071,0,0,1-10.142,0,32.432,32.432,0,0,1,60.533-16.215,4.057,4.057,0,0,0,7.013-4.081A40.546,40.546,0,0,0,156,130.567a13.211,13.211,0,0,0,13.184,13.185Z" transform="translate(-53.489 -53.489)" fill="#fff" />
                                    <path id="Path_332" data-name="Path 332" d="M268.313,293a4.057,4.057,0,0,0-4.057,4.057v7.708a14.215,14.215,0,0,1-14.2,14.2,4.057,4.057,0,0,0,0,8.114,22.337,22.337,0,0,0,22.312-22.312v-7.708A4.057,4.057,0,0,0,268.313,293Z" transform="translate(-106.979 -174.137)" fill="#fff" />
                                    <path id="Path_333" data-name="Path 333" d="M227.369,228.511V184.055a4.057,4.057,0,1,0-8.114,0v44.456a5.071,5.071,0,0,1-10.142,0V211.439a4.057,4.057,0,1,0-8.114,0v17.072a13.184,13.184,0,0,0,26.369,0Z" transform="translate(-80.234 -106.977)" fill="#fff" />
                                </g>
                                <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M121.865,72.338V32.716A29.716,29.716,0,0,0,92.149,3L52.527,92.149v108.96H164.261a19.811,19.811,0,0,0,19.811-16.839l13.67-89.149A19.811,19.811,0,0,0,177.93,72.338ZM52.527,201.109H22.811A19.811,19.811,0,0,1,3,181.3V111.96A19.811,19.811,0,0,1,22.811,92.149H52.527" transform="translate(884.017 1294.603)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="15" />
                            </g>
                        </svg>
                    </div>

                </div>

            </div>
            <div className="row d-none d-sm-flex d-md-none" style={{
                height: "1500px",
                backgroundPosition: 'center',
                backgroundImage: `url(${Image1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: "100px",




            }}>
                <div className='col' style={{

                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginTop: '-70px'


                }}>
                    <svg style={{ width: "150px" }} width="250" height="250" viewBox="0 0 364 364">
                        <g id="Group_411" data-name="Group 411" transform="translate(-1299 -956)">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="182" cy="182" r="182" transform="translate(1299 956)" />
                            <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M20.907,23.294H99.442L5.293,117.443l12.934,12.934,94.149-94.149v78.535h18.294V5H20.907Z" transform="translate(1413.019 1070.312)" fill="#fff" />
                        </g>
                    </svg>
                </div>
                <div className='Container col'>
                    <div className="Animation">
                        <div className='MovingText'>
                            CREATIVITY&nbsp;<span style={{ display: 'flex', margin: '0px 300px 0px 300px' }}><svg id="_5" data-name="5" width="80.207" height="82.407" viewBox="0 0 80.207 82.407">
                                <g id="row1">
                                    <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                                </g>
                            </svg>
                            </span>&nbsp;CHALLENGE
                        </div>
                    </div>
                </div>
                <div className="row">
                    <svg onClick={() => ServicesClick()} width="331" height="96" viewBox="0 0 331 96">
                        <g id="Group_412" data-name="Group 412" transform="translate(-793.889 -2418)">
                            <g id="Rectangle_179" data-name="Rectangle 179" transform="translate(793.889 2418)" fill="#fff" stroke="#fff" stroke-width="1">
                                <rect width="331" height="96" rx="48" stroke="none" />
                                <rect x="0.5" y="0.5" width="330" height="95" rx="47.5" fill="none" />
                            </g>
                            <text id="Read_more" data-name="Read more" transform="translate(837.889 2482)" font-size="45" font-family="LexendDeca-Medium, Lexend Deca" font-weight="500"><tspan x="0" y="0">Read more</tspan></text>
                        </g>
                    </svg>
                </div>


                {/* Services */}
                <div className="row" style={{

                }}>

                    <div className="row" style={{
                        color: 'white',
                        fontSize: '60px',
                        fontFamily: 'Lexend Deca',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end'
                    }}>SERVICES</div>
                    <div className="row" style={{
                        display: 'flex',
                        alignContent: 'flex-start',
                        justifyContent: 'center',
                        padding: '0px'

                    }}>

                        <svg viewBox="0 0 1502 917">
                            <g id="Group_383" data-name="Group 383" transform="translate(-40 -1113.805)">
                                <Link to="/services#ad">
                                    <rect id="Rectangle_165" data-name="Rectangle 165" width="347" height="597" rx="45" transform="translate(40 1433.805)" fill="#fff" opacity="0.832" />
                                </Link>
                                <rect id="Rectangle_166" data-name="Rectangle 166" width="347" height="285" rx="45" transform="translate(40 1113.805)" fill="#8553a5" opacity="0.8" />
                                <Link to="/services#visual">
                                    <rect id="Rectangle_168" data-name="Rectangle 168" width="347" height="748" rx="45" transform="translate(426 1113.805)" fill="#602f7f" opacity="0.822" />
                                </Link>
                                <rect id="Rectangle_169" data-name="Rectangle 169" width="348" height="126" rx="45" transform="translate(423 1904.805)" fill="#fff" opacity="0.804" />
                                <Link to="/services#video"><rect id="Rectangle_170" data-name="Rectangle 170" width="731" height="282" rx="45" transform="translate(811 1748.805)" fill="#602f7f" opacity="0.861" /></Link>
                                <Link to="/services#dev">
                                    <rect id="Rectangle_171" data-name="Rectangle 171" width="347" height="436" rx="45" transform="translate(1195 1272.805)" fill="#fff" opacity="0.859" />
                                </Link>
                                <rect id="Rectangle_172" data-name="Rectangle 172" width="347" height="127" rx="45" transform="translate(1195 1113.805)" fill="#8553a5" opacity="0.895" />
                                <Link to="/services#social"><rect id="Rectangle_167" data-name="Rectangle 167" width="347" height="595" rx="45" transform="translate(811 1113.805)" fill="#fff" opacity="0.867" /></Link>
                                <g id="u" transform="translate(1271.801 1402.793)">
                                    <g id="Group_384" data-name="Group 384" transform="translate(2.25 3.25)">
                                        <path id="Path_320" data-name="Path 320" d="M145.134,3.25H48.264A46.055,46.055,0,0,0,2.25,49.264v77.5a46.055,46.055,0,0,0,46.014,46.014h96.871a46.055,46.055,0,0,0,46.014-46.014v-77.5A46.055,46.055,0,0,0,145.134,3.25ZM48.264,17.781h96.871A31.455,31.455,0,0,1,175.649,42H17.749A31.455,31.455,0,0,1,48.264,17.781Zm96.871,140.463H48.264A31.544,31.544,0,0,1,16.781,126.76V56.529H176.617V126.76A31.544,31.544,0,0,1,145.134,158.243Z" transform="translate(-2.25 -3.25)" fill="#231f20" />
                                        <path id="Path_321" data-name="Path 321" d="M38,13.359a7.309,7.309,0,0,0-10.268,0L8.359,32.734A7.309,7.309,0,0,0,8.359,43L27.734,62.376a7.25,7.25,0,0,0,10.268,0,7.309,7.309,0,0,0,0-10.268l-14.24-14.24L38,23.628a7.309,7.309,0,0,0,0-10.268Z" transform="translate(32.52 66.268)" fill="#231f20" />
                                        <path id="Path_322" data-name="Path 322" d="M26.628,13.359A7.261,7.261,0,0,0,16.359,23.628L30.6,37.868l-14.24,14.24a7.309,7.309,0,0,0,0,10.268,7.25,7.25,0,0,0,10.268,0L46,43a7.309,7.309,0,0,0,0-10.268L26.628,13.359Z" transform="translate(102.017 66.268)" fill="#231f20" />
                                    </g>
                                </g>
                                <g id="Megaphone" transform="translate(107.806 1612.944)">
                                    <path id="Path_314" data-name="Path 314" d="M76.2,89.572H41.786A41.786,41.786,0,1,1,41.786,6H76.2a7.374,7.374,0,0,1,7.374,7.374V82.2A7.374,7.374,0,0,1,76.2,89.572ZM41.786,20.748a27.038,27.038,0,0,0,0,54.076H68.824V20.748Z" transform="translate(0 23.495)" />
                                    <path id="Path_315" data-name="Path 315" d="M124.612,142.562a7.511,7.511,0,0,1-2.025-.28l-103.236-29.5A7.375,7.375,0,0,1,23.4,98.6l93.836,26.807V17.151L23.4,43.962A7.375,7.375,0,0,1,19.351,29.78L122.586.284a7.374,7.374,0,0,1,9.4,7.089V135.188A7.374,7.374,0,0,1,124.612,142.562Z" transform="translate(54.822 0)" />
                                    <path id="Path_316" data-name="Path 316" d="M42.374,71.908a7.374,7.374,0,0,1,0-14.748,17.206,17.206,0,1,0,0-34.412A7.374,7.374,0,0,1,42.374,8a31.954,31.954,0,1,1,0,63.908Z" transform="translate(137.059 31.327)" />
                                    <path id="Path_317" data-name="Path 317" d="M32.264,27.748H18.593a7.374,7.374,0,1,1,0-14.748H32.264a7.374,7.374,0,0,1,0,14.748Z" transform="translate(43.933 50.907)" />
                                    <path id="Path_318" data-name="Path 318" d="M14.043,27.748a7.482,7.482,0,0,1-5.26-2.163A9.971,9.971,0,0,1,7.9,24.454,7.929,7.929,0,0,1,6.767,21.8a6.966,6.966,0,0,1,0-2.851A7.929,7.929,0,0,1,7.9,16.294a9.971,9.971,0,0,1,.885-1.131,10.373,10.373,0,0,1,1.131-.934,10.561,10.561,0,0,1,1.278-.688c.442-.147.934-.295,1.376-.393a7.2,7.2,0,0,1,2.9,0c.442.1.934.246,1.376.393a10.559,10.559,0,0,1,1.278.688,10.372,10.372,0,0,1,1.131.934,9.969,9.969,0,0,1,.885,1.131,7.929,7.929,0,0,1,1.131,2.655,6.966,6.966,0,0,1,0,2.851,7.929,7.929,0,0,1-1.131,2.655,9.97,9.97,0,0,1-.885,1.131,10.373,10.373,0,0,1-1.131.934,10.559,10.559,0,0,1-1.278.688c-.442.147-.934.295-1.376.393a7.373,7.373,0,0,1-1.426.147Z" transform="translate(25.924 50.907)" />
                                    <path id="Path_319" data-name="Path 319" d="M73.342,113.274H29.1a7.374,7.374,0,0,1-7.246-6.017L7.1,28.6A7.374,7.374,0,0,1,21.6,25.888L35.219,98.526H64.346L51.363,33.606a7.374,7.374,0,0,1,14.463-2.891l14.748,73.74a7.374,7.374,0,0,1-7.231,8.819Z" transform="translate(27.435 78.448)" />
                                </g>
                                <path id="clapperboard" d="M123.511,4.011a21.685,21.685,0,0,0-6.541.836L20.92,32.48A22.13,22.13,0,0,0,5.846,59.729L9,70.74v71.285A22.1,22.1,0,0,0,31,164.032H135.03a22.1,22.1,0,0,0,22.006-22.006V74.007a6,6,0,0,0-6-6H59.689l85-24.452a6,6,0,0,0,4.111-7.416l-4.618-16.176A22.121,22.121,0,0,0,125.652,4.167C124.944,4.079,124.231,4.029,123.511,4.011Zm4.665,13.566a9.885,9.885,0,0,1,4.462,5.681s0,.005,0,.008l2.977,10.409-19.24,5.533Zm-13.973.555L100.161,43.874l-21.8,6.275L92.393,24.407ZM76.186,29.073,62.143,54.814l-21.8,6.267L54.375,35.348ZM38.16,40.013,24.117,65.747l-3.759,1.078-2.97-10.394a9.96,9.96,0,0,1,6.853-12.417ZM21,80.009H145.033v62.017a9.914,9.914,0,0,1-10,10H31a9.914,9.914,0,0,1-10-10Z" transform="translate(1076.981 1805.787)" fill="#fff" />
                                <path id="Polygon_2" data-name="Polygon 2" d="M14.514,6.2a4,4,0,0,1,6.973,0L32.647,26.039A4,4,0,0,1,29.161,32H6.839a4,4,0,0,1-3.486-5.961Z" transform="translate(1181 1904.805) rotate(90)" fill="#fff" />
                                <g id="_027-finger_scanner" data-name="027-finger scanner" transform="translate(453.922 1367.861)">
                                    <path id="Path_324" data-name="Path 324" d="M323.077,225a4.057,4.057,0,0,0-4.057,4.057V264.35a69.043,69.043,0,0,1-68.965,68.965,4.057,4.057,0,1,0,0,8.113,77.166,77.166,0,0,0,77.078-77.078V229.056A4.057,4.057,0,0,0,323.077,225Z" transform="translate(-106.978 -133.722)" fill="#fff" />
                                    <path id="Path_325" data-name="Path 325" d="M294.036,13.7a69.017,69.017,0,0,1,51.728,66.774,4.057,4.057,0,0,0,8.114,0A76.182,76.182,0,0,0,337.41,32.961,77.756,77.756,0,0,0,296.073,5.847a4.057,4.057,0,0,0-2.037,7.853Z" transform="translate(-133.721 -3.397)" fill="#fff" />
                                    <path id="Path_326" data-name="Path 326" d="M125.841,382.831a69.017,69.017,0,0,1-51.728-66.774,4.057,4.057,0,0,0-8.114,0,76.182,76.182,0,0,0,16.467,47.514A77.755,77.755,0,0,0,123.8,390.685a4.057,4.057,0,1,0,2.036-7.853Z" transform="translate(0 -185.429)" fill="#fff" />
                                    <path id="Path_327" data-name="Path 327" d="M70.057,116.429a4.057,4.057,0,0,0,4.057-4.057V77.078A69.043,69.043,0,0,1,143.078,8.114a4.057,4.057,0,0,0,0-8.114A77.165,77.165,0,0,0,66,77.078v35.294A4.057,4.057,0,0,0,70.057,116.429Z" transform="translate(0)" fill="#fff" />
                                    <path id="Path_328" data-name="Path 328" d="M169.822,45A58.89,58.89,0,0,0,111,103.822v53.55a58.823,58.823,0,0,0,117.646,0v-53.55A58.889,58.889,0,0,0,169.822,45Zm50.71,112.373a50.71,50.71,0,0,1-101.419,0v-53.55a50.71,50.71,0,1,1,101.419,0Z" transform="translate(-26.744 -26.744)" fill="#fff" />
                                    <path id="Path_329" data-name="Path 329" d="M180.355,305.786a32.658,32.658,0,0,1-16.243-28.063V251.557a4.057,4.057,0,1,0-8.114,0v26.166a40.8,40.8,0,0,0,20.269,35.072,4.057,4.057,0,0,0,4.088-7.009Z" transform="translate(-53.489 -147.095)" fill="#fff" />
                                    <path id="Path_330" data-name="Path 330" d="M282.511,184.056v53.55a32.491,32.491,0,0,1-32.454,32.454,4.057,4.057,0,1,0,0,8.114,40.72,40.72,0,0,0,40.568-40.568v-53.55a4.057,4.057,0,0,0-8.114,0Z" transform="translate(-106.978 -106.978)" fill="#fff" />
                                    <path id="Path_331" data-name="Path 331" d="M169.184,143.751a13.2,13.2,0,0,0,13.184-13.185,14.2,14.2,0,0,1,28.4,0v27.586a4.057,4.057,0,1,0,8.114,0V130.567a22.312,22.312,0,0,0-44.625,0,5.071,5.071,0,0,1-10.142,0,32.432,32.432,0,0,1,60.533-16.215,4.057,4.057,0,0,0,7.013-4.081A40.546,40.546,0,0,0,156,130.567a13.211,13.211,0,0,0,13.184,13.185Z" transform="translate(-53.489 -53.489)" fill="#fff" />
                                    <path id="Path_332" data-name="Path 332" d="M268.313,293a4.057,4.057,0,0,0-4.057,4.057v7.708a14.215,14.215,0,0,1-14.2,14.2,4.057,4.057,0,0,0,0,8.114,22.337,22.337,0,0,0,22.312-22.312v-7.708A4.057,4.057,0,0,0,268.313,293Z" transform="translate(-106.979 -174.137)" fill="#fff" />
                                    <path id="Path_333" data-name="Path 333" d="M227.369,228.511V184.055a4.057,4.057,0,1,0-8.114,0v44.456a5.071,5.071,0,0,1-10.142,0V211.439a4.057,4.057,0,1,0-8.114,0v17.072a13.184,13.184,0,0,0,26.369,0Z" transform="translate(-80.234 -106.977)" fill="#fff" />
                                </g>
                                <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M121.865,72.338V32.716A29.716,29.716,0,0,0,92.149,3L52.527,92.149v108.96H164.261a19.811,19.811,0,0,0,19.811-16.839l13.67-89.149A19.811,19.811,0,0,0,177.93,72.338ZM52.527,201.109H22.811A19.811,19.811,0,0,1,3,181.3V111.96A19.811,19.811,0,0,1,22.811,92.149H52.527" transform="translate(884.017 1294.603)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="15" />
                            </g>
                        </svg>
                    </div>

                </div>

            </div>
            <div className="row d-flex d-sm-none" style={{
                height: "1000px",
                backgroundPosition: 'center',
                backgroundImage: `url(${Image1})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: "50px",

            }}>
                <div className='col' style={{

                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginTop: '-100px',
                    marginLeft: '90px'


                }}>
                    <svg style={{ width: "70px" }} width="250" height="250" viewBox="0 0 364 364">
                        <g id="Group_411" data-name="Group 411" transform="translate(-1299 -956)">
                            <circle id="Ellipse_1" data-name="Ellipse 1" cx="182" cy="182" r="182" transform="translate(1299 956)" />
                            <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M20.907,23.294H99.442L5.293,117.443l12.934,12.934,94.149-94.149v78.535h18.294V5H20.907Z" transform="translate(1413.019 1070.312)" fill="#fff" />
                        </g>
                    </svg>
                </div>
                {/* Animated div */}
                <div className='Container col'>
                    <div className="Animation">
                        <div className='MovingText'>
                            CREATIVITY&nbsp;<span style={{ display: 'flex', margin: '0px 300px 0px 300px' }}><svg id="_5" data-name="5" width="80.207" height="82.407" viewBox="0 0 80.207 82.407">
                                <g id="row1">
                                    <path id="icon:5" d="M80.207,40.3l-24,4.249A14.581,14.581,0,0,0,44.365,57.17L40.981,82.407,36.845,57.749A14.544,14.544,0,0,0,24.564,45.581L0,42.1l24-4.249A14.581,14.581,0,0,0,35.843,25.237L39.226,0l4.136,24.658A14.544,14.544,0,0,0,55.644,36.826Z" fill="#fff" />
                                </g>
                            </svg>
                            </span>&nbsp;CHALLENGE
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <svg onClick={() => ServicesClick()} style={{ width: '160px' }} width="331" height="96" viewBox="0 0 331 96">
                        <g id="Group_412" data-name="Group 412" transform="translate(-793.889 -2418)">
                            <g id="Rectangle_179" data-name="Rectangle 179" transform="translate(793.889 2418)" fill="#fff" stroke="#fff" stroke-width="1">
                                <rect width="331" height="96" rx="48" stroke="none" />
                                <rect x="0.5" y="0.5" width="330" height="95" rx="47.5" fill="none" />
                            </g>
                            <text id="Read_more" data-name="Read more" transform="translate(837.889 2482)" font-size="45" font-family="LexendDeca-Medium, Lexend Deca" font-weight="500"><tspan x="0" y="0">Read more</tspan></text>
                        </g>
                    </svg>
                </div>


                {/* Services */}
                <div className="row" style={{

                }}>

                    <div className="row" style={{
                        color: 'white',
                        fontSize: '30px',
                        fontFamily: 'Lexend Deca',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end',

                    }}>SERVICES</div>
                    <div className="row" style={{
                        display: 'flex',
                        alignContent: 'flex-start',
                        justifyContent: 'center',
                        padding: '0px',


                    }}>

                        <svg style={{
                            width: 'auto'
                        }} viewBox="0 0 1502 917">
                            <g id="Group_383" data-name="Group 383" transform="translate(-40 -1113.805)">
                                <Link to="/services#ad">
                                    <rect id="Rectangle_165" data-name="Rectangle 165" width="347" height="597" rx="45" transform="translate(40 1433.805)" fill="#fff" opacity="0.832" />
                                </Link>
                                <rect id="Rectangle_166" data-name="Rectangle 166" width="347" height="285" rx="45" transform="translate(40 1113.805)" fill="#8553a5" opacity="0.8" />
                                <Link to="/services#visual">
                                    <rect id="Rectangle_168" data-name="Rectangle 168" width="347" height="748" rx="45" transform="translate(426 1113.805)" fill="#602f7f" opacity="0.822" />
                                </Link>
                                <rect id="Rectangle_169" data-name="Rectangle 169" width="348" height="126" rx="45" transform="translate(423 1904.805)" fill="#fff" opacity="0.804" />
                                <Link to="/services#video">
                                    <rect id="Rectangle_170" data-name="Rectangle 170" width="731" height="282" rx="45" transform="translate(811 1748.805)" fill="#602f7f" opacity="0.861" />
                                </Link>
                                <Link to="/services#dev">
                                    <rect id="Rectangle_171" data-name="Rectangle 171" width="347" height="436" rx="45" transform="translate(1195 1272.805)" fill="#fff" opacity="0.859" />
                                </Link>
                                <rect id="Rectangle_172" data-name="Rectangle 172" width="347" height="127" rx="45" transform="translate(1195 1113.805)" fill="#8553a5" opacity="0.895" />
                                <Link to="/services#social">
                                    <rect id="Rectangle_167" data-name="Rectangle 167" width="347" height="595" rx="45" transform="translate(811 1113.805)" fill="#fff" opacity="0.867" />
                                </Link>
                                <g id="u" transform="translate(1271.801 1402.793)">
                                    <g id="Group_384" data-name="Group 384" transform="translate(2.25 3.25)">
                                        <path id="Path_320" data-name="Path 320" d="M145.134,3.25H48.264A46.055,46.055,0,0,0,2.25,49.264v77.5a46.055,46.055,0,0,0,46.014,46.014h96.871a46.055,46.055,0,0,0,46.014-46.014v-77.5A46.055,46.055,0,0,0,145.134,3.25ZM48.264,17.781h96.871A31.455,31.455,0,0,1,175.649,42H17.749A31.455,31.455,0,0,1,48.264,17.781Zm96.871,140.463H48.264A31.544,31.544,0,0,1,16.781,126.76V56.529H176.617V126.76A31.544,31.544,0,0,1,145.134,158.243Z" transform="translate(-2.25 -3.25)" fill="#231f20" />
                                        <path id="Path_321" data-name="Path 321" d="M38,13.359a7.309,7.309,0,0,0-10.268,0L8.359,32.734A7.309,7.309,0,0,0,8.359,43L27.734,62.376a7.25,7.25,0,0,0,10.268,0,7.309,7.309,0,0,0,0-10.268l-14.24-14.24L38,23.628a7.309,7.309,0,0,0,0-10.268Z" transform="translate(32.52 66.268)" fill="#231f20" />
                                        <path id="Path_322" data-name="Path 322" d="M26.628,13.359A7.261,7.261,0,0,0,16.359,23.628L30.6,37.868l-14.24,14.24a7.309,7.309,0,0,0,0,10.268,7.25,7.25,0,0,0,10.268,0L46,43a7.309,7.309,0,0,0,0-10.268L26.628,13.359Z" transform="translate(102.017 66.268)" fill="#231f20" />
                                    </g>
                                </g>
                                <g id="Megaphone" transform="translate(107.806 1612.944)">
                                    <path id="Path_314" data-name="Path 314" d="M76.2,89.572H41.786A41.786,41.786,0,1,1,41.786,6H76.2a7.374,7.374,0,0,1,7.374,7.374V82.2A7.374,7.374,0,0,1,76.2,89.572ZM41.786,20.748a27.038,27.038,0,0,0,0,54.076H68.824V20.748Z" transform="translate(0 23.495)" />
                                    <path id="Path_315" data-name="Path 315" d="M124.612,142.562a7.511,7.511,0,0,1-2.025-.28l-103.236-29.5A7.375,7.375,0,0,1,23.4,98.6l93.836,26.807V17.151L23.4,43.962A7.375,7.375,0,0,1,19.351,29.78L122.586.284a7.374,7.374,0,0,1,9.4,7.089V135.188A7.374,7.374,0,0,1,124.612,142.562Z" transform="translate(54.822 0)" />
                                    <path id="Path_316" data-name="Path 316" d="M42.374,71.908a7.374,7.374,0,0,1,0-14.748,17.206,17.206,0,1,0,0-34.412A7.374,7.374,0,0,1,42.374,8a31.954,31.954,0,1,1,0,63.908Z" transform="translate(137.059 31.327)" />
                                    <path id="Path_317" data-name="Path 317" d="M32.264,27.748H18.593a7.374,7.374,0,1,1,0-14.748H32.264a7.374,7.374,0,0,1,0,14.748Z" transform="translate(43.933 50.907)" />
                                    <path id="Path_318" data-name="Path 318" d="M14.043,27.748a7.482,7.482,0,0,1-5.26-2.163A9.971,9.971,0,0,1,7.9,24.454,7.929,7.929,0,0,1,6.767,21.8a6.966,6.966,0,0,1,0-2.851A7.929,7.929,0,0,1,7.9,16.294a9.971,9.971,0,0,1,.885-1.131,10.373,10.373,0,0,1,1.131-.934,10.561,10.561,0,0,1,1.278-.688c.442-.147.934-.295,1.376-.393a7.2,7.2,0,0,1,2.9,0c.442.1.934.246,1.376.393a10.559,10.559,0,0,1,1.278.688,10.372,10.372,0,0,1,1.131.934,9.969,9.969,0,0,1,.885,1.131,7.929,7.929,0,0,1,1.131,2.655,6.966,6.966,0,0,1,0,2.851,7.929,7.929,0,0,1-1.131,2.655,9.97,9.97,0,0,1-.885,1.131,10.373,10.373,0,0,1-1.131.934,10.559,10.559,0,0,1-1.278.688c-.442.147-.934.295-1.376.393a7.373,7.373,0,0,1-1.426.147Z" transform="translate(25.924 50.907)" />
                                    <path id="Path_319" data-name="Path 319" d="M73.342,113.274H29.1a7.374,7.374,0,0,1-7.246-6.017L7.1,28.6A7.374,7.374,0,0,1,21.6,25.888L35.219,98.526H64.346L51.363,33.606a7.374,7.374,0,0,1,14.463-2.891l14.748,73.74a7.374,7.374,0,0,1-7.231,8.819Z" transform="translate(27.435 78.448)" />
                                </g>
                                <path id="clapperboard" d="M123.511,4.011a21.685,21.685,0,0,0-6.541.836L20.92,32.48A22.13,22.13,0,0,0,5.846,59.729L9,70.74v71.285A22.1,22.1,0,0,0,31,164.032H135.03a22.1,22.1,0,0,0,22.006-22.006V74.007a6,6,0,0,0-6-6H59.689l85-24.452a6,6,0,0,0,4.111-7.416l-4.618-16.176A22.121,22.121,0,0,0,125.652,4.167C124.944,4.079,124.231,4.029,123.511,4.011Zm4.665,13.566a9.885,9.885,0,0,1,4.462,5.681s0,.005,0,.008l2.977,10.409-19.24,5.533Zm-13.973.555L100.161,43.874l-21.8,6.275L92.393,24.407ZM76.186,29.073,62.143,54.814l-21.8,6.267L54.375,35.348ZM38.16,40.013,24.117,65.747l-3.759,1.078-2.97-10.394a9.96,9.96,0,0,1,6.853-12.417ZM21,80.009H145.033v62.017a9.914,9.914,0,0,1-10,10H31a9.914,9.914,0,0,1-10-10Z" transform="translate(1076.981 1805.787)" fill="#fff" />
                                <path id="Polygon_2" data-name="Polygon 2" d="M14.514,6.2a4,4,0,0,1,6.973,0L32.647,26.039A4,4,0,0,1,29.161,32H6.839a4,4,0,0,1-3.486-5.961Z" transform="translate(1181 1904.805) rotate(90)" fill="#fff" />
                                <g id="_027-finger_scanner" data-name="027-finger scanner" transform="translate(453.922 1367.861)">
                                    <path id="Path_324" data-name="Path 324" d="M323.077,225a4.057,4.057,0,0,0-4.057,4.057V264.35a69.043,69.043,0,0,1-68.965,68.965,4.057,4.057,0,1,0,0,8.113,77.166,77.166,0,0,0,77.078-77.078V229.056A4.057,4.057,0,0,0,323.077,225Z" transform="translate(-106.978 -133.722)" fill="#fff" />
                                    <path id="Path_325" data-name="Path 325" d="M294.036,13.7a69.017,69.017,0,0,1,51.728,66.774,4.057,4.057,0,0,0,8.114,0A76.182,76.182,0,0,0,337.41,32.961,77.756,77.756,0,0,0,296.073,5.847a4.057,4.057,0,0,0-2.037,7.853Z" transform="translate(-133.721 -3.397)" fill="#fff" />
                                    <path id="Path_326" data-name="Path 326" d="M125.841,382.831a69.017,69.017,0,0,1-51.728-66.774,4.057,4.057,0,0,0-8.114,0,76.182,76.182,0,0,0,16.467,47.514A77.755,77.755,0,0,0,123.8,390.685a4.057,4.057,0,1,0,2.036-7.853Z" transform="translate(0 -185.429)" fill="#fff" />
                                    <path id="Path_327" data-name="Path 327" d="M70.057,116.429a4.057,4.057,0,0,0,4.057-4.057V77.078A69.043,69.043,0,0,1,143.078,8.114a4.057,4.057,0,0,0,0-8.114A77.165,77.165,0,0,0,66,77.078v35.294A4.057,4.057,0,0,0,70.057,116.429Z" transform="translate(0)" fill="#fff" />
                                    <path id="Path_328" data-name="Path 328" d="M169.822,45A58.89,58.89,0,0,0,111,103.822v53.55a58.823,58.823,0,0,0,117.646,0v-53.55A58.889,58.889,0,0,0,169.822,45Zm50.71,112.373a50.71,50.71,0,0,1-101.419,0v-53.55a50.71,50.71,0,1,1,101.419,0Z" transform="translate(-26.744 -26.744)" fill="#fff" />
                                    <path id="Path_329" data-name="Path 329" d="M180.355,305.786a32.658,32.658,0,0,1-16.243-28.063V251.557a4.057,4.057,0,1,0-8.114,0v26.166a40.8,40.8,0,0,0,20.269,35.072,4.057,4.057,0,0,0,4.088-7.009Z" transform="translate(-53.489 -147.095)" fill="#fff" />
                                    <path id="Path_330" data-name="Path 330" d="M282.511,184.056v53.55a32.491,32.491,0,0,1-32.454,32.454,4.057,4.057,0,1,0,0,8.114,40.72,40.72,0,0,0,40.568-40.568v-53.55a4.057,4.057,0,0,0-8.114,0Z" transform="translate(-106.978 -106.978)" fill="#fff" />
                                    <path id="Path_331" data-name="Path 331" d="M169.184,143.751a13.2,13.2,0,0,0,13.184-13.185,14.2,14.2,0,0,1,28.4,0v27.586a4.057,4.057,0,1,0,8.114,0V130.567a22.312,22.312,0,0,0-44.625,0,5.071,5.071,0,0,1-10.142,0,32.432,32.432,0,0,1,60.533-16.215,4.057,4.057,0,0,0,7.013-4.081A40.546,40.546,0,0,0,156,130.567a13.211,13.211,0,0,0,13.184,13.185Z" transform="translate(-53.489 -53.489)" fill="#fff" />
                                    <path id="Path_332" data-name="Path 332" d="M268.313,293a4.057,4.057,0,0,0-4.057,4.057v7.708a14.215,14.215,0,0,1-14.2,14.2,4.057,4.057,0,0,0,0,8.114,22.337,22.337,0,0,0,22.312-22.312v-7.708A4.057,4.057,0,0,0,268.313,293Z" transform="translate(-106.979 -174.137)" fill="#fff" />
                                    <path id="Path_333" data-name="Path 333" d="M227.369,228.511V184.055a4.057,4.057,0,1,0-8.114,0v44.456a5.071,5.071,0,0,1-10.142,0V211.439a4.057,4.057,0,1,0-8.114,0v17.072a13.184,13.184,0,0,0,26.369,0Z" transform="translate(-80.234 -106.977)" fill="#fff" />
                                </g>
                                <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M121.865,72.338V32.716A29.716,29.716,0,0,0,92.149,3L52.527,92.149v108.96H164.261a19.811,19.811,0,0,0,19.811-16.839l13.67-89.149A19.811,19.811,0,0,0,177.93,72.338ZM52.527,201.109H22.811A19.811,19.811,0,0,1,3,181.3V111.96A19.811,19.811,0,0,1,22.811,92.149H52.527" transform="translate(884.017 1294.603)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="15" />
                            </g>
                        </svg>
                    </div>

                </div>

            </div>
            <div ref={ref1} className="row d-none d-xl-flex mb-5" >
                <div className="row" style={{
                    fontSize: '120px',
                    fontFamily: 'Lexend Deca',
                    color: 'white'
                }}>PORTFOLIO</div>
                <div className="col">
                    <div style={{
                        marginLeft: '50px'
                    }}>

                        <svg className='Portfolio' width="150" height="48" viewBox="0 0 190 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-219 -4927)">
                                <g id="Rectangle_180" data-name="Rectangle 180" transform="translate(219 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="190" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="189" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(232.75 4935.31)" fill="#fff" />
                                <text id="Design" transform="translate(282 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Design</tspan></text>
                            </g>
                        </svg>


                        <svg className='Portfolio' width="226" height="48" viewBox="0 0 276 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-449 -4927)">
                                <g id="Rectangle_181" data-name="Rectangle 181" transform="translate(449 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="276" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="275" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(462.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="Development" transform="translate(512 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Development</tspan></text>
                            </g>
                        </svg>


                        <svg className='Portfolio' width="279" height="48" viewBox="0 0 329 48">
                            <g id="Group_415" data-name="Group 415" transform="translate(-769 -4927)">
                                <g id="Rectangle_182" data-name="Rectangle 182" transform="translate(769 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="329" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="328" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(782.75 4935.31)" fill="#fff" />
                                <text id="Digital_Marketing" data-name="Digital Marketing" transform="translate(832 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Digital Marketing</tspan></text>
                            </g>
                        </svg>



                        <svg className='Portfolio' width="109" height="48" viewBox="0 0 129 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-1138 -4927)">
                                <g id="Rectangle_183" data-name="Rectangle 183" transform="translate(1138 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="129" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="128" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(1150.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="SEO" transform="translate(1190 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">SEO</tspan></text>
                            </g>
                        </svg>

                    </div>
                </div>


            </div>

            <div className="row d-none d-lg-flex d-xl-none mb-5">
                <div className="row" style={{
                    fontSize: '120px',
                    fontFamily: 'Lexend Deca',
                    color: 'white'
                }}>PORTFOLIO</div>
                <div className="col">
                    <div style={{
                        marginLeft: '50px'
                    }}>

                        <svg className='Portfolio' width="150" height="48" viewBox="0 0 190 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-219 -4927)">
                                <g id="Rectangle_180" data-name="Rectangle 180" transform="translate(219 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="190" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="189" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(232.75 4935.31)" fill="#fff" />
                                <text id="Design" transform="translate(282 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Design</tspan></text>
                            </g>
                        </svg>


                        <svg className='Portfolio' width="226" height="48" viewBox="0 0 276 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-449 -4927)">
                                <g id="Rectangle_181" data-name="Rectangle 181" transform="translate(449 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="276" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="275" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(462.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="Development" transform="translate(512 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Development</tspan></text>
                            </g>
                        </svg>


                        <svg className='Portfolio' width="279" height="48" viewBox="0 0 329 48">
                            <g id="Group_415" data-name="Group 415" transform="translate(-769 -4927)">
                                <g id="Rectangle_182" data-name="Rectangle 182" transform="translate(769 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="329" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="328" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(782.75 4935.31)" fill="#fff" />
                                <text id="Digital_Marketing" data-name="Digital Marketing" transform="translate(832 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Digital Marketing</tspan></text>
                            </g>
                        </svg>



                        <svg className='Portfolio' width="109" height="48" viewBox="0 0 129 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-1138 -4927)">
                                <g id="Rectangle_183" data-name="Rectangle 183" transform="translate(1138 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="129" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="128" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(1150.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="SEO" transform="translate(1190 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">SEO</tspan></text>
                            </g>
                        </svg>

                    </div>
                </div>


            </div>

            <div className="row d-none d-md-flex d-lg-none mb-5">
                <div className="row" style={{
                    fontSize: '120px',
                    fontFamily: 'Lexend Deca',
                    color: 'white'
                }}>PORTFOLIO</div>
                <div className="col">
                    <div style={{
                        marginLeft: '50px'
                    }}>

                        <svg style={{ width: '120px' }} className='' width="150" height="48" viewBox="0 0 190 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-219 -4927)">
                                <g id="Rectangle_180" data-name="Rectangle 180" transform="translate(219 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="190" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="189" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(232.75 4935.31)" fill="#fff" />
                                <text id="Design" transform="translate(282 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Design</tspan></text>
                            </g>
                        </svg>


                        <svg style={{ width: '166px' }} className='' width="226" height="48" viewBox="0 0 276 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-449 -4927)">
                                <g id="Rectangle_181" data-name="Rectangle 181" transform="translate(449 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="276" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="275" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(462.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="Development" transform="translate(512 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Development</tspan></text>
                            </g>
                        </svg>


                        <svg style={{ width: '185px' }} className='' width="279" height="48" viewBox="0 0 329 48">
                            <g id="Group_415" data-name="Group 415" transform="translate(-769 -4927)">
                                <g id="Rectangle_182" data-name="Rectangle 182" transform="translate(769 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="329" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="328" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(782.75 4935.31)" fill="#fff" />
                                <text id="Digital_Marketing" data-name="Digital Marketing" transform="translate(832 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Digital Marketing</tspan></text>
                            </g>
                        </svg>



                        <svg style={{ width: '70px' }} className='' width="109" height="48" viewBox="0 0 129 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-1138 -4927)">
                                <g id="Rectangle_183" data-name="Rectangle 183" transform="translate(1138 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="129" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="128" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(1150.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="SEO" transform="translate(1190 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">SEO</tspan></text>
                            </g>
                        </svg>

                    </div>
                </div>


            </div>

            <div className="row d-none d-sm-flex d-md-none mb-5">
                <div className="row" style={{
                    fontSize: '60px',
                    fontFamily: 'Lexend Deca',
                    color: 'white'
                }}>PORTFOLIO</div>
                <div className="col">
                    <div style={{
                        marginLeft: '50px'
                    }}>

                        <svg style={{ width: '83px' }} className='' width="150" height="48" viewBox="0 0 190 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-219 -4927)">
                                <g id="Rectangle_180" data-name="Rectangle 180" transform="translate(219 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="190" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="189" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(232.75 4935.31)" fill="#fff" />
                                <text id="Design" transform="translate(282 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Design</tspan></text>
                            </g>
                        </svg>


                        <svg style={{ width: '120px' }} className='' width="226" height="48" viewBox="0 0 276 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-449 -4927)">
                                <g id="Rectangle_181" data-name="Rectangle 181" transform="translate(449 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="276" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="275" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(462.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="Development" transform="translate(512 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Development</tspan></text>
                            </g>
                        </svg>


                        <svg style={{ width: '140px' }} className='' width="279" height="48" viewBox="0 0 329 48">
                            <g id="Group_415" data-name="Group 415" transform="translate(-769 -4927)">
                                <g id="Rectangle_182" data-name="Rectangle 182" transform="translate(769 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="329" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="328" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(782.75 4935.31)" fill="#fff" />
                                <text id="Digital_Marketing" data-name="Digital Marketing" transform="translate(832 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Digital Marketing</tspan></text>
                            </g>
                        </svg>



                        <svg style={{ width: '58px' }} className='' width="109" height="48" viewBox="0 0 129 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-1138 -4927)">
                                <g id="Rectangle_183" data-name="Rectangle 183" transform="translate(1138 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="129" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="128" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(1150.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="SEO" transform="translate(1190 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">SEO</tspan></text>
                            </g>
                        </svg>

                    </div>
                </div>


            </div>

            <div className="row d-flex d-sm-none mb-5">
                <div className="row" style={{
                    fontSize: '30px',
                    fontFamily: 'Lexend Deca',
                    color: 'white'
                }}>PORTFOLIO</div>
                <div className="col">
                    <div style={{

                    }}>

                        <svg style={{ width: '58px' }} className='' width="150" height="48" viewBox="0 0 190 48">
                            <g id="Group_413" data-name="Group 413" transform="translate(-219 -4927)">
                                <g id="Rectangle_180" data-name="Rectangle 180" transform="translate(219 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="190" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="189" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(232.75 4935.31)" fill="#fff" />
                                <text id="Design" transform="translate(282 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Design</tspan></text>
                            </g>
                        </svg>


                        <svg style={{ width: '85px' }} className='' width="226" height="48" viewBox="0 0 276 48">
                            <g id="Group_414" data-name="Group 414" transform="translate(-449 -4927)">
                                <g id="Rectangle_181" data-name="Rectangle 181" transform="translate(449 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="276" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="275" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(462.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="Development" transform="translate(512 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Development</tspan></text>
                            </g>
                        </svg>


                        <svg style={{ width: '100px' }} className='' width="279" height="48" viewBox="0 0 329 48">
                            <g id="Group_415" data-name="Group 415" transform="translate(-769 -4927)">
                                <g id="Rectangle_182" data-name="Rectangle 182" transform="translate(769 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="329" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="328" height="47" rx="22.5" fill="none" />
                                </g>
                                <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" transform="translate(782.75 4935.31)" fill="#fff" />
                                <text id="Digital_Marketing" data-name="Digital Marketing" transform="translate(832 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">Digital Marketing</tspan></text>
                            </g>
                        </svg>



                        <svg style={{ width: '40px' }} className='' width="109" height="48" viewBox="0 0 129 48">
                            <g id="Group_416" data-name="Group 416" transform="translate(-1138 -4927)">
                                <g id="Rectangle_183" data-name="Rectangle 183" transform="translate(1138 4927)" fill="none" stroke="#fff" stroke-width="1">
                                    <rect width="129" height="48" rx="23" stroke="none" />
                                    <rect x="0.5" y="0.5" width="128" height="47" rx="22.5" fill="none" />
                                </g>
                                <g id="t" transform="translate(1150.75 4935.31)">
                                    <path id="Path_323" data-name="Path 323" d="M29.13,15.69A13.449,13.449,0,0,0,15.69,2.25h-.028a13.44,13.44,0,0,0,.028,26.88h0A13.449,13.449,0,0,0,29.13,15.69Zm-24.813,0a11.547,11.547,0,0,1,.441-3.1h3.4a15.508,15.508,0,0,0-.4,3.1,15.754,15.754,0,0,0,.317,3.1H4.759a11.547,11.547,0,0,1-.441-3.1Zm5.514,0a14.022,14.022,0,0,1,.441-3.1h4.384v6.2H10.231A12.983,12.983,0,0,1,9.832,15.69Zm10.642-5.169H16.724V5.393A15,15,0,0,1,20.474,10.521ZM14.656,5.31v5.211H10.948A13.219,13.219,0,0,1,14.656,5.31Zm0,15.549v5.128a15,15,0,0,1-3.749-5.128Zm2.068,5.183V20.859h3.708A13.383,13.383,0,0,1,16.724,26.043Zm0-7.251v-6.2h4.439a12.906,12.906,0,0,1,.386,3.1,13.928,13.928,0,0,1-.441,3.1Zm6.575-6.2h3.322a11.124,11.124,0,0,1,0,6.2h-3.4a15.508,15.508,0,0,0,.4-3.1,15.755,15.755,0,0,0-.317-3.1Zm2.509-2.068H22.693a16.631,16.631,0,0,0-3.584-5.679A11.408,11.408,0,0,1,25.808,10.521ZM12.134,4.9a15.876,15.876,0,0,0-3.377,5.624H5.572A11.48,11.48,0,0,1,12.12,4.9ZM5.572,20.859H8.688a16.631,16.631,0,0,0,3.584,5.679A11.408,11.408,0,0,1,5.572,20.859Zm13.675,5.624a15.876,15.876,0,0,0,3.377-5.624h3.184a11.48,11.48,0,0,1-6.548,5.624Z" fill="#fff" />
                                </g>
                                <text id="SEO" transform="translate(1190 4962)" fill="#fff" font-size="30" font-family="LexendDeca-Light, Lexend Deca" font-weight="300"><tspan x="0" y="0">SEO</tspan></text>
                            </g>
                        </svg>

                    </div>
                </div>


            </div>






            <div className="row container">
                <div className="row">
                    <div className="col-4">
                        <img src={PortfolioImage1} alt="logo" className="img-fluid" />
                    </div>
                    <div className="col-8">
                        <img src={PortfolioImage2} alt="logo" className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <img src={PortfolioImage3} alt="logo" className="img-fluid" />
                    </div>
                    <div className="col-4">
                        <img src={PortfolioImage4} alt="logo" className="img-fluid" />
                    </div>
                    <div className="col-4">
                        <img src={PortfolioImage5} alt="logo" className="img-fluid" />
                    </div>
                </div>
            </div>













            <div ref={ref2} className="row d-none d-xl-block" style={{
                color: 'white',
                fontSize: '120px',
                fontFamily: 'Lexend Deca',
                fontWeight: '500',

            }}>OUR CLIENTS</div>
            <div id='client' className="row d-none d-lg-block d-xl-none" style={{
                color: 'white',
                fontSize: '100px',
                fontFamily: 'Lexend Deca',
                fontWeight: '500',

            }}>OUR CLIENTS</div>

            <div className="row d-none d-md-block d-lg-none" style={{
                color: 'white',
                fontSize: '80px',
                fontFamily: 'Lexend Deca',
                fontWeight: '500',

            }}>OUR CLIENTS</div>
            <div className="row d-none d-sm-block d-md-none" style={{
                color: 'white',
                fontSize: '60px',
                fontFamily: 'Lexend Deca',
                fontWeight: '500',

            }}>OUR CLIENTS</div>

            <div className="row d-block d-sm-none" style={{
                color: 'white',
                fontSize: '30px',
                fontFamily: 'Lexend Deca',
                fontWeight: '500',

            }}>OUR CLIENTS</div>

            <div className="row Scroller d-none d-md-flex px-5 pb-2">
                {!yaqeenToggle && (
                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => YaqeenClick(yaqeenToggle, setYaqeenToggle)}>
                        <div className="col-4">
                            Yaqeen
                        </div>
                        <div className="col-1">
                            <svg width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {yaqeenToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => YaqeenClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500' }}>
                            Yaqeen
                        </div>
                        <div className="col-4">
                            <img src={yaqeenLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!mindToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => MindClick()}>
                        <div className="col-4" >
                            Mind care
                        </div>
                        <div className="col-1">
                            <svg width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {mindToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => MindClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500' }}>
                            Mind care
                        </div>
                        <div className="col-4">
                            <img src={mindLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!vastaToggle && (
                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => VastaClick()}>
                        <div className="col-4">
                            Vasta
                        </div>
                        <div className="col-1">
                            <svg width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )
                }
                {vastaToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => VastaClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500' }}>
                            Vasta
                        </div>
                        <div className="col-4">
                            <img src={vastaLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!itisToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => ItisClick()}>
                        <div className="col-4">
                            Itis
                        </div>
                        <div className="col-1">
                            <svg width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {itisToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => ItisClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500' }}>
                            Itis
                        </div>
                        <div className="col-4">
                            <img src={itisLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}

                {!msaderToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => MsaderClick()}>
                        <div className="col-4">
                            Msader
                        </div>
                        <div className="col-1">
                            <svg width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {msaderToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => MsaderClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500' }}>
                            Msader
                        </div>
                        <div className="col-4">
                            <img src={msaderLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!atharToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => AtharClick()}>
                        <div className="col-4">
                            Athar
                        </div>
                        <div className="col-1">
                            <svg width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {atharToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => AtharClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500' }}>
                            Athar
                        </div>
                        <div className="col-4">
                            <img src={atharLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!deliverToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => DeliverClick()}>
                        <div className="col-4">
                            Deliver it
                        </div>
                        <div className="col-1">
                            <svg width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {deliverToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => DeliverClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500' }}>
                            Deliver it
                        </div>
                        <div className="col-4">
                            <img src={deliverLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!itracksToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => ItracksClick()}>
                        <div className="col-4">
                            Itracks
                        </div>
                        <div className="col-1">
                            <svg width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {itracksToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => ItracksClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500' }}>
                            Itracks
                        </div>
                        <div className="col-4">
                            <img src={itracksLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!goodToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => GoodClick()}>
                        <div className="col-4">
                            Good&nbsp;food
                        </div>
                        <div className="col-1">
                            <svg width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {goodToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => GoodClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500' }}>
                            Good&nbsp;food
                        </div>
                        <div className="col-4">
                            <img src={goodLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
            </div>

            <div className="row d-flex d-md-none Scroller1">
                {!yaqeenToggle && (
                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => YaqeenClick(yaqeenToggle, setYaqeenToggle)}>
                        <div className="col-4" style={{ fontSize: '30px' }}>
                            Yaqeen
                        </div>
                        <div className="col-1">
                            <svg style={{ width: ' 30px' }} width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {yaqeenToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => YaqeenClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500', fontSize: '30px' }}>
                            Yaqeen
                        </div>
                        <div className="col-4">
                            <img style={{ width: '100px', height: '40px' }} src={yaqeenLogo} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg style={{ width: '70px' }} width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!mindToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" style={{ fontSize: '30px' }} onClick={() => MindClick()}>
                        <div className="col-4" >
                            Mind&nbsp;care
                        </div>
                        <div className="col-1" >
                            <svg style={{ width: '30px' }} width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {mindToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => MindClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500', fontSize: '30px' }}>
                            Mind&nbsp;care
                        </div>
                        <div className="col-4">
                            <img src={mindLogo} style={{ width: '80px', height: '90px', marginLeft: '30px' }} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg style={{ width: '70px' }} width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!vastaToggle && (
                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => VastaClick()}>
                        <div className="col-4" style={{ fontSize: '30px' }} >
                            Vasta
                        </div>
                        <div className="col-1">
                            <svg style={{ width: '30px' }} width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )
                }
                {vastaToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => VastaClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500', fontSize: '30px' }}>
                            Vasta
                        </div>
                        <div className="col-4">
                            <img src={vastaLogo} style={{ width: '100px', height: '100px' }} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg style={{ width: '70px' }} width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!itisToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => ItisClick()}>
                        <div className="col-4" style={{ fontSize: '30px' }}>
                            Itis
                        </div>
                        <div className="col-1">
                            <svg style={{ width: '30px' }} width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {itisToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => ItisClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500', fontSize: '30px' }}>
                            Itis
                        </div>
                        <div className="col-4">
                            <img src={itisLogo} style={{ height: '90px', width: '80px' }} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg style={{ width: '70px' }} width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}

                {!msaderToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => MsaderClick()}>
                        <div className="col-4" style={{ fontSize: '30px' }}>
                            Msader
                        </div>
                        <div className="col-1">
                            <svg style={{ width: '30px' }} width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {msaderToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => MsaderClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500', fontSize: '30px' }}>
                            Msader
                        </div>
                        <div className="col-4">
                            <img src={msaderLogo} style={{ width: '80px', height: '90px' }} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg style={{ width: '70px' }} width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!atharToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => AtharClick()}>
                        <div className="col-4" style={{ fontSize: '30px' }}>
                            Athar
                        </div>
                        <div className="col-1">
                            <svg style={{ width: '30px' }} width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {atharToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => AtharClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500', fontSize: '30px' }}>
                            Athar
                        </div>
                        <div className="col-4">
                            <img src={atharLogo} style={{ height: '90px', width: '80px' }} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg style={{ width: '70px' }} width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!deliverToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => DeliverClick()}>
                        <div className="col-4" style={{ fontSize: '30px' }}>
                            Deliver&nbsp;it
                        </div>
                        <div className="col-1">
                            <svg style={{ width: '30px' }} width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {deliverToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => DeliverClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500', fontSize: '30px' }}>
                            Deliver&nbsp;it
                        </div>
                        <div className="col-4">
                            <img src={deliverLogo} style={{ height: '90px', width: '80px' }} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg style={{ width: '70px' }} width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!itracksToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => ItracksClick()}>
                        <div className="col-4" style={{ fontSize: '30px' }}>
                            Itracks
                        </div>
                        <div className="col-1">
                            <svg style={{ width: '30px' }} width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {itracksToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => ItracksClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500', fontSize: '30px' }}>
                            Itracks
                        </div>
                        <div className="col-4">
                            <img src={itracksLogo} style={{ height: '90px', width: '80px' }} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg style={{ width: '70px' }} width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
                {!goodToggle && (

                    <div className="row mb-2 Scrollbox justify-content-between" onClick={() => GoodClick()}>
                        <div className="col-4" style={{ fontSize: '30px' }}>
                            Good&nbsp;food
                        </div>
                        <div className="col-1">
                            <svg style={{ width: '30px' }} width="79.477" height="79.477" viewBox="0 0 79.477 79.477">
                                <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(-5.293 -5)" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                )}
                {goodToggle && (
                    <div className="row mb-2 Scrollbox justify-content-around" onClick={() => GoodClick()} style={{ backgroundColor: '#707070' }}>
                        <div className="col-4" style={{ fontWeight: '500', fontSize: '30px' }}>
                            Good&nbsp;food
                        </div>
                        <div className="col-4">
                            <img src={goodLogo} style={{ height: '90px', width: '80px', marginLeft: '30px' }} alt='logo' height="150" width="140" />

                        </div>
                        <div className="col-1">
                            <svg style={{ width: '70px' }} width="202" height="202" viewBox="0 0 202 202">
                                <g id="Group_412" data-name="Group 412" transform="translate(-414 -6185)">
                                    <rect id="Rectangle_193" data-name="Rectangle 193" width="202" height="202" transform="translate(414 6185)" fill="#8553a5" />
                                    <path id="Arrow_Diagonal_Up_Right" data-name="Arrow Diagonal Up Right" d="M15.191,16.6H64.975L5.293,76.278l8.2,8.2L73.174,24.8V74.58h11.6V5H15.191Z" transform="translate(470.23 6240.736)" fill="#fff" />
                                </g>
                            </svg>

                        </div>
                    </div>
                )}
            </div>
            {/* What they say about us */}
            <div className="row justify-content-between d-none d-xl-flex" style={{
                height: '750px',
                backgroundColor: '#8553A5',
                borderRadius: '104px 104px 0 0',
                marginTop: '300px',
                padding: '0px 60px 40px 30px'


            }}>
                <div className="col-8" style={{
                    borderRadius: '104px 104px 0 104px',
                    backgroundColor: 'white',
                    marginTop: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}

                        pagination={{ clickable: true }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}

                    >
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>


                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>


                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>


                        </SwiperSlide>

                    </Swiper>
                </div>
                <div className="col-3 mt-3" style={{
                    padding: '50px',
                    height: '300px',
                    textAlign: 'center',
                    lineHeight: '50px',
                    backgroundColor: 'white',
                    borderRadius: '79px 79px 79px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '35px',
                    fontWeight: '500',
                    fontFamily: 'Lexend Deca'
                }}>What they say about us?</div>
            </div>
            <div className="row justify-content-between d-none d-lg-flex d-xl-none" style={{
                height: '450px',
                backgroundColor: '#8553A5',
                borderRadius: '104px 104px 0 0',
                marginTop: '300px',
                padding: '0px 40px 20px 10px'


            }}>
                <div className="col-8" style={{
                    borderRadius: '104px 104px 0 104px',
                    backgroundColor: 'white',
                    marginTop: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}

                        pagination={{ clickable: true }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}

                    >
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '130px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '13px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>


                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '130px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '13px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>


                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '130px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '13px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>

                        </SwiperSlide>

                    </Swiper>
                </div>
                <div className="col-3 mt-3" style={{
                    padding: '50px',
                    height: '300px',
                    textAlign: 'center',
                    lineHeight: '50px',
                    backgroundColor: 'white',
                    borderRadius: '79px 79px 79px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '25px',
                    fontWeight: '500',
                    fontFamily: 'Lexend Deca'
                }}>What they say about us?</div>
            </div>
            <div className="row justify-content-between d-none d-md-flex d-lg-none" style={{
                height: '450px',
                backgroundColor: '#8553A5',
                borderRadius: '104px 104px 0 0',
                marginTop: '300px',
                padding: '0px 40px 20px 10px'


            }}>
                <div className="col-8" style={{
                    borderRadius: '104px 104px 0 104px',
                    backgroundColor: 'white',
                    marginTop: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}

                        pagination={{ clickable: true }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}

                    >
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '10px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>


                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '10px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '10px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
                <div className="col-3 mt-3" style={{
                    padding: '20px',
                    height: '200px',
                    textAlign: 'center',
                    lineHeight: '50px',
                    backgroundColor: 'white',
                    borderRadius: '79px 79px 79px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '15px',
                    fontWeight: '500',
                    fontFamily: 'Lexend Deca'
                }}>What they say about us?</div>
            </div>
            <div className="row justify-content-between d-none d-sm-flex d-md-none" style={{
                height: '350px',
                backgroundColor: '#8553A5',
                borderRadius: '104px 104px 0 0',
                marginTop: '300px',
                padding: '0px 30px 20px 10px'


            }}>
                <div className="col-8" style={{
                    borderRadius: '104px 104px 0 104px',
                    backgroundColor: 'white',
                    marginTop: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}

                        pagination={{ clickable: true }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}

                    >
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '50px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '10px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>


                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '10px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '10px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
                <div className="col-3 mt-3" style={{
                    padding: '20px',
                    height: '150px',
                    textAlign: 'center',
                    lineHeight: '50px',
                    backgroundColor: 'white',
                    borderRadius: '79px 79px 79px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '10px',
                    fontWeight: '500',
                    fontFamily: 'Lexend Deca'
                }}>What they say about us?</div>
            </div>
            <div className="row justify-content-between d-flex d-sm-none" style={{
                height: '250px',
                backgroundColor: '#8553A5',
                borderRadius: '104px 104px 0 0',
                marginTop: '300px',
                padding: '0px 30px 20px 10px'


            }}>
                <div className="col-8" style={{
                    borderRadius: '104px 104px 0 104px',
                    backgroundColor: 'white',
                    marginTop: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}

                        pagination={{ clickable: true }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}

                    >
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '30px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '5px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>


                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '30px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '5px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="row" style={{

                            }}>
                                <div className="col-3">
                                    <svg style={{ width: '30px' }} xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 170 170">
                                        <circle id="Ellipse_16" data-name="Ellipse 16" cx="85" cy="85" r="85" />
                                    </svg>
                                </div>
                                <div className="col-9" style={{ alignSelf: 'center', fontWeight: '500', fontFamily: 'Lexend Deca', fontSize: '5px' }}>
                                    <div className="row">Morem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                        irmod tempor invidunt ut labore et dolore magna aliquyam erat,
                                        sed diam voluptua. At vero eos et
                                    </div>
                                    <div className="row pt-4">Liza Mond - Apple</div>
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
                <div className="col-3 mt-3" style={{
                    padding: '0px',
                    height: '100px',
                    textAlign: 'center',
                    lineHeight: '10px',
                    backgroundColor: 'white',
                    borderRadius: '79px 79px 79px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '7px',
                    fontWeight: '500',
                    fontFamily: 'Lexend Deca'
                }}>What they say about us?</div>
            </div>
            <Footer />
        </div >
    )
}

