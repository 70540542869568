import React from 'react'
import './Request.css'
import { Navbar } from '../Navbar/Navbar'
import { Footer } from '../Footer/Footer'
import Image1 from './Images/Image1.png'
function Request1() {
    return (
        <div className=' col container' style={{color: 'white'}}>
            <Navbar />
            <div className="row justify-content-center" style={{
                fontSize: '50px',

                fontWeight: '600'
            }}>
                اطلب عرض أسعار
            </div>
            <div className="row justify-content-center" style={{
                fontSize: '20px',
                fontWeight: '500',

            }}>
                حان الوقت لإعادة تشكيل شركتك بإبداعنا!
            </div>
            <div className="row mt-5">
                <div className="col align-self-start"></div>
                <div className="col align-self-center">
                    <div className="row details">رقم هاتف شركتك:</div>
                    <div className="row">
                        <input className='input' placeholder='Type here...' type='text'></input>
                    </div>
                    <div className="row details">الاسم الذي يمكن التواصل به:</div>
                    <div className="row">
                        <input className='input' placeholder='Type here...' type='text'></input>
                    </div>
                    <div className="row details">عنوان البريد الإلكتروني:</div>
                    <div className="row">
                        <input className='input' placeholder='Type here...' type='text'></input>
                    </div>

                </div>
                <div className="col align-self-end"></div>
            </div>
            <div className="row" style={{
                marginTop: '200px'
            }}>
                <svg width="170.167" height="26.985" viewBox="0 0 170.167 26.985">
                    <g id="Group_415" data-name="Group 415" transform="translate(-876.459 -970)">
                        <g id="_5" data-name="5" transform="translate(876.46 970)">
                            <g id="row1" transform="translate(0)">
                                <path id="icon:5" d="M26.264,13.2l-7.859,1.391a4.775,4.775,0,0,0-3.878,4.132l-1.108,8.264L12.065,18.91a4.762,4.762,0,0,0-4.022-3.984L0,13.787,7.859,12.4a4.775,4.775,0,0,0,3.878-4.132L12.845,0,14.2,8.074a4.762,4.762,0,0,0,4.022,3.984Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-2" data-name="5" transform="translate(948.411 970)">
                            <g id="row1-2" data-name="row1" transform="translate(0)">
                                <path id="icon:5-2" data-name="icon:5" d="M26.264,13.2l-7.859,1.391a4.775,4.775,0,0,0-3.878,4.132l-1.108,8.264L12.065,18.91a4.762,4.762,0,0,0-4.022-3.984L0,13.787,7.859,12.4a4.775,4.775,0,0,0,3.878-4.132L12.845,0,14.2,8.074a4.762,4.762,0,0,0,4.022,3.984Z" fill="#fff" />
                            </g>
                        </g>
                        <g id="_5-3" data-name="5" transform="translate(1020.362 970)">
                            <g id="row1-3" data-name="row1" transform="translate(0)">
                                <path id="icon:5-3" data-name="icon:5" d="M26.264,13.2l-7.859,1.391a4.775,4.775,0,0,0-3.878,4.132l-1.108,8.264L12.065,18.91a4.762,4.762,0,0,0-4.022-3.984L0,13.787,7.859,12.4a4.775,4.775,0,0,0,3.878-4.132L12.845,0,14.2,8.074a4.762,4.762,0,0,0,4.022,3.984Z" fill="#fff" />
                            </g>
                        </g>
                    </g>
                </svg>

            </div>
            <div className="row mt-5">
                <div className="col align-self-start"></div>
                <div className="col align-self-center">
                    <div className="row details">ما هي شركتك؟</div>
                    <div className="row">
                        <input className='input' placeholder='Type here...' type='text'></input>
                    </div>
                    <div className="row details">في أي قطاع تعمل؟</div>
                    <div className="row">
                        <input className='input' placeholder='Type here...' type='text'></input>
                    </div>
                    <div className="row details">من هم المستهدفون؟</div>
                    <div className="row">
                        <input className='input' placeholder='Type here...' type='text'></input>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-end pt-4" style={{
                            paddingRight: '0px !important',
                        }}>
                            <button className='button'>Submit</button>
                        </div>
                    </div>
                </div>
                <div className="col align-self-end"></div>
            </div>
            <div className="row" style={{
                marginTop: '200px'
            }}>
                <div className="col"></div>
                <div className="col align-self-center">
                    <img src={Image1} alt='logo'/>

                </div>
                <div className="col"></div>
            </div>
            <Footer />
        </div>
    )
}

export default Request1;