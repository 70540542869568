import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import { Home } from './Components/Home Page/Home';
import { About } from './Components/About/About';
import { Contact } from './Components/Contact/Contact';
import { Request } from './Components/Request_a_quote/Request';
import { Services } from './Components/Services/Services';
import Home1 from './AR-Components/Home Page/Home';
import About1 from './AR-Components/About/About';
import Services1 from './AR-Components/Services/Services';
import Request1 from './AR-Components/Request_a_quote/Request';
import Contact1 from './AR-Components/Contact/Contact';


function App() {
  return (
    <div className="App" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path="/request" element={<Request />} />
        <Route path="/services" element={<Services />} />
        <Route path="/ar" element={<Home1 />} />
        <Route path="/about/ar" element={<About1 />} />
        <Route path="/services/ar" element={<Services1 />} />
        <Route path="/request/ar" element={<Request1 />} />
        <Route path="/contact/ar" element={<Contact1 />} />
      </Routes>
    </div>
  );
}

export default App;
