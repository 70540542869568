import React, { useState, useEffect } from 'react';
import './Dropdown.css';

export function Dropdown2(props) {
  const [toggle, setToggle] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function toggleClick() {
    setToggle(!toggle);
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="dropdown-container">
      <div
        id="drop"
        className="row"
        onClick={toggleClick}
        style={{
          opacity: '1',
          transition: '0.5s',
          height: toggle ? 'fit-content' : 'fit-content',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: `${windowWidth > 600 ? '300px' :  '150px'}`, // Adjust the width based on window width
          borderRadius: '50px',
          placeContent: 'flex-start center',
          position: 'absolute',
          
        }}
      >
        <div className="col-2 d-none d-md-block" style={{ padding: '10px' }}>
          <svg  xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49">
            <ellipse id="Ellipse_85" data-name="Ellipse 85" cx="24" cy="24.5" rx="24" ry="24.5" fill="#47235e" />
          </svg>
        </div>
        <div
          className="col-6"
          style={{
            fontSize: windowWidth > 600 ? '15px' : '12px', // Adjust the font size based on window width
            color: 'black',
            fontWeight: '500',
            textAlign: 'center',
          }}
        >
          {props.title}
        </div>
        <div className="col-2">
          <svg
            id="arrow"
            style={{
              width: `${windowWidth > 600 ? '30px' : '20px'}`,
              transition: '0.5s',
              transform: toggle ? 'rotate(180deg)' : 'rotate(360deg)',
              
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="29.25"
            height="29.25"
            viewBox="0 0 29.25 29.25"
          >
            <g id="Icon_ionic-ios-arrow-dropup" data-name="Icon ionic-ios-arrow-dropup" transform="translate(32.625 32.625) rotate(180)">
              <path
                id="Path_377"
                data-name="Path 377"
                d="M10.378,21.185a1.362,1.362,0,0,1,0-1.92l6.729-6.708a1.356,1.356,0,0,1,1.87-.042l6.63,6.609a1.355,1.355,0,1,1-1.912,1.92L18,15.434l-5.7,5.759A1.356,1.356,0,0,1,10.378,21.185Z"
                fill="#502769"
              />
              <path
                id="Path_378"
                data-name="Path 378"
                d="M18,32.625A14.625,14.625,0,1,0,3.375,18,14.623,14.623,0,0,0,18,32.625Zm0-2.25A12.37,12.37,0,0,1,9.253,9.253,12.37,12.37,0,1,1,26.747,26.747,12.269,12.269,0,0,1,18,30.375Z"
                fill="#502769"
              />
            </g>
          </svg>
        </div>
        {/* Dropdown content */}
        {toggle && (
          <div
            id="description"
            className="row"
            style={{
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: '1',
              zIndex: '2',
              transition: '0.5s',
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '50px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              marginTop: '10px',
              padding: '10px',
            }}
          >
            <p style={{ color: 'black', fontSize: '12px' }}>{props.description}</p>
          </div>
        )}
      </div>
    </div>
  );
}
