import React from 'react'
import { Navbar } from '../Navbar/Navbar'
import './About.css'
import Image1 from './Images/Image1.png'
import Image2 from './Images/Image2.png'
import { Footer } from '../Footer/Footer'
export function About() {
    return (
        <div className=" col" style={{ padding: '60px' }}>
            <Navbar />

            <div className="row d-none d-lg-flex">
                <div className="col-6">
                    <div className="col-6">
                        <div className="row about-us" style={{ marginTop: '100px' }}>ABOUT US</div>
                    </div>
                    <div className="row info" style={{ marginTop: '100px' }}>
                        Distinguished by the 1st letter of the Greek Alphabet Alpha, power and<br />
                        assertion resonate with our drive to shift local businesses and startups<br />
                        to a higher level, aligning with Vision 2030 and beyond. Saudi society is<br />
                        entering a more vibrant, ambitious, and determined era while embracing<br />
                        all sorts of new technologies, and innovative trends, with creativity on its<br />
                        side. We are moving up the Saudi market by adopting an accountable<br />
                        and responsible attitude to fully take in the massive wave of drive and<br />
                        energy that moves the Kingdom and its populace. Our ability to attract<br />
                        challenging cutting-edge projects is proven every time our team of local<br />
                        experts walks our clients through a structured and smooth creative<br />
                        process. Honored by our Saudi origins, we value talent and guide your<br />
                        business to stand out and accomplish the Saudi Dream - all diverse<br />
                        regions appreciated, connected, and fulfilled through constant innovation
                    </div>
                </div>
                <div className="col-6" style={{

                    textAlign: 'center',
                    marginTop: '120px'
                }}>
                    <img src={Image1} alt='logo'></img>

                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none">
                <div className="col-6">
                    <div className="col-6">
                        <div className="row about-us" style={{ marginTop: '100px', fontSize: '20px' }}>ABOUT US</div>
                    </div>
                    <div className="row info-1" style={{ marginTop: '100px', fontSize: '8px', height: '300px' }}>
                        Distinguished by the 1st letter of the Greek Alphabet Alpha, power and<br />
                        assertion resonate with our drive to shift local businesses and startups<br />
                        to a higher level, aligning with Vision 2030 and beyond. Saudi society is<br />
                        entering a more vibrant, ambitious, and determined era while embracing<br />
                        all sorts of new technologies, and innovative trends, with creativity on its<br />
                        side. We are moving up the Saudi market by adopting an accountable<br />
                        and responsible attitude to fully take in the massive wave of drive and<br />
                        energy that moves the Kingdom and its populace. Our ability to attract<br />
                        challenging cutting-edge projects is proven every time our team of local<br />
                        experts walks our clients through a structured and smooth creative<br />
                        process. Honored by our Saudi origins, we value talent and guide your<br />
                        business to stand out and accomplish the Saudi Dream - all diverse<br />
                        regions appreciated, connected, and fulfilled through constant innovation
                    </div>
                </div>
                <div className="col-6" style={{

                    textAlign: 'center',
                    marginTop: '120px'
                }}>
                    <img style={{height: '650px'}} src={Image1} alt='logo'></img>

                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none">
                <div className="col-6">
                    <div className="col-6">
                        <div className="row about-us" style={{ marginTop: '100px', fontSize: '15px' }}>ABOUT US</div>
                    </div>
                    <div className="row info-1" style={{ marginTop: '100px', fontSize: '5px', height: '220px' }}>
                        Distinguished by the 1st letter of the Greek Alphabet Alpha, power and<br />
                        assertion resonate with our drive to shift local businesses and startups<br />
                        to a higher level, aligning with Vision 2030 and beyond. Saudi society is<br />
                        entering a more vibrant, ambitious, and determined era while embracing<br />
                        all sorts of new technologies, and innovative trends, with creativity on its<br />
                        side. We are moving up the Saudi market by adopting an accountable<br />
                        and responsible attitude to fully take in the massive wave of drive and<br />
                        energy that moves the Kingdom and its populace. Our ability to attract<br />
                        challenging cutting-edge projects is proven every time our team of local<br />
                        experts walks our clients through a structured and smooth creative<br />
                        process. Honored by our Saudi origins, we value talent and guide your<br />
                        business to stand out and accomplish the Saudi Dream - all diverse<br />
                        regions appreciated, connected, and fulfilled through constant innovation
                    </div>
                </div>
                <div className="col-6" style={{

                    textAlign: 'center',
                    marginTop: '120px'
                }}>
                    <img style={{height: '350px'}} src={Image1} alt='logo'></img>

                </div>
            </div>
            <div className="row d-flex d-sm-none">
                <div className="col-6">
                    <div className="col-6">
                        <div className="row about-us" style={{ marginTop: '20px', fontSize: '8px' }}>ABOUT US</div>
                    </div>
                    <div className="row info-1" style={{ marginTop: '20px', fontSize: '3px', height: '210px' }}>
                        Distinguished by the 1st letter of the Greek Alphabet Alpha, power and<br />
                        assertion resonate with our drive to shift local businesses and startups<br />
                        to a higher level, aligning with Vision 2030 and beyond. Saudi society is<br />
                        entering a more vibrant, ambitious, and determined era while embracing<br />
                        all sorts of new technologies, and innovative trends, with creativity on its<br />
                        side. We are moving up the Saudi market by adopting an accountable<br />
                        and responsible attitude to fully take in the massive wave of drive and<br />
                        energy that moves the Kingdom and its populace. Our ability to attract<br />
                        challenging cutting-edge projects is proven every time our team of local<br />
                        experts walks our clients through a structured and smooth creative<br />
                        process. Honored by our Saudi origins, we value talent and guide your<br />
                        business to stand out and accomplish the Saudi Dream - all diverse<br />
                        regions appreciated, connected, and fulfilled through constant innovation
                    </div>
                </div>
                <div className="col-6" style={{

                    textAlign: 'center',
                    marginTop: '100px'
                }}>
                    <img style={{height: '180px'}} src={Image1} alt='logo'></img>

                </div>
            </div>
            {/* Geometry */}
            <div className="row" style={{
                marginTop: '100px'
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1800" height="88" viewBox="0 0 1800 88">
                    <g id="Group_412" data-name="Group 412" transform="translate(-60 -1246)">
                        <circle id="Ellipse_36" data-name="Ellipse 36" cx="44" cy="44" r="44" transform="translate(916 1246)" />
                        <g id="_5" data-name="5" transform="translate(940.948 1270.426)">
                            <g id="row1">
                                <path id="icon:5" d="M38.1,19.146,26.7,21.165a6.927,6.927,0,0,0-5.626,5.995L19.469,39.148,17.5,27.435a6.909,6.909,0,0,0-5.835-5.781L0,20l11.4-2.019a6.927,6.927,0,0,0,5.626-5.995L18.635,0,20.6,11.714a6.909,6.909,0,0,0,5.835,5.781Z" fill="#fff" />
                            </g>
                        </g>
                        <circle id="Ellipse_30" data-name="Ellipse 30" cx="3" cy="3" r="3" transform="translate(60 1284)" fill="#fff" />
                        <circle id="Ellipse_77" data-name="Ellipse 77" cx="3" cy="3" r="3" transform="translate(1044 1284)" fill="#fff" />
                        <circle id="Ellipse_75" data-name="Ellipse 75" cx="3" cy="3" r="3" transform="translate(870 1284)" fill="#fff" />
                        <circle id="Ellipse_76" data-name="Ellipse 76" cx="3" cy="3" r="3" transform="translate(1854 1284)" fill="#fff" />
                        <path id="Path_348" data-name="Path 348" d="M2761.14,756H3570.1" transform="translate(-2698.14 531)" fill="none" stroke="#fff" stroke-width="0.5" />
                        <path id="Path_362" data-name="Path 362" d="M2761.14,756H3570.1" transform="translate(-1714.14 531)" fill="none" stroke="#fff" stroke-width="0.5" />
                    </g>
                </svg>

            </div>

            {/* Mission */}
            <div className="row d-none d-lg-flex">
                <div className="col-6">

                    <div className="row" style={{ marginTop: '100px' }}>
                        <div className="col-6 about-us">MISSION</div>

                    </div>


                    <div className="row info" style={{ marginTop: '100px', padding: '50px', marginRight: '3rem' }}>

                        Creating a strong impact on our fellow Saudis undeniably aspires us to<br />
                        be a pillar in Vision 2030. With transparency, integrity and honesty,<br />
                        we are setting a clear path toward growth, leadership, and<br />
                        out-of-the-box projects and achievements. A set combined of our<br />
                        strategic skills and unordinary creativity with your ambitious projects<br />
                        are our tools to fulfill ideals set for our mutual growth in the<br />
                        upcoming Saudi future!
                    </div>

                    <div className="row justify-content-center" style={{

                        marginTop: '100px',
                        textAlign: 'center'
                    }}>
                        <div className="col">

                            <img  width='386px' heigh="667px" src={Image2} alt='logo'></img>
                        </div>
                    </div>
                </div>
                <div className="col-5">

                    <div className="col-6 about-us" style={{ marginTop: '100px' }}>VALUES</div>


                    <div className="row info" style={{ marginTop: '100px', padding: '50px' }}>
                        <div className="col">

                            <span style={{ fontSize: '35px' }}>Adaptability <br /></span>
                            <p>
                                Our primary strength.
                                We dig deep into the Saudi market and its various niches with
                                every new project coming our way. Shifting our focus from one
                                industry to another allows us to efficiently adapt the needed tools
                                and strategies for our client’s goals.
                            </p>


                            <span style={{ fontSize: '35px' }}>Leadership <br /></span>
                            An art we go by!<br />
                            Being the communication experts, we focus on what people value,<br />
                            and what businesses have to offer to lead you to positive outcomes.<br />
                            We are committed to a problem-solving philosophy that can only<br />
                            contribute to your success and raise you to stand out as an expert<br />
                            in your industry.<br />


                            <span style={{ fontSize: '35px' }}>Persuasiveness <br /></span>
                            Our credibility does not go unnoticed.<br />
                            Our creative twist is our (not so) secret ingredient used to persuade<br />
                            as we go by delivering exceptional services and talent for your<br />
                            business to succeed more!<br />


                            <span style={{ fontSize: '35px' }}>High-Minded <br /></span>
                            Committed to Vision 2030.<br />
                            As a Saudi creative agency, our participation in the evolution of<br />
                            our culture and Kingdom is our sole motivation.<br />
                            Committed to the Saudi culture and values, we hold our heads high<br />
                            while focusing on innovating, expanding, and growing our<br />
                            identity and yours!<br />


                            <span style={{ fontSize: '35px' }}>Accountibility <br /></span>
                            A deal is a deal!<br />
                            We aspire to lead in the Saudi market given our integrity and<br />
                            transparency blindly leading us to take full responsibility regarding<br />
                            the project at hand.


                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none">
                <div className="col-6">

                    <div className="row" style={{ marginTop: '100px' }}>
                        <div className="col-6 about-us">MISSION</div>

                    </div>


                    <div className="row info-1" style={{ marginTop: '100px', padding: '50px', marginRight: '3rem', fontSize: '8px' }}>

                        Creating a strong impact on our fellow Saudis undeniably aspires us to<br />
                        be a pillar in Vision 2030. With transparency, integrity and honesty,<br />
                        we are setting a clear path toward growth, leadership, and<br />
                        out-of-the-box projects and achievements. A set combined of our<br />
                        strategic skills and unordinary creativity with your ambitious projects<br />
                        are our tools to fulfill ideals set for our mutual growth in the<br />
                        upcoming Saudi future!
                    </div>

                    <div className="row justify-content-center" style={{

                        marginTop: '100px',
                        textAlign: 'center'
                    }}>
                        <div className="col">

                            <img width='386px' heigh="667px" src={Image2} alt='logo'></img>
                        </div>
                    </div>
                </div>
                <div className="col-5">

                    <div className="col-6 about-us" style={{ marginTop: '100px' }}>VALUES</div>


                    <div className="row info" style={{ marginTop: '100px', padding: '50px' }}>
                        <div className="col">

                            <span style={{ fontSize: '25px' }}>Adaptability <br /></span>
                            <p style={{ fontSize: '8px' }}>
                                Our primary strength.
                                We dig deep into the Saudi market and its various niches with
                                every new project coming our way. Shifting our focus from one
                                industry to another allows us to efficiently adapt the needed tools
                                and strategies for our client’s goals.<br />


                                <span style={{ fontSize: '25px' }}>Leadership <br /></span >
                                An art we go by!<br />
                                Being the communication experts, we focus on what people value,<br />
                                and what businesses have to offer to lead you to positive outcomes.<br />
                                We are committed to a problem-solving philosophy that can only<br />
                                contribute to your success and raise you to stand out as an expert<br />
                                in your industry.<br />


                                <span style={{ fontSize: '25px' }}>Persuasiveness <br /></span>
                                Our credibility does not go unnoticed.<br />
                                Our creative twist is our (not so) secret ingredient used to persuade<br />
                                as we go by delivering exceptional services and talent for your<br />
                                business to succeed more!<br />


                                <span style={{ fontSize: '25px' }}>High&nbsp;-&nbsp;Minded<br /></span>
                                Committed to Vision 2030.<br />
                                As a Saudi creative agency, our participation in the evolution of<br />
                                our culture and Kingdom is our sole motivation.<br />
                                Committed to the Saudi culture and values, we hold our heads high<br />
                                while focusing on innovating, expanding, and growing our<br />
                                identity and yours!<br />


                                <span style={{ fontSize: '25px' }}>Accountibility <br /></span>
                                A deal is a deal!<br />
                                We aspire to lead in the Saudi market given our integrity and<br />
                                transparency blindly leading us to take full responsibility regarding<br />
                                the project at hand.

                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none">
                <div className="col-6">

                    <div className="row" style={{ marginTop: '100px' }}>
                        <div className="col-6 about-us">MISSION</div>

                    </div>


                    <div className="row info-1" style={{ marginTop: '100px', padding: '50px', marginRight: '3rem', fontSize: '6px' }}>

                        Creating a strong impact on our fellow Saudis undeniably aspires us to<br />
                        be a pillar in Vision 2030. With transparency, integrity and honesty,<br />
                        we are setting a clear path toward growth, leadership, and<br />
                        out-of-the-box projects and achievements. A set combined of our<br />
                        strategic skills and unordinary creativity with your ambitious projects<br />
                        are our tools to fulfill ideals set for our mutual growth in the<br />
                        upcoming Saudi future!
                    </div>

                    <div className="row justify-content-center" style={{
                        marginLeft: '-60px',
                        marginTop: '100px',
                        textAlign: 'center'
                    }}>
                        <div className="col">

                            <img style={{height: '450px'}} src={Image2} alt='logo'></img>
                        </div>
                    </div>
                </div>
                <div className="col-5">

                    <div className="col-6 about-us" style={{ marginTop: '100px' }}>VALUES</div>


                    <div className="row info" style={{ marginTop: '100px', padding: '40px' }}>
                        <div className="col">

                            <span style={{ fontSize: '15px' }}>Adaptability <br /></span>
                            <p style={{ fontSize: '8px' }}>
                                Our primary strength.
                                We dig deep into the Saudi market and its various niches with
                                every new project coming our way. Shifting our focus from one
                                industry to another allows us to efficiently adapt the needed tools
                                and strategies for our client’s goals.<br />


                                <span style={{ fontSize: '18px' }}>Leadership <br /></span >
                                An art we go by!<br />
                                Being the communication experts, we focus on what people value,<br />
                                and what businesses have to offer to lead you to positive outcomes.<br />
                                We are committed to a problem-solving philosophy that can only<br />
                                contribute to your success and raise you to stand out as an expert<br />
                                in your industry.<br />


                                <span style={{ fontSize: '18px' }}>Persuasiveness <br /></span>
                                Our credibility does not go unnoticed.<br />
                                Our creative twist is our (not so) secret ingredient used to persuade<br />
                                as we go by delivering exceptional services and talent for your<br />
                                business to succeed more!<br />


                                <span style={{ fontSize: '18px' }}>High&nbsp;-&nbsp;Minded<br /></span>
                                Committed to Vision 2030.<br />
                                As a Saudi creative agency, our participation in the evolution of<br />
                                our culture and Kingdom is our sole motivation.<br />
                                Committed to the Saudi culture and values, we hold our heads high<br />
                                while focusing on innovating, expanding, and growing our<br />
                                identity and yours!<br />


                                <span style={{ fontSize: '18px' }}>Accountibility <br /></span>
                                A deal is a deal!<br />
                                We aspire to lead in the Saudi market given our integrity and<br />
                                transparency blindly leading us to take full responsibility regarding<br />
                                the project at hand.

                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none">
                <div className="col-6">

                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-6 about-us" style={{fontSize:'10px'}}>MISSION</div>

                    </div>


                    <div className="row info-1" style={{ marginTop: '10px', padding: '10px', marginRight: '3rem', fontSize: '3.5px' }}>

                        Creating a strong impact on our fellow Saudis undeniably aspires us to<br />
                        be a pillar in Vision 2030. With transparency, integrity and honesty,<br />
                        we are setting a clear path toward growth, leadership, and<br />
                        out-of-the-box projects and achievements. A set combined of our<br />
                        strategic skills and unordinary creativity with your ambitious projects<br />
                        are our tools to fulfill ideals set for our mutual growth in the<br />
                        upcoming Saudi future!
                    </div>

                    <div className="row justify-content-center" style={{
                        marginLeft: '-60px',
                        marginTop: '20px',
                        textAlign: 'center'
                    }}>
                        <div className="col">

                            <img style={{height: '200px'}} src={Image2} alt='logo'></img>
                        </div>
                    </div>
                </div>
                <div className="col-5">

                    <div className="col-6 about-us" style={{ marginTop: '20px', fontSize:'10px' }}>VALUES</div>


                    <div className="row info" style={{ marginTop: '20px', padding: '15px' }}>
                        <div className="col">

                            <span style={{ fontSize: '8px' }}>Adaptability <br /></span>
                            <p style={{ fontSize: '3px' }}>
                                Our primary strength.
                                We dig deep into the Saudi market and its various niches with
                                every new project coming our way. Shifting our focus from one
                                industry to another allows us to efficiently adapt the needed tools
                                and strategies for our client’s goals.<br />


                                <span style={{ fontSize: '8px' }}>Leadership <br /></span >
                                An art we go by!<br />
                                Being the communication experts, we focus on what people value,<br />
                                and what businesses have to offer to lead you to positive outcomes.<br />
                                We are committed to a problem-solving philosophy that can only<br />
                                contribute to your success and raise you to stand out as an expert<br />
                                in your industry.<br />


                                <span style={{ fontSize: '8px' }}>Persuasiveness <br /></span>
                                Our credibility does not go unnoticed.<br />
                                Our creative twist is our (not so) secret ingredient used to persuade<br />
                                as we go by delivering exceptional services and talent for your<br />
                                business to succeed more!<br />


                                <span style={{ fontSize: '8px' }}>High&nbsp;-&nbsp;Minded<br /></span>
                                Committed to Vision 2030.<br />
                                As a Saudi creative agency, our participation in the evolution of<br />
                                our culture and Kingdom is our sole motivation.<br />
                                Committed to the Saudi culture and values, we hold our heads high<br />
                                while focusing on innovating, expanding, and growing our<br />
                                identity and yours!<br />


                                <span style={{ fontSize: '8px' }}>Accountibility <br /></span>
                                A deal is a deal!<br />
                                We aspire to lead in the Saudi market given our integrity and<br />
                                transparency blindly leading us to take full responsibility regarding<br />
                                the project at hand.

                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

