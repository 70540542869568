import React from 'react'
import { Navbar } from '../Navbar/Navbar'
import './About.css'
import Image1 from './Images/Image1.png'
import Image2 from './Images/Image2.png'
import { Footer } from '../Footer/Footer'
function About1() {
    return (
        <div className="container-fluid col" style={{ padding: '60px' }}>
            <Navbar />

            <div className="row d-none d-lg-flex">
                <div className="col-6">
                    <div className="col-6">
                        <div className="row about-us" style={{ marginTop: '100px' }}>نبذة عنا</div>
                    </div>
                    <div className="row info" style={{ marginTop: '100px', padding: '55px' }}>
                        اهتمامنا في وكالة ألفا للإبداع يتغذى على التحدي والفضول.
                        يبرز الحرف الأول من الأبجدية اليونانية (ألفا) والذي يتميز بالقوة والتأكيد، مع رغبتنا في رفع مستوى الشركات المحلية والشركات الناشئة إلى مستوى أعلى، متوافقين مع رؤية المملكة 2030
                        ندخل كمجتمع سعودي في عصر أكثر حركة وطموحًا وعزيمة، مع اتباع التكنولوجيا الجديدة والاتجاهات الابتكارية، واكيد مع الأبداع !
                        تقدمنا في السوق السعودية عن طريق تبني موقف مسؤول لاستيعاب الموجة الضخمة من الدافع والطاقة التي تحرك المملكة وشعبها.
                        كل مرة نثبت قدرتنا على جذب مشاريع تحديثية حديثة كلما قاد فريقنا من الخبراء المحليين عملائنا من خلال عملية إبداعية منظمة وسلسة.
                        تقديرًا لأصولنا السعودية، نقدر المواهب ونوجه عملك للتألق وتحقيق الحلم السعودي - كل المناطق المتنوعة مقدرة ومرتبطة ومحققة من خلال الابتكار المستمر.

                    </div>
                </div>
                <div className="col-6" style={{

                    textAlign: 'center',
                    marginTop: '120px'
                }}>
                    <img src={Image1} alt='logo'></img>

                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none">
                <div className="col-6">
                    <div className="col-6">
                        <div className="row about-us" style={{ marginTop: '100px', fontSize: '20px' }}>نبذة عنا</div>
                    </div>
                    <div className="row info-1" style={{ marginTop: '100px', fontSize: '8px', height: '300px', padding: '0px' }}>
                        اهتمامنا في وكالة ألفا للإبداع يتغذى على التحدي والفضول.
                        يبرز الحرف الأول من الأبجدية اليونانية (ألفا) والذي يتميز بالقوة والتأكيد، مع رغبتنا في رفع مستوى الشركات المحلية والشركات الناشئة إلى مستوى أعلى، متوافقين مع رؤية المملكة 2030
                        ندخل كمجتمع سعودي في عصر أكثر حركة وطموحًا وعزيمة، مع اتباع التكنولوجيا الجديدة والاتجاهات الابتكارية، واكيد مع الأبداع !
                        تقدمنا في السوق السعودية عن طريق تبني موقف مسؤول لاستيعاب الموجة الضخمة من الدافع والطاقة التي تحرك المملكة وشعبها.
                        كل مرة نثبت قدرتنا على جذب مشاريع تحديثية حديثة كلما قاد فريقنا من الخبراء المحليين عملائنا من خلال عملية إبداعية منظمة وسلسة.
                        تقديرًا لأصولنا السعودية، نقدر المواهب ونوجه عملك للتألق وتحقيق الحلم السعودي - كل المناطق المتنوعة مقدرة ومرتبطة ومحققة من خلال الابتكار المستمر.

                    </div>
                </div>
                <div className="col-6" style={{

                    textAlign: 'center',
                    marginTop: '120px'
                }}>
                    <img style={{height: '650px'}} src={Image1} alt='logo'></img>

                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none">
                <div className="col-6">
                    <div className="col-6">
                        <div className="row about-us" style={{ marginTop: '100px', fontSize: '15px' }}>نبذة عنا</div>
                    </div>
                    <div className="row info-1" style={{ marginTop: '100px', fontSize: '5px', height: '220px' }}>
                        اهتمامنا في وكالة ألفا للإبداع يتغذى على التحدي والفضول.
                        يبرز الحرف الأول من الأبجدية اليونانية (ألفا) والذي يتميز بالقوة والتأكيد، مع رغبتنا في رفع مستوى الشركات المحلية والشركات الناشئة إلى مستوى أعلى، متوافقين مع رؤية المملكة 2030
                        ندخل كمجتمع سعودي في عصر أكثر حركة وطموحًا وعزيمة، مع اتباع التكنولوجيا الجديدة والاتجاهات الابتكارية، واكيد مع الأبداع !
                        تقدمنا في السوق السعودية عن طريق تبني موقف مسؤول لاستيعاب الموجة الضخمة من الدافع والطاقة التي تحرك المملكة وشعبها.
                        كل مرة نثبت قدرتنا على جذب مشاريع تحديثية حديثة كلما قاد فريقنا من الخبراء المحليين عملائنا من خلال عملية إبداعية منظمة وسلسة.
                        تقديرًا لأصولنا السعودية، نقدر المواهب ونوجه عملك للتألق وتحقيق الحلم السعودي - كل المناطق المتنوعة مقدرة ومرتبطة ومحققة من خلال الابتكار المستمر.

                    </div>
                </div>
                <div className="col-6" style={{

                    textAlign: 'center',
                    marginTop: '120px'
                }}>
                    <img style={{height: '350px'}} src={Image1} alt='logo'></img>

                </div>
            </div>
            <div className="row d-flex d-sm-none">
                <div className="col-6">
                    <div className="col-6">
                        <div className="row about-us" style={{ marginTop: '20px', fontSize: '8px' }}>نبذة عنا</div>
                    </div>
                    <div className="row info-1" style={{ marginTop: '20px', fontSize: '3px', height: '210px' }}>
                        اهتمامنا في وكالة ألفا للإبداع يتغذى على التحدي والفضول.
                        يبرز الحرف الأول من الأبجدية اليونانية (ألفا) والذي يتميز بالقوة والتأكيد، مع رغبتنا في رفع مستوى الشركات المحلية والشركات الناشئة إلى مستوى أعلى، متوافقين مع رؤية المملكة 2030
                        ندخل كمجتمع سعودي في عصر أكثر حركة وطموحًا وعزيمة، مع اتباع التكنولوجيا الجديدة والاتجاهات الابتكارية، واكيد مع الأبداع !
                        تقدمنا في السوق السعودية عن طريق تبني موقف مسؤول لاستيعاب الموجة الضخمة من الدافع والطاقة التي تحرك المملكة وشعبها.
                        كل مرة نثبت قدرتنا على جذب مشاريع تحديثية حديثة كلما قاد فريقنا من الخبراء المحليين عملائنا من خلال عملية إبداعية منظمة وسلسة.
                        تقديرًا لأصولنا السعودية، نقدر المواهب ونوجه عملك للتألق وتحقيق الحلم السعودي - كل المناطق المتنوعة مقدرة ومرتبطة ومحققة من خلال الابتكار المستمر.

                    </div>
                </div>
                <div className="col-6" style={{

                    textAlign: 'center',
                    marginTop: '120px'
                }}>
                    <img style={{height: '180px'}} src={Image1} alt='logo'></img>

                </div>
            </div>
            {/* Geometry */}
            <div className="row" style={{
                marginTop: '100px'
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1800" height="88" viewBox="0 0 1800 88">
                    <g id="Group_412" data-name="Group 412" transform="translate(-60 -1246)">
                        <circle id="Ellipse_36" data-name="Ellipse 36" cx="44" cy="44" r="44" transform="translate(916 1246)" />
                        <g id="_5" data-name="5" transform="translate(940.948 1270.426)">
                            <g id="row1">
                                <path id="icon:5" d="M38.1,19.146,26.7,21.165a6.927,6.927,0,0,0-5.626,5.995L19.469,39.148,17.5,27.435a6.909,6.909,0,0,0-5.835-5.781L0,20l11.4-2.019a6.927,6.927,0,0,0,5.626-5.995L18.635,0,20.6,11.714a6.909,6.909,0,0,0,5.835,5.781Z" fill="#fff" />
                            </g>
                        </g>
                        <circle id="Ellipse_30" data-name="Ellipse 30" cx="3" cy="3" r="3" transform="translate(60 1284)" fill="#fff" />
                        <circle id="Ellipse_77" data-name="Ellipse 77" cx="3" cy="3" r="3" transform="translate(1044 1284)" fill="#fff" />
                        <circle id="Ellipse_75" data-name="Ellipse 75" cx="3" cy="3" r="3" transform="translate(870 1284)" fill="#fff" />
                        <circle id="Ellipse_76" data-name="Ellipse 76" cx="3" cy="3" r="3" transform="translate(1854 1284)" fill="#fff" />
                        <path id="Path_348" data-name="Path 348" d="M2761.14,756H3570.1" transform="translate(-2698.14 531)" fill="none" stroke="#fff" stroke-width="0.5" />
                        <path id="Path_362" data-name="Path 362" d="M2761.14,756H3570.1" transform="translate(-1714.14 531)" fill="none" stroke="#fff" stroke-width="0.5" />
                    </g>
                </svg>

            </div>

            {/* الرؤية */}
            <div className="row d-none d-lg-flex">
                <div className="col-6">

                    <div className="row" style={{ marginTop: '100px' }}>
                        <div className="col-6 about-us">الرؤية</div>

                    </div>


                    <div className="row info" style={{ marginTop: '100px', padding: '50px', marginRight: '3rem' }}>

                        إن خلق تأثير قوي علينا كسعوديين يطمح بلا شك لأن نكون ركيزة في رؤية المملكة 2030.
                        مع الشفافية والنزاهة والصدق، نضع مسارًا واضحًا نحو النمو والقيادة والمشاريع والإنجازات الغير المعهودة.
                        مجموعة من مهاراتنا الاستراتيجية والابداع الغير عادي مع مشاريعكم الطموحة هي أدواتنا لتحقيق المثل الأعلى المتمثل في النمو المتبادل في مستقبل السعودية!

                    </div>

                    <div className="row justify-content-center" style={{

                        marginTop: '100px',
                        textAlign: 'center'
                    }}>
                        <div className="col">

                            <img width='386px' heigh="667px" src={Image2} alt='logo'></img>
                        </div>
                    </div>
                </div>
                <div className="col-5">

                    <div className="col-6 about-us" style={{ marginTop: '100px' }}>القيم</div>


                    <div className="row info" style={{ marginTop: '100px', padding: '50px' }}>
                        <div className="col">

                            <span style={{ fontSize: '35px' }}>التكيف <br /></span>
                            <p>
                                قوتنا الأساسية
                                نحن نحفر بعمق في السوق السعودي ومختلف المجالات مع كل مشروع جديد يأتي في طريقنا. إن تحويل تركيزنا من صناعة إلى أخرى يتيح لنا التكيف بكفاءة مع الأدوات والاستراتيجيات اللازمة لأهداف عملائنا.

                            </p>


                            <span style={{ fontSize: '35px' }}>القيادة <br /></span>
                            فن نتبعه!
                            نحن كخبراء في التواصل، نركز على ما يقدره الناس وما يقدمه الأعمال لنقودك إلى نتائج إيجابية.
                            نحن ملتزمون بفلسفة حل المشكلات التي لا يمكن أن تساهم إلا في نجاحك وترفعك لتبرز كخبير في صناعتك.


                            <br />
                            <span style={{ fontSize: '35px' }}>الإقناعية <br /></span>
                            إن تطورنا الإبداعي هو المكون السري المستخدم للإقناع بتقديم خدمات استثنائية ومواهب لنجاح عملك بشكل أفضل!

                            <br />
                            <span style={{ fontSize: '35px' }}>طموح عال <br /></span>
                            ملتزمون برؤية المملكة 2030.
                            كوكالة إبداعية سعودية، مشاركتنا في تطور ثقافتنا ومملكتنا هي دافعنا الوحيد. ملتزمون بالثقافة والقيم السعودية، مع تركيزنا على الابتكار والتوسع ونمو هويتنا وهويتك!


                            <br />
                            <span style={{ fontSize: '35px' }}>المسئولية <br /></span>
                            الصفقة هي صفقة!
                            نتطلع لقيادة السوق السعودية بنزاهتنا وشفافيتنا التي تقودنا بلا تردد على تحمل المسؤولية الكاملة بشأن المشروع المطلوب.



                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-none d-md-flex d-lg-none">
                <div className="col-6">

                    <div className="row" style={{ marginTop: '100px' }}>
                        <div className="col-6 about-us">الرؤية</div>

                    </div>


                    <div className="row info-1" style={{ marginTop: '100px', padding: '50px', marginRight: '3rem', fontSize: '8px' }}>

                        إن خلق تأثير قوي علينا كسعوديين يطمح بلا شك لأن نكون ركيزة في رؤية المملكة 2030.
                        مع الشفافية والنزاهة والصدق، نضع مسارًا واضحًا نحو النمو والقيادة والمشاريع والإنجازات الغير المعهودة.
                        مجموعة من مهاراتنا الاستراتيجية والابداع الغير عادي مع مشاريعكم الطموحة هي أدواتنا لتحقيق المثل الأعلى المتمثل في النمو المتبادل في مستقبل السعودية!

                    </div>

                    <div className="row justify-content-center" style={{

                        marginTop: '100px',
                        textAlign: 'center'
                    }}>
                        <div className="col">

                            <img width='386px' heigh="667px" src={Image2} alt='logo'></img>
                        </div>
                    </div>
                </div>
                <div className="col-5">

                    <div className="col-6 about-us" style={{ marginTop: '100px' }}>القيم</div>


                    <div className="row info" style={{ marginTop: '100px', padding: '50px' }}>
                        <div className="col">

                            <span style={{ fontSize: '25px' }}>التكيف <br /></span>
                            <p style={{ fontSize: '8px' }}>
                                قوتنا الأساسية
                                نحن نحفر بعمق في السوق السعودي ومختلف المجالات مع كل مشروع جديد يأتي في طريقنا. إن تحويل تركيزنا من صناعة إلى أخرى يتيح لنا التكيف بكفاءة مع الأدوات والاستراتيجيات اللازمة لأهداف عملائنا.
                                <br />


                                <span style={{ fontSize: '25px' }}>القيادة <br /></span >
                                فن نتبعه!
                                نحن كخبراء في التواصل، نركز على ما يقدره الناس وما يقدمه الأعمال لنقودك إلى نتائج إيجابية.
                                نحن ملتزمون بفلسفة حل المشكلات التي لا يمكن أن تساهم إلا في نجاحك وترفعك لتبرز كخبير في صناعتك.



                                <span style={{ fontSize: '25px' }}>الإقناعية <br /></span>
                                Our credibility does not go unnoticed.<br />
                                Our creative twist is our (not so) secret ingredient used to persuade<br />
                                as we go by delivering exceptional services and talent for your<br />
                                business to succeed more!<br />


                                <span style={{ fontSize: '25px' }}>طموح عال<br /></span>
                                ملتزمون برؤية المملكة 2030.
                                كوكالة إبداعية سعودية، مشاركتنا في تطور ثقافتنا ومملكتنا هي دافعنا الوحيد. ملتزمون بالثقافة والقيم السعودية، مع تركيزنا على الابتكار والتوسع ونمو هويتنا وهويتك!



                                <span style={{ fontSize: '25px' }}>المسئولية <br /></span>
                                الصفقة هي صفقة!
                                نتطلع لقيادة السوق السعودية بنزاهتنا وشفافيتنا التي تقودنا بلا تردد على تحمل المسؤولية الكاملة بشأن المشروع المطلوب.


                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-none d-sm-flex d-md-none">
                <div className="col-6">

                    <div className="row" style={{ marginTop: '100px' }}>
                        <div className="col-6 about-us">الرؤية</div>

                    </div>


                    <div className="row info-1" style={{ marginTop: '100px', padding: '50px', marginRight: '3rem', fontSize: '6px' }}>

                        إن خلق تأثير قوي علينا كسعوديين يطمح بلا شك لأن نكون ركيزة في رؤية المملكة 2030.
                        مع الشفافية والنزاهة والصدق، نضع مسارًا واضحًا نحو النمو والقيادة والمشاريع والإنجازات الغير المعهودة.
                        مجموعة من مهاراتنا الاستراتيجية والابداع الغير عادي مع مشاريعكم الطموحة هي أدواتنا لتحقيق المثل الأعلى المتمثل في النمو المتبادل في مستقبل السعودية!

                    </div>

                    <div className="row justify-content-center" style={{

                        marginTop: '100px',
                        textAlign: 'center'
                    }}>
                        <div className="col">

                            <img width='386px' heigh="667px" src={Image2} alt='logo'></img>
                        </div>
                    </div>
                </div>
                <div className="col-5">

                    <div className="col-6 about-us" style={{ marginTop: '100px' }}>القيم</div>


                    <div className="row info" style={{ marginTop: '100px', padding: '40px' }}>
                        <div className="col">

                            <span style={{ fontSize: '15px' }}>التكيف <br /></span>
                            <p style={{ fontSize: '8px' }}>
                                قوتنا الأساسية
                                نحن نحفر بعمق في السوق السعودي ومختلف المجالات مع كل مشروع جديد يأتي في طريقنا. إن تحويل تركيزنا من صناعة إلى أخرى يتيح لنا التكيف بكفاءة مع الأدوات والاستراتيجيات اللازمة لأهداف عملائنا.
                                <br />


                                <span style={{ fontSize: '18px' }}>القيادة <br /></span >
                                فن نتبعه!
                                نحن كخبراء في التواصل، نركز على ما يقدره الناس وما يقدمه الأعمال لنقودك إلى نتائج إيجابية.
                                نحن ملتزمون بفلسفة حل المشكلات التي لا يمكن أن تساهم إلا في نجاحك وترفعك لتبرز كخبير في صناعتك.



                                <span style={{ fontSize: '18px' }}>الإقناعية <br /></span>
                                Our credibility does not go unnoticed.<br />
                                Our creative twist is our (not so) secret ingredient used to persuade<br />
                                as we go by delivering exceptional services and talent for your<br />
                                business to succeed more!<br />


                                <span style={{ fontSize: '18px' }}>طموح عال<br /></span>
                                ملتزمون برؤية المملكة 2030.
                                كوكالة إبداعية سعودية، مشاركتنا في تطور ثقافتنا ومملكتنا هي دافعنا الوحيد. ملتزمون بالثقافة والقيم السعودية، مع تركيزنا على الابتكار والتوسع ونمو هويتنا وهويتك!



                                <span style={{ fontSize: '18px' }}>المسئولية <br /></span>
                                الصفقة هي صفقة!
                                نتطلع لقيادة السوق السعودية بنزاهتنا وشفافيتنا التي تقودنا بلا تردد على تحمل المسؤولية الكاملة بشأن المشروع المطلوب.


                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex d-sm-none">
                <div className="col-6">

                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-6 about-us" style={{ fontSize: '10px' }}>الرؤية</div>

                    </div>


                    <div className="row info-1" style={{ marginTop: '10px', padding: '10px', marginRight: '3rem', fontSize: '3.5px' }}>

                        إن خلق تأثير قوي علينا كسعوديين يطمح بلا شك لأن نكون ركيزة في رؤية المملكة 2030.
                        مع الشفافية والنزاهة والصدق، نضع مسارًا واضحًا نحو النمو والقيادة والمشاريع والإنجازات الغير المعهودة.
                        مجموعة من مهاراتنا الاستراتيجية والابداع الغير عادي مع مشاريعكم الطموحة هي أدواتنا لتحقيق المثل الأعلى المتمثل في النمو المتبادل في مستقبل السعودية!

                    </div>

                    <div className="row justify-content-center" style={{

                        marginTop: '20px',
                        textAlign: 'center'
                    }}>
                        <div className="col">

                            <img width='386px' heigh="667px" src={Image2} alt='logo'></img>
                        </div>
                    </div>
                </div>
                <div className="col-5">

                    <div className="col-6 about-us" style={{ marginTop: '20px', fontSize: '10px' }}>القيم</div>


                    <div className="row info" style={{ marginTop: '20px', padding: '15px'}}>
                        <div className="col">

                            <span style={{ fontSize: '8px' }}>التكيف <br /></span>
                            <p style={{ fontSize: '3px' }}>
                                قوتنا الأساسية
                                نحن نحفر بعمق في السوق السعودي ومختلف المجالات مع كل مشروع جديد يأتي في طريقنا. إن تحويل تركيزنا من صناعة إلى أخرى يتيح لنا التكيف بكفاءة مع الأدوات والاستراتيجيات اللازمة لأهداف عملائنا.
                                <br />


                                <span style={{ fontSize: '8px' }}>القيادة <br /></span >
                                فن نتبعه!
                                نحن كخبراء في التواصل، نركز على ما يقدره الناس وما يقدمه الأعمال لنقودك إلى نتائج إيجابية.
                                نحن ملتزمون بفلسفة حل المشكلات التي لا يمكن أن تساهم إلا في نجاحك وترفعك لتبرز كخبير في صناعتك.



                                <span style={{ fontSize: '8px' }}>الإقناعية <br /></span>
                                Our credibility does not go unnoticed.<br />
                                Our creative twist is our (not so) secret ingredient used to persuade<br />
                                as we go by delivering exceptional services and talent for your<br />
                                business to succeed more!<br />


                                <span style={{ fontSize: '8px' }}>طموح عال<br /></span>
                                ملتزمون برؤية المملكة 2030.
                                كوكالة إبداعية سعودية، مشاركتنا في تطور ثقافتنا ومملكتنا هي دافعنا الوحيد. ملتزمون بالثقافة والقيم السعودية، مع تركيزنا على الابتكار والتوسع ونمو هويتنا وهويتك!

<br />

                                <span style={{ fontSize: '8px' }}>المسئولية <br /></span>
                                الصفقة هي صفقة!
                                نتطلع لقيادة السوق السعودية بنزاهتنا وشفافيتنا التي تقودنا بلا تردد على تحمل المسؤولية الكاملة بشأن المشروع المطلوب.


                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About1;